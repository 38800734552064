import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useGetNetwork = () => {
  const { data, makeRequest, loading } = useApi(linkCode => httpClient.get('admin/network', { params: { linkCode } }))

  return {
    dataNetwork: Array.isArray(data) ? null : data,
    fetchNetwork: makeRequest,
    isLoading: loading,
  }
}
