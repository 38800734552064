import { twMerge } from 'tailwind-merge'
import { AiOutlineClose } from 'react-icons/ai'
import { Button } from '@/components/Form'
import { TextField } from '@/components/Form/Fields'

export const ModalForgotPassword = ({
  modalIsOpen,
  handleModalIsOpen,
  handleSubmit,
  handleChange,
  values = {},
  loading,
  errors,
}) => {
  return (
    <>
      {modalIsOpen && (
        <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 bg-black/75'>
          <div className='border-0 rounded-md shadow-lg relative flex flex-col  my-6 mx-auto w-[85%] lg:w-[50%] bg-white outline-none focus:outline-none px-3'>
            <div className='flex items-center  justify-between rounded-t pt-4 pb-2 '>
              <h3 className='text-xl lg:text-xl text-colorPrimary font-semibold'>Esqueci minha senha</h3>
              <button className='text-black text-lg lg:text-xl' type='button' onClick={() => handleModalIsOpen()}>
                <AiOutlineClose />
              </button>
            </div>
            <form onSubmit={handleSubmit} className='flex flex-col w-full mt-2'>
              <TextField
                name='email'
                label='E-mail'
                placeholder='Ex.: joao@email.com'
                inputClassName='rounded border border-primary-600 bg-transparent'
                onChange={handleChange}
                errorMessage={errors.email}
                isDisabled={loading}
                value={values.email}
              />
              <div className='flex items-center justify-between'>
                <button className='font-bold text-gray-400 bg-transparent' onClick={() => handleModalIsOpen()}>
                  Fechar
                </button>
                <Button
                  type='submit'
                  className={twMerge(
                    'rounded-md  text-white my-4 font-bold px-4 py-2',
                    loading && 'bg-slate-300',
                    !loading && 'bg-primary'
                  )}
                  disabled={loading}
                  isLoading={loading}
                >
                  Redefinir senha
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  )
}
