import React from 'react'
import { Icon } from '@iconify/react/dist/iconify'
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import { VscCopy } from 'react-icons/vsc'
import MoneyAsText from '@/components/Common/MoneyAsText'

export const MobileSection = ({ dataStatus }) => {
  return (
    <section className='flex flex-col gap-4 p-4 bg-surface rounded-lg shadow-lg w-full max-w-lg lg:hidden mx-auto'>
      <img src={dataStatus.voucher.qrCode} alt='Confirmation Payment Image' />

      <div className='flex flex-col justify-between gap-3'>
        <div className='border-b border-b-primary pb-2'>
          <div className='flex items-center gap-1'>
            <p className='text-lg font-bold'>Voucher</p> <Icon icon='ci:ticket-voucher' fontSize={24} />
          </div>
          <CopyToClipboard
            text={dataStatus?.voucher.code}
            onCopy={() =>
              toast.success('Copiado com sucesso!', {
                position: 'top-center',
                autoClose: 2000,
                toastId: 'copied',
              })
            }
          >
            <div className='flex gap-2 items-center w-full text-center'>
              <div className='text-sm truncate'>{dataStatus?.voucher.code}</div>
              <div className=' text-lg'>
                <VscCopy />
              </div>
            </div>
          </CopyToClipboard>
        </div>

        <div className='flex flex-col font-bold w-full'>
          <p>
            Valor Unitário:{' '}
            <span className='font-normal'>
              <MoneyAsText value={dataStatus?.donation.unitAmount} />
            </span>
          </p>
          <p>
            Quantidade: <span className='font-normal'>{dataStatus?.donation.quantity}</span>
          </p>
          <p>
            Valor Total:{' '}
            <span className='font-semibold text-positive'>
              <MoneyAsText value={dataStatus?.donation.finalAmount} />
            </span>
          </p>
        </div>
      </div>
    </section>
  )
}
