import { Home } from '@/pages/Home'
import { createBrowserRouter } from 'react-router-dom'
import { Donation } from './pages/Donation'
import { Layout } from './layouts/Layout'
import { ConfirmationPage } from './pages/Payment/ConfirmationPage'
import { Voucher } from './pages/Voucher'
import { PaymentScreenAnalysis } from './pages/Payment/PaymentScreenAnalysis'
import { Dashboard } from './pages/Dashboard'
import { SignIn } from './pages/SignIn'
import { VerifySign } from './pages/VerifySignIn'
import { SetPassword } from './pages/CreatePassword'
import { PrivateRoute } from './components/Common'
import { SignUp } from './pages/SignUp'
import { SignUpProvider } from './pages/SignUp/context/SignUpContext'
import { DonationList } from './pages/DonationList'
import { Withdrawal } from './pages/Withdrawal'
import { DonationDetails } from './pages/DonationDetails'
import { CashbackStatement } from './pages/CashBackStatement'
import { NetworkMap } from './pages/NetworkMap'
import { DonationProvider } from './contexts/DonationContext'
import { Promotions } from './pages/Promotions'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    element: <Layout />,
    children: [
      {
        path: '/donate/:indication_code',
        element: (
          <DonationProvider>
            <Donation />
          </DonationProvider>
        ),
      },
      {
        path: '/donate/:indication_code/:donor_indication',
        element: (
          <DonationProvider>
            <Donation />
          </DonationProvider>
        ),
      },
      {
        path: '/confirmation-payment/:indication_code/:order_id',
        element: <ConfirmationPage />,
      },
      {
        path: '/voucher/:voucher',
        element: <Voucher />,
      },
      {
        path: '/process/:order_id/:indication_code',
        element: <PaymentScreenAnalysis />,
      },
      {
        path: '/set-password-screen/:token/:email',
        element: <SetPassword />,
      },
      {
        path: '/verify-email',
        element: <VerifySign />,
      },
      {
        path: '/cashback',
        element: (
          <PrivateRoute>
            <CashbackStatement />
          </PrivateRoute>
        ),
      },
      {
        path: '/promos',
        element: (
          <PrivateRoute>
            <Promotions />
          </PrivateRoute>
        ),
      },
      {
        path: '/network/:partnerId',
        element: (
          <PrivateRoute>
            <NetworkMap />
          </PrivateRoute>
        ),
      },
      {
        path: '/network',
        element: (
          <PrivateRoute>
            <NetworkMap />
          </PrivateRoute>
        ),
      },
      {
        path: '/dashboard',
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
      },
      {
        path: '/donationList',
        element: (
          <PrivateRoute>
            <DonationList />
          </PrivateRoute>
        ),
      },
      {
        path: '/donation/details/:id',
        element: (
          <PrivateRoute>
            <DonationDetails />
          </PrivateRoute>
        ),
      },
      {
        path: '/page-withdrawal',
        element: (
          <PrivateRoute>
            <Withdrawal />
          </PrivateRoute>
        ),
      },
      {
        path: '/login',
        element: <SignIn />,
      },
      {
        path: '/sign-up',
        element: (
          <SignUpProvider>
            <SignUp />
          </SignUpProvider>
        ),
      },
    ],
  },
])
