import React from 'react'
import { twMerge } from 'tailwind-merge'
import { Icon } from '@iconify/react'
import { Button } from '@/components/Form'
import { Link } from 'react-router-dom'
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import { VscCopy } from 'react-icons/vsc'
import MoneyAsText from '@/components/Common/MoneyAsText'

export const DesktopSection = ({ onClick, isFavorite, dataStatus, indication_code, isDonationAppwins }) => {
  return (
    <section className='hidden lg:flex lg:justify-around mx-auto'>
      <img src={dataStatus.voucher.qrCode} alt='Confirmation Payment Image' className='w-[512px]' />

      <div className='flex flex-col gap-2 bg-surface rounded-lg shadow-lg p-6 justify-center min-w-[512px]'>
        {!isDonationAppwins && (
          <div className='flex items-center justify-center gap-2'>
            <p className='text-2xl font-bold text-primary'>Favoritar!</p>
            <button
              className={twMerge('rounded-full bg-red-500 p-1', isFavorite && 'bg-red-500 animate-fadeIn')}
              onClick={onClick}
            >
              {isFavorite ? (
                <Icon icon='mdi:heart' color='white' fontSize={24} />
              ) : (
                <Icon icon='mdi:heart-outline' color='white' fontSize={24} />
              )}
            </button>
          </div>
        )}
        <div className='border-b border-b-primary pb-2 lg:border-none'>
          <div className='flex items-center gap-1'>
            <p className='text-lg font-bold'>Voucher</p> <Icon icon='ci:ticket-voucher' fontSize={24} />
          </div>
          <CopyToClipboard
            text={dataStatus.voucher.code}
            onCopy={() =>
              toast.success('Copiado com sucesso!', {
                position: 'top-center',
                autoClose: 2000,
              })
            }
          >
            <div className='flex gap-2 items-center w-full text-center'>
              <div className='text-sm truncate'>{dataStatus.voucher.code}</div>
              <div className=' text-lg'>
                <VscCopy />
              </div>
            </div>
          </CopyToClipboard>
        </div>

        <div className='flex flex-col font-bold w-full'>
          <p>
            Valor Unitário:{' '}
            <span className='font-normal'>
              <MoneyAsText value={dataStatus.donation.unitAmount} />
            </span>
          </p>
          <p>
            Quantidade: <span className='font-normal'>{dataStatus.donation.quantity}</span>
          </p>
          <p>
            Valor Total:{' '}
            <span className='font-semibold text-positive'>
              <MoneyAsText value={dataStatus.donation.finalAmount} />
            </span>
          </p>
        </div>
        <div className='lg:flex flex-col w-full gap-4 border-t border-primary pt-4'>
          <a href={`/voucher/${dataStatus.voucher.code}`}>
            <Button className='w-full' text='Visualizar Voucher' />
          </a>

          <Link to={`/donate/${indication_code}`}>
            <Button text='Voltar para a tela de Doação' className='bg-transparent border border-primary text-primary w-full' />
          </Link>
        </div>
      </div>
    </section>
  )
}
