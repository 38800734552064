import { useVoucherPage } from './hooks'
import { VoucherCard } from './components/VoucherCard'

export const Voucher = () => {
  const { loadingVoucher, dataVoucher, isFavorite, handleFavorite, goToDonatePage } = useVoucherPage()

  return (
    <div className='w-full flex justify-center flex-col lg:flex-row px-4 pb-8 gap-6'>
      <VoucherCard
        onClick={goToDonatePage}
        isLoading={loadingVoucher}
        dataVoucher={dataVoucher}
        favorite={{ isFavorite, handleFavorite }}
      />
    </div>
  )
}
