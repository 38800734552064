import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import './i18n'
import './styles.scss'
import { router } from './routes'
import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { AuthProvider } from './contexts'
import { MultiSelectTheme } from 'chakra-multiselect'

const theme = extendTheme({
  components: {
    MultiSelect: MultiSelectTheme,
  },
})

const App = () => (
  <ChakraProvider theme={theme}>
    <AuthProvider>
      <ToastContainer limit={1} />

      <RouterProvider router={router} />
    </AuthProvider>
  </ChakraProvider>
)

export default App
