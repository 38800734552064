import { Icon } from '@iconify/react'

export const Empty = ({ message }) => {
  return (
    <div className='w-full p-2 rounded-lg min-h-[180px] flex flex-col justify-center items-center bg-surface shadow-lg gap-3 text-negative-dark font-medium text-lg'>
      <Icon className='text-3xl' icon='material-symbols:error-outline' />
      <p>{message}</p>
    </div>
  )
}
