import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useFetchDonationList } from './useFetchDonationList'
import { useEffect, useState } from 'react'
import { FilterBuilder } from '@/utils'
import { useAuth } from '@/contexts'

export const useDonationList = () => {
  const { fetchDonation, isLoadingDonation, donationList: dataDonationList, metaDonation } = useFetchDonationList()
  const { user, userGroup } = useAuth()
  const [filtersParams, setFilters] = useState({})
  const onFetchDonation = params => {
    fetchDonation({ ...filtersParams, ...params })
  }

  const filterSchema = Yup.object().shape({
    term: Yup.string(),
    donationCode: Yup.string().min(14, 'O campo deve conter pelo menos 14 caracteres!'),
    partner: Yup.string(),
    status: Yup.number(),
    initialDate: Yup.string(),
    endDate: Yup.string(),
    // endDate: Yup.string().when('initialDate', {
    //   is: value => (!value ? false : value.length > 1),
    //   then: () => Yup.string().required('Campo obrigatório!'),
    //   otherwise: Yup.string().notRequired(),
    // }),
  })

  const onSubmit = async values => {
    const filters = FilterBuilder()
      .like('donor.name', values.term)
      .like('partner.name', userGroup.isAdmin ? values.partner : null)
      .like('number', values.donationCode)
      .equal('status', values.status > 0 ? values.status : null)
      .between('createdAt', `${values.initialDate}${values.endDate.length > 0 ? `,${values.endDate}` : ''}`)
      .build()

    setFilters(filters)
    await fetchDonation(filters)
    return
  }

  const filterFormParams = useFormik({
    initialValues: {
      term: '',
      donationCode: '',
      partner: '',
      status: '1',
      initialDate: '',
      endDate: '',
    },
    enableReinitialize: true,
    onSubmit,
    validationSchema: filterSchema,
    validateOnBlur: false,
    validateOnChange: true,
  })

  useEffect(() => {
    if (filterFormParams.values.initialDate.length === 0) {
      filterFormParams.setFieldError('endDate', '')
      filterFormParams.setFieldValue('endDate', '')
    }
  }, [filterFormParams.values.initialDate])

  useEffect(() => {
    const filters = FilterBuilder().equal('status', '1').build()

    filterFormParams.setFieldValue('status', '1')
    user && fetchDonation(filters)
  }, [user])

  const handleResetFilter = () => {
    filterFormParams.resetForm()
    filterFormParams.setFieldValue('status', '1')
    const filters = FilterBuilder().equal('status', '1').build()
    fetchDonation(filters)
  }

  return { filterFormParams, onFetchDonation, dataDonationList, isLoading: isLoadingDonation, metaDonation, handleResetFilter }
}
