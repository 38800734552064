import React from 'react'
import PropTypes from 'prop-types'
import { twMerge } from 'tailwind-merge'

export const Textarea = ({ name, className, value, onChange, isDisabled, isInvalid, placeholder = 'Digite Aqui', ...rest }) => {
  const { id } = rest
  const inputClassName = twMerge(
    'w-full block bg-zinc-50 py-2 px-3 border rounded-lg min-h-[140px] outline-none transition ease-in-out duration-300 delay-100 focus:border-gray-300 placeholder:text-opacity-50',
    className,
    isInvalid && 'text-negative border-negative',
    isDisabled && 'text-gray-100 opacity-30'
  )
  return (
    <textarea
      name={name}
      id={id ?? name}
      value={value}
      onChange={onChange}
      disabled={isDisabled}
      className={inputClassName}
      placeholder={placeholder}
      {...rest}
    />
  )
}

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
}

Textarea.defaultProps = {
  isDisabled: false,
  isInvalid: false,
}
