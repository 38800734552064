import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

export const useSignUpForm = ({ onSubmit }) => {
  const { t } = useTranslation()

  const schema = Yup.object({
    name: Yup.string().required(t('global.validation.required')),
    email: Yup.string().email(t('signUp.validation.invalidEmail')).required(t('global.validation.required')),
    phone: Yup.string()
      .test('lengthMinPhone', 'Digite um número válido', value => {
        return value?.replace(/\D/g, '').length === 11 || !value
      })
      .required(t('global.validation.required')),
    typeCustomer: Yup.string().required(),
    cpfCnpj: Yup.string().when('typeCustomer', {
      is: 'cpf',
      then: Yup.string()
        .test('lengthMinCpf', 'Digite um CPF válido', value => {
          return value?.replace(/\D/g, '').length === 11 || !value
        })
        .required(t('global.validation.required')),
      otherwise: Yup.string()
        .test('lengthMinCnpj', 'Digite um CNPJ válido', value => {
          return value?.replace(/\D/g, '').length === 14 || !value
        })
        .required(t('global.validation.required')),
    }),
    segments: Yup.array().min(1, t('global.validation.required')).required(t('global.validation.required')),
    state: Yup.string().required(t('global.validation.required')),
    city: Yup.string().required(t('global.validation.required')),
    street: Yup.string().required(t('global.validation.required')),
    number: Yup.string().required(t('global.validation.required')),
    neighborhood: Yup.string().required(t('global.validation.required')),
    complement: Yup.string(),
    zipCode: Yup.string()
      .test('lengthMinZipCode', 'Digite um CEP válido', value => {
        return value?.replace(/\D/g, '').length === 8 || !value
      })
      .required(t('global.validation.required')),
    password: Yup.string().required(t('global.validation.required')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('signUp.validation.validationRequiredConfirmPassword'))
      .required(t('signUp.validation.validationConfirmPassword')),
    check: Yup.boolean().oneOf([true]).required(t('global.validation.required')),
  })

  return useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      typeCustomer: 'cpf',
      number: '',
      neighborhood: '',
      cpfCnpj: '',
      segments: [],
      state: '',
      city: '',
      street: '',
      zipCode: '',
      complement: '',
      password: '',
      confirmPassword: '',
      check: false,
    },
    onSubmit,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
