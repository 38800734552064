import { CpfCnpjField, PhoneField, TextField } from '@/components/Form/Fields'
import React from 'react'

export const PersonalStep = ({ values, errors, handleChange, setFieldValue, verifyAppwins }) => {
  return (
    <>
      <h2 className='text-lg font-semibold border-t border-zinc-300 py-3'>Dados de pagamento</h2>

      <CpfCnpjField
        name='cpf'
        value={values.cpf}
        onChange={handleChange}
        errorMessage={errors.cpf}
        label='CPF'
        placeholder='Ex.: 349.251.155-45'
        info='Esse dado será usado somente para o pagamento desta doação!'
        className='mb-5'
      />

      <h2 className='text-lg font-semibold border-t border-zinc-300 py-3'> Dados Pessoais</h2>

      <TextField
        label='Nome'
        name='name'
        placeholder='Ex.: Eliane Louise Giovana Castro'
        value={values.name}
        errorMessage={errors.name}
        onChange={handleChange}
      />

      <TextField
        label='E-mail'
        name='email'
        placeholder='Ex.: eliane_castro@boldcron.com.br'
        value={values.email}
        errorMessage={errors.email}
        onChange={handleChange}
        isDisabled={!verifyAppwins}
      />

      <PhoneField
        label='Telefone'
        name='phone'
        placeholder='Ex.: (32) 99481-8925'
        value={values.phone}
        errorMessage={errors.phone}
        onChange={v => setFieldValue('phone', v.value)}
      />
    </>
  )
}
