import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export function useGetCashbackStatement() {
  const { data, error, loading, meta, makeRequest } = useApi(params => httpClient.get(`/cashbacks/extract`, { params }))

  return {
    dataCashbackStatement: data,
    metaCashbackStatement: meta,
    errorCashbackStatement: error,
    loadingCashbackStatement: loading,
    fetchCashbackStatement: makeRequest,
  }
}
