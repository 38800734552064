import { DateAsText } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { StatusAsText } from '@/components/Common/StatusAsText'
import { Button } from '@/components/Form'
import { useAuth } from '@/contexts'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { VoucherStatus } from './VoucherStatus'
import { ModalVoucher } from './ModalVoucher'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const TableList = ({ dataDonationList, onFetchDonation }) => {
  const { userGroup } = useAuth()
  const [voucherDetails, setVoucherDetails] = useState()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()

  const handleModalIsOpen = donationItem => {
    setVoucherDetails(donationItem)
    onOpen()
  }

  const handleClickDetails = id => {
    navigate(`/donation/details/${id}`)
  }

  const MenuOptions = ({ children, placement, mobile, donationDetails }) => {
    return (
      <Menu placement={placement}>
        <MenuButton className='w-full'>{children}</MenuButton>

        <MenuList>
          {!mobile && (
            <MenuItem paddingY={3} onClick={() => handleClickDetails(donationDetails.id)}>
              <Icon className='text-2xl mr-1.5 text-info-dark' icon='mdi:file-search-outline' /> Ver detalhes
            </MenuItem>
          )}

          {!mobile && donationDetails?.voucher && (
            <MenuItem paddingY={3} onClick={() => handleModalIsOpen(donationDetails)}>
              <Icon className='text-2xl mr-1.5 text-yellow-500' icon='ci:ticket-voucher' /> Ver Voucher
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    )
  }

  return (
    <>
      <TableContainer className='max-lg:!hidden bg-surface shadow-xl rounded mt-4 overflow-hidden'>
        <Table size='md'>
          <Thead>
            <Tr>
              <Th>Doador</Th>
              <Th textAlign='center'>Código da doação</Th>
              <Th>Data de Solicitação</Th>
              <Th textAlign='end'>Valor da Doação</Th>
              {!userGroup.isPartner && <Th>Parceiro</Th>}
              <Th>Pagamento</Th>
              {userGroup.isPartner && <Th textAlign='center'>Voucher</Th>}
              <Th>Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {dataDonationList?.map(item => (
              <Tr key={item.id}>
                <Td className='max-w-[320px]'>
                  <Tooltip label={item.donor.name}>
                    <div className='w-full truncate'>{item.donor.name}</div>
                  </Tooltip>
                </Td>

                <Td>
                  <div className='w-full truncate'>{item.number}</div>
                </Td>

                <Td textAlign='center'>
                  <DateAsText date={item.createdAt} />
                </Td>

                <Td textAlign='end'>
                  <MoneyAsText className='w-full text-center' value={item.payment.finalAmount} />
                </Td>

                {!userGroup.isPartner && (
                  <Td className='max-w-[320px]'>
                    <Tooltip label={item.partner.name}>
                      <div className='w-full truncate'>{item.partner.name}</div>
                    </Tooltip>
                  </Td>
                )}

                <Td>
                  <StatusAsText status={item.payment.status} statusClassName='py-1 px-2' />
                </Td>

                {userGroup.isPartner && (
                  <Td textAlign='center'>
                    <VoucherStatus
                      status={item.voucher ? item.voucher.isAvailable : 'Indisponível'}
                      statusClassName='py-1 px-2'
                    />
                  </Td>
                )}

                <Td className='flex justify-center'>
                  <MenuOptions donationDetails={item}>
                    <Icon className='mx-auto' icon='simple-line-icons:options-vertical' />
                  </MenuOptions>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <div className='lg:hidden flex flex-col gap-4'>
        {dataDonationList?.map(item => (
          <div key={item.id} className='flex flex-col bg-surface rounded-lg w-full my-2 animate-fadeIn gap-2 text-text shadow-lg'>
            <CardCell label='Doador'>
              <span className='truncate'>{item.donor.name}</span>
            </CardCell>

            <CardCell label='Código da doação'>
              <span className='truncate max-w-[75%]'>{item.number}</span>
            </CardCell>

            <CardCell label='Data de Solicitação'>
              <DateAsText date={item.createdAt} />
            </CardCell>

            <CardCell label='Valor da Doação'>
              <MoneyAsText value={item.payment.finalAmount} />
            </CardCell>

            {userGroup.isAdmin && (
              <CardCell label='Parceiro'>
                <span className='truncate max-w-[75%]'>{item.partner.name}</span>
              </CardCell>
            )}

            <CardCell label='Status'>
              <StatusAsText status={item.payment.status} />
            </CardCell>

            {item.payment.status === 2 && (
              <CardCell label='Voucher'>
                <div className='flex items-center gap-2'>
                  <VoucherStatus status={item.voucher.isAvailable} />

                  <Button
                    className='p-2 bg-primary-800/10 text-text border-primary-600 rounded-lg gap-1'
                    onClick={() => handleModalIsOpen(item)}
                  >
                    <Icon className='text-lg' icon='majesticons:open-line' />
                  </Button>
                </div>
              </CardCell>
            )}

            <div className='flex items-center justify-end gap-4 p-3 w-full'>
              <Button
                onClick={() => handleClickDetails(item.id)}
                text='Ver detalhes'
                className='px-4 py-2 bg-primary rounded w-full'
              />
            </div>
          </div>
        ))}
      </div>

      <ModalVoucher isOpen={isOpen} onClose={onClose} data={voucherDetails} fetchDonation={onFetchDonation} />
    </>
  )
}

const CardCell = ({ label = '', children }) => {
  return (
    <div className='flex items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
      <span className='font-medium'>{label}</span>
      {children}
    </div>
  )
}
