import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useGetNetwork } from './useGetNetwork'
import { useNavigate, useParams } from 'react-router-dom'

export const usePageNetwork = () => {
  const getNetwork = useGetNetwork()
  const { partnerId } = useParams()
  const navigate = useNavigate()
  const [activeFilters, setActiveFilters] = useState([])

  const onExpandNetwork = async childCode => {
    await getNetwork.fetchNetwork(childCode)
    handleResetFilter()
  }

  const handleClickDonation = donationId => {
    navigate(`/donation/details/${donationId}`)
  }

  const filterSchema = yup.object({
    indicationCode: yup.string(),
  })

  const onSubmit = values => {
    getNetwork.fetchNetwork(values.indicationCode)
    const newFilters = Object.entries(values)
    setActiveFilters(newFilters.filter(i => i[1] !== '').map(i => ({ label: i[0], value: i[1] })))
  }

  const filterParams = useFormik({
    initialValues: {
      indicationCode: '',
    },
    onSubmit,
    validationSchema: filterSchema,
    validateOnBlur: false,
    validateOnChange: false,
  })

  useEffect(() => {
    getNetwork.fetchNetwork(partnerId || null)
  }, [])

  const handleResetFilter = () => {
    filterParams.resetForm()
    setActiveFilters([])
  }

  return {
    filterParams,
    onExpandNetwork,
    handleClickDonation,
    getNetwork,
    isLoading: getNetwork.isLoading,
    handleResetFilter,
    activeFilters,
  }
}
