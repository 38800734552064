import { useFetchPartner } from '@/pages/Donation/hook'
import { useGetStatus } from './useGetStatus'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

export const useAnalysis = () => {
  const navigate = useNavigate()
  const [isDonationAppwins, setIsDonationAppwins] = useState(false)
  const { indication_code, order_id } = useParams()
  const { dataStatus, errorStatus, getStatus, loadingStatus } = useGetStatus()
  const { dataPartner, fetchPartner, loadingPartner } = useFetchPartner()
  const isLoading = loadingStatus && loadingPartner

  useEffect(() => {
    if (!dataPartner) return
    setIsDonationAppwins(dataPartner.id === '2916e835-c3b6-4cf0-a211-fab36a494846')
  }, [dataPartner])

  useEffect(() => {
    fetchPartner(indication_code)
    getStatus(order_id).then(res => {
      if (res.data.data.status === 3) {
        Swal.fire({
          title: 'Sua doação foi recusada!',
          text: 'Verifique as informações fornecidas e tente novamente.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'Fechar',
        }).then(() => {
          navigate(`/donate/${indication_code}`)
        })

        return
      }

      const timer = setInterval(() => {
        getStatus(order_id)
          .then(res => {
            if (res.data.data.status === 2) {
              clearInterval(timer)
              navigate(`/confirmation-payment/${indication_code}/${order_id}`)
            }

            if (res.data.data.status === 3) {
              clearInterval(timer)
              Swal.fire({
                title: 'Sua doação foi recusada!',
                text: 'Verifique as informações fornecidas e tente novamente.',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'Fechar',
              }).then(() => {
                navigate(`/donate/${indication_code}`)
              })

              return
            }
          })
          .catch(() => {
            clearInterval(timer)
          })
      }, 2000)
    })
  }, [])

  return {
    dataStatus,
    errorStatus,
    dataPartner,
    isLoading,
    indication_code,
    isDonationAppwins,
    order_id,
  }
}
