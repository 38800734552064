import { Button } from '@/components/Form'
import { DateField, SelectField, TextField } from '@/components/Form/Fields'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react'
import { inputStyles } from '../Dashboard/constants/styles'
import { twMerge } from 'tailwind-merge'
import { useGetPartnerCashback } from './hooks/useGetPartnerCashback'
import { useEffect } from 'react'
import { useAuth } from '@/contexts'
import { DateAsText } from '@/components/Common'

export const CashbackFilter = ({ filtersParams, loadingPartnerCashback, activeFilters, handleClearFilter }) => {
  const { handleSubmit, handleChange, values, errors, setFieldValue } = filtersParams
  const { dataPartnerCashback, fetchPartnerCashback } = useGetPartnerCashback()

  const { userGroup, user } = useAuth()

  const selectOptions = dataPartnerCashback?.map(item => ({ label: item.name, value: item.id })) || []

  useEffect(() => {
    if (!user) return
    if (userGroup.isAdmin) {
      fetchPartnerCashback()
    }
  }, [user])

  const renderTag = (label, value) => {
    switch (label) {
      case 'termDonor':
        return `Nome do Doador: ${value}`
      case 'termPartner':
        return `Nome do Parceiro: ${selectOptions.find(item => item.value === value).label}`
      case 'initialDate':
        return (
          <>
            Data inicial: <DateAsText date={value} />
          </>
        )
      case 'endDate':
        return (
          <>
            Data final: <DateAsText date={value} />
          </>
        )
    }
  }

  return (
    <Accordion allowToggle className='bg-surface shadow-lg rounded' padding={1} pt={2}>
      <AccordionItem border={0}>
        <AccordionButton>
          <div className='w-full flex flex-col items-start'>
            <div className='w-full flex justify-between gap-2'>
              <h3 className='text-lg font-medium text-left'>Filtros de busca</h3>
              <AccordionIcon fontSize={28} />
            </div>

            {activeFilters.length > 0 && (
              <div className='flex gap-2 justify-start items-center flex-wrap py-2'>
                {activeFilters.map((filter, ind) => (
                  <span className='bg-tertiary-600 text-zinc-50 p-1 px-3 rounded text-sm' key={ind}>
                    {renderTag(filter.label, filter.value)}
                  </span>
                ))}
              </div>
            )}
          </div>
        </AccordionButton>

        <AccordionPanel>
          <form onSubmit={handleSubmit} className='grid lg:grid-cols-2 xl:grid-cols-4 items-start gap-3'>
            {userGroup.isAdmin && (
              <TextField
                label='Nome do Doador'
                name='termDonor'
                value={values.termDonor}
                onChange={e => setFieldValue('termDonor', e.target.value)}
                errorMessage={errors.termDonor}
                placeholder='Ex.: joao da silva'
                inputClassName={inputStyles}
              />
            )}

            {userGroup.isAdmin && (
              <SelectField
                label='Nome do parceiro'
                optionPrimary='Selecione um parceiro'
                name='termPartner'
                options={selectOptions}
                value={values.termPartner}
                onChange={e => setFieldValue('termPartner', e.target.value)}
                errorMessage={errors.termPartner}
                placeholder='Ex.: joao da silva'
                inputClassName={inputStyles}
              />
            )}

            <DateField
              label='Data inicial'
              name='initialDate'
              value={values.initialDate}
              onChange={handleChange}
              errorMessage={errors.initialDate}
              inputClassName={inputStyles}
            />

            <DateField
              label='Data final'
              name='endDate'
              value={values.endDate}
              onChange={handleChange}
              errorMessage={errors.endDate}
              inputClassName={twMerge(inputStyles, values.initialDate.length === 0 && 'text-opacity-50')}
              isDisabled={values.initialDate.length === 0}
              min={values.initialDate}
            />

            <div className={twMerge('max-xl:hidden col-span-1 xl:col-span-2', !userGroup.isAdmin && 'lg:col-span-1 xl:hidden')} />

            <Button
              type='button'
              className={twMerge('w-full p-2 rounded xl:col-span-1', !userGroup.isAdmin && 'md:p-2.5 xl:mt-6')}
              isDisabled={loadingPartnerCashback}
              onClick={handleClearFilter}
            >
              Limpar
            </Button>
            <Button
              type='submit'
              className={twMerge('w-full p-2 rounded xl:col-span-1', !userGroup.isAdmin && 'md:p-2.5 xl:mt-6')}
              isDisabled={loadingPartnerCashback}
            >
              Pesquisar
            </Button>
          </form>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
