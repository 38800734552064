export const paymentStatus = {
  PENDING: 1,
  APPROVED: 2,
  REFUSED: 3,
  WAITING: 4,
}

export const activeMethod = {
  PIX_STATIC: 'STATIC_PIX',
  PIX_GATEWAY: 'GATEWAY',
}
