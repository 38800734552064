import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { useAuth } from '@/contexts'
import Swal from 'sweetalert2'
import { usePostNewPromo } from './useApi'

export const useNewPromo = ({ onClose, onOpen }) => {
  const [bannerFile, setBannerFile] = useState({
    file: null,
    preview: null,
  })
  const [step, setStep] = useState(1)
  const { postPromo, loadingPostPromo, errorPostPromo } = usePostNewPromo()
  const { makeRequest } = useAuth()

  const schema = yup.object({
    title: yup.string().required('Campo obrigatório!'),
    body: yup.string().required('Campo obrigatório!'),
    banner: yup.string().required('Campo obrigatório!'),
  })

  const onSubmit = async values => {
    if (step === 2) {
      onClose()
      setStep(prev => prev + 1)
      Swal.fire({
        title: 'Atenção!',
        text: 'Após o envio não será possível modificar a promoção! Deseja continuar?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Fechar',
        confirmButtonText: 'Confirmar',
        reverseButtons: true,
      }).then(async result => {
        if (result.isConfirmed) {
          const formData = new FormData()

          formData.append('title', values.title)
          formData.append('description', values.body)
          formData.append('image', bannerFile.file)

          await postPromo(formData).then(async () => {
            await makeRequest()
            toast.success('Promoção enviada com sucesso!')
            handleCancel()
          })

          return
        }
        onOpen()
        setStep(prev => prev - 1)
      })
      return
    }
    setStep(prev => prev + 1)
  }

  const formParams = useFormik({
    initialValues: {
      title: '',
      body: '',
      banner: '',
    },
    onSubmit,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: false,
  })

  useEffect(() => {
    if (!errorPostPromo) return
    handleCancel()
    if (errorPostPromo.error === 'promotionTodayCreationLimitReached') {
      toast.error('Limite diário para criação de promoções excedido.')
      return
    }
    if (errorPostPromo.error === 'invalidFileExtension') {
      toast.error('Arquivo com extensão inválida.')
      return
    }
    toast.error('Erro ao criar promoção.')
  }, [errorPostPromo])

  const handleChangeBanner = file => {
    const acceptFiles = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf']

    if (!acceptFiles.includes(file.type)) {
      toast.warn('Arquivo com extensão inválida.')
      return
    }

    const previewUrl = file.type.includes('image') ? URL.createObjectURL(file) : null
    formParams.setFieldValue('banner', file.name)

    setBannerFile({
      file,
      preview: previewUrl,
    })
  }

  const handleCancel = () => {
    if (step === 1) {
      onClose()
      formParams.resetForm()
      setBannerFile({ preview: null, file: null })
      return
    }
    setStep(1)
  }

  return { formParams, handleChangeBanner, bannerFile, handleCancel, loadingPostPromo, step }
}
