import { Button } from '@/components/Form'
import { TextField } from '@/components/Form/Fields'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react'
import { twMerge } from 'tailwind-merge'

export const Filters = ({ filterParams, activeFilters, handleResetFilter }) => {
  const { values, errors, handleChange, handleSubmit } = filterParams
  const inputStyles = twMerge('rounded border-primary-blue/40 focus:border-primary bg-zinc-300/50 placeholder:text-zinc-800')

  const renderTag = (label, value) => {
    switch (label) {
      case 'indicationCode':
        return `Código de indicação: ${value}`
    }
  }

  return (
    <Accordion allowToggle className='bg-surface shadow-lg rounded' padding={1} pt={2}>
      <AccordionItem border={0}>
        <AccordionButton>
          <div className='w-full flex flex-col items-start'>
            <div className='w-full flex justify-between gap-2'>
              <h3 className='text-lg font-medium text-left'>Filtros de busca</h3>
              <AccordionIcon fontSize={28} />
            </div>

            {activeFilters.length > 0 && (
              <div className='flex gap-2 justify-start items-center flex-wrap py-2'>
                {activeFilters.map((filter, ind) => (
                  <span className='bg-tertiary-600 text-zinc-50 p-1 px-3 rounded text-sm' key={ind}>
                    {renderTag(filter.label, filter.value)}
                  </span>
                ))}
              </div>
            )}
          </div>
        </AccordionButton>

        <AccordionPanel>
          <form onSubmit={handleSubmit} className='flex max-sm:flex-col items-start gap-3'>
            <TextField
              label='Código de indicação'
              name='indicationCode'
              value={values.indicationCode}
              onChange={handleChange}
              errorMessage={errors.indicationCode}
              placeholder='0123456789012'
              inputClassName={inputStyles}
              className='w-full'
              maxlength={25}
            />

            <Button type='button' onClick={handleResetFilter} className='w-full p-2 mt-6 rounded min-w-[160px]'>
              Limpar
            </Button>
            <Button type='submit' className='w-full p-2 mt-6 rounded min-w-[160px]'>
              Pesquisar
            </Button>
          </form>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
