import React from 'react'
import { Button } from '@/components/Form'
import { PasswordField, TextField } from '@/components/Form/Fields'
import phoneIcon from '@/assets/images/phone.svg'
import { Link } from 'react-router-dom'

export const SignInForm = ({ loading, handleSubmit, handleChange, setFieldValue, values = {}, errors = {} }) => {
  return (
    <form onSubmit={handleSubmit} className='flex flex-col gap-6 p-4 py-12 px-6 bg-surface rounded-lg shadow-lg w-full max-w-lg'>
      <div className='flex items-center justify-center gap-1'>
        <img src={phoneIcon} className='w-9' />
        <h1 className='text-xl text-[#186885] font-semibold'>Entrar na Wins</h1>
      </div>
      <div className='flex flex-col'>
        <TextField
          label='E-mail'
          name='email'
          placeholder='Ex.: joao@email.com'
          value={values.email}
          errorMessage={errors.email}
          onChange={e => setFieldValue('email', e.target.value.trim())}
          isDisabled={loading}
          inputClassName='border-primary-600 rounded bg-transparent'
          className='text-base'
        />
        <PasswordField
          label='Senha'
          name='password'
          placeholder='******'
          value={values.password}
          errorMessage={errors.password}
          onChange={handleChange}
          isDisabled={loading}
          inputClassName='border-primary-600 rounded bg-transparent'
        />
        {/* <div className='w-full flex justify-start'>
          <Button
            type='button'
            className='text-info-dark underline underline-offset-2 -mt-1 text-sm bg-transparent p-0'
            onClick={handleModalIsOpen}
            text='Esqueci minha senha'
          />
        </div> */}
      </div>
      <Button text='Continuar' type='submit' isDisabled={loading} isLoading={loading} className='justify-center rounded py-2.5' />

      <div className='w-full flex justify-start gap-x-1 flex-wrap'>
        Primeiro acesso?{' '}
        <Link to='/verify-email' className='text-info-dark underline underline-offset-2 font-medium'>
          Verifique seu e-mail
        </Link>
      </div>
    </form>
  )
}
