import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { FilterBuilder } from '@/utils'
import { useGetCashbackStatement } from './useGetCashbackStatement'

export const useCashback = () => {
  const { fetchCashbackStatement, dataCashbackStatement, metaCashbackStatement, loadingCashbackStatement } =
    useGetCashbackStatement()

  const [filtersQueries, setFiltersQueries] = useState({})
  const [activeFilters, setActiveFilters] = useState([])

  const onFetchCashback = params => {
    fetchCashbackStatement({ ...filtersQueries, ...params })
  }

  const filterSchema = yup.object({
    termDonor: yup.string(),
    termPartner: yup.string(),
    initialDate: yup.string(),
    endDate: yup.string(),
  })

  useEffect(() => {
    fetchCashbackStatement()
  }, [])

  const onSubmit = values => {
    const filters = FilterBuilder()
      .like('receiver.name', values.termDonor.length > 0 ? values.termDonor : null)
      .equal('partner.id', values.termPartner.length > 0 ? values.termPartner : null)
      .between(
        'createdAt',
        values.initialDate.length > 0 ? `${values.initialDate}${values.endDate.length > 0 ? `,${values.endDate}` : ''}` : null
      )
      .build()

    setFiltersQueries(filters)
    const newFilters = Object.entries(values)
    setActiveFilters(newFilters.filter(i => i[1] !== '').map(i => ({ label: i[0], value: i[1] })))
    fetchCashbackStatement(filters)
    return
  }

  const filterParams = useFormik({
    initialValues: {
      termDonor: '',
      termPartner: '',
      initialDate: '',
      endDate: '',
    },
    onSubmit,
    validationSchema: filterSchema,
    validateOnBlur: false,
    validateOnChange: false,
  })

  useEffect(() => {
    filterParams.values.initialDate.length === 0 && filterParams.setFieldError('endDate', '')
  }, [filterParams.values.initialDate])

  const handleClearFilter = () => {
    filterParams.resetForm()
    setActiveFilters([])
    fetchCashbackStatement()
  }

  return {
    filterParams,
    dataCashbackStatement,
    metaCashbackStatement,
    onFetchCashback,
    loadingCashbackStatement,
    activeFilters,
    handleClearFilter,
  }
}
