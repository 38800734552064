import React, { createContext, useContext, useEffect, useState } from 'react'
import httpClient from '@/utils/httpClient'
import { useApi } from '@/hooks/useApi'

const AuthContext = createContext({
  token: null,
  user: null,
  userGroup: {
    isDonor: false,
    isPartner: false,
    isAdmin: false,
  },
})

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(window.localStorage.getItem('auth:user'))
  const [user, setUser] = useState(null)
  const { data, makeRequest, loading } = useApi(() => httpClient.get('/me'))

  const userGroup = {
    isDonor: user && user.group.key === 'DONOR',
    isPartner: user && user.group.key === 'PARTNER',
    isAdmin: user && user.group.key === 'ADMIN',
  }

  useEffect(() => {
    if (!token) {
      setUser(null)
      window.localStorage.removeItem('auth:user')
      return
    }
    window.localStorage.setItem('auth:user', token)
  }, [token])

  useEffect(() => {
    if (!data) return
    setUser(data)
  }, [data])

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        user,
        loading,
        makeRequest,
        userGroup,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => ({ ...useContext(AuthContext) })
