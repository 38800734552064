import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '@/contexts/AuthContext'
import { Navigate } from 'react-router-dom'

import { twMerge } from 'tailwind-merge'
import { Icon } from '@iconify/react'

export const ScreenLoading = () => {
  const { loading } = useAuth()

  return (
    <div
      className={twMerge(
        'flex flex-col w-full h-screen justify-center none items-center bg-gray-200 transition-all duration-300 ease-in-out',
        loading && 'opacity-100',
        !loading && 'opacity-0 hide'
      )}
    >
      <Icon icon='mingcute:loading-line' className='w-8 animate-spin' />
    </div>
  )
}

export const PrivateRoute = ({ children }) => {
  const { token, loading, makeRequest } = useAuth()
  const location = useLocation()

  const isLoadingAuth = loading
  const isLoggedUser = !loading && token

  useEffect(() => {
    makeRequest()
  }, [token])

  return isLoadingAuth ? <ScreenLoading /> : isLoggedUser ? children : <Navigate to='/login' replace state={{ from: location }} />
}
