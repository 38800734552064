import { useEffect } from 'react'
import { useGetLinks } from '../../hooks/useGetLinks'
import { useGetDonations } from '../../hooks/useGetMyDonations'

export const useDonations = () => {
  const { dataLinks, fetchLinks } = useGetLinks()
  const { dataDonations, loadingDonations, fetchDonations } = useGetDonations()

  const data = {
    donations: dataDonations,
    links: dataLinks,
  }

  const fetchData = async () => {
    await Promise.all([fetchDonations()])
  }

  const onViewLinks = async id => {
    await fetchLinks(id)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return {
    data,
    isLoadingData: loadingDonations,
    onViewLinks,
  }
}
