import { Link } from 'react-router-dom'

export const Companies = () => {
  return (
    <section className='bg-white'>
      <div className='w-full relative h-80 justify-center bg-section-companies flex-wrap flex lg:flex-row lg:justify-around lg:gap-6 lg:px-20'>
        <div className='absolute size-full bg-gradient-to-r from-[#44618C] to-[#44618C] opacity-65'></div>
        <div className='size-full absolute flex items-center justify-center'>
          <Link
            className='font-semibold rounded-lg text-2xl text-white min-w-96 h-16 bg-[#558CDE] flex items-center justify-center hover:bg-[#558CDE]/90'
            target='_blank'
            to='https://parceiros.winsacaoviver.org/'
          >
            Conheça os parceiros Wins
          </Link>
        </div>
      </div>
    </section>
  )
}
