import { DateAsText } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { Button } from '@/components/Form'
import { useAuth } from '@/contexts'
import { Table, TableContainer, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { toast } from 'react-toastify'

export const CashbackTable = ({ dataCashbackStatement }) => {
  const { userGroup } = useAuth()

  const handleCopyLink = link => {
    navigator.clipboard.writeText(link)

    toast.success('Copiado com sucesso!', {
      position: 'top-center',
      autoClose: 2000,
      toastId: 'copied',
    })
  }

  return (
    <>
      <TableContainer className='max-md:!hidden bg-surface shadow-xl rounded mt-4 overflow-hidden'>
        <Table size='md'>
          <Thead>
            <Tr>
              {(userGroup.isAdmin || userGroup.isPartner) && (
                <>
                  <Th>Recebedor</Th>
                  <Th>E-mail do Recebedor</Th>
                </>
              )}
              {(userGroup.isAdmin || userGroup.isDonor) && (
                <>
                  <Th>Parceiro</Th>
                  <Th>E-mail do Parceiro</Th>
                </>
              )}
              <Th textAlign='end'>Valor</Th>
              <Th>Data do pagamento</Th>
              <Th>Link de indicação</Th>
            </Tr>
          </Thead>
          <Tbody>
            {dataCashbackStatement?.map(item => (
              <Tr key={item.id}>
                {(userGroup.isAdmin || userGroup.isPartner) && (
                  <>
                    <Td className='max-w-[320px]'>
                      <Tooltip label={item.receiver.name}>
                        <div className='w-full flex items-center truncate'>{item.receiver.name}</div>
                      </Tooltip>
                    </Td>

                    <Td className='max-w-[360px]'>
                      <Tooltip label={item.receiver.email}>
                        <div className='w-full truncate'>{item.receiver.email}</div>
                      </Tooltip>
                    </Td>
                  </>
                )}

                {(userGroup.isAdmin || userGroup.isDonor) && (
                  <>
                    <Td>
                      <Tooltip label={item.partner.name}>
                        <div className='w-full flex items-center truncate'>{item.partner.name}</div>
                      </Tooltip>
                    </Td>

                    <Td className='max-w-[360px]'>
                      <Tooltip label={item.receiver.email}>
                        <div className='w-full truncate'>{item.partner.email}</div>
                      </Tooltip>
                    </Td>
                  </>
                )}

                <Td textAlign='end'>
                  <MoneyAsText prefix='R$ ' value={item.amount} />
                </Td>

                <Td>
                  <DateAsText date={item.createdAt} />
                </Td>

                <Td className='max-w-[360px]'>
                  <div className='w-full flex items-center gap-2'>
                    <Tooltip label={item.link}>
                      <p className='truncate'>{item.link}</p>
                    </Tooltip>

                    <Button
                      className='p-2 bg-primary-800/10 text-text border-primary-600 rounded-lg gap-1'
                      onClick={() => handleCopyLink(item.link)}
                    >
                      <Icon className='text-lg' icon='tabler:copy' />
                    </Button>
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <div className='md:hidden flex flex-col gap-4'>
        {dataCashbackStatement?.map(item => (
          <div key={item.id} className='flex flex-col bg-surface rounded-lg w-full my-2 animate-fadeIn gap-2 text-text shadow-lg'>
            {(userGroup.isAdmin || userGroup.isPartner) && (
              <>
                <div className='flex items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
                  <span className='font-medium'>Doador</span>
                  <div className='max-w-[80%] text-end truncate'>{item.receiver.name}</div>
                </div>

                <div className='flex items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
                  <span className='font-medium whitespace-wrap'>E-mail do Doador</span>
                  <span className='truncate max-w-[75%]'>{item.receiver.email}</span>
                </div>
              </>
            )}

            {(userGroup.isAdmin || userGroup.isDonor) && (
              <>
                <div className='flex items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
                  <span className='font-medium'>Parceiro</span>
                  <span>{item.partner.name}</span>
                </div>
                <div className='flex items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
                  <span className='font-medium whitespace-wrap'>E-mail do Parceiro</span>
                  <span className='truncate max-w-[75%]'>{item.partner.email}</span>
                </div>
              </>
            )}

            <div className='flex items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
              <span className='font-medium'>Valor</span>
              <MoneyAsText prefix='R$ ' value={item.amount} />
            </div>

            <div className='flex items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
              <span className='font-medium'>Data do pagamento</span>
              <DateAsText date={item.createdAt} />
            </div>

            <div className='flex items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
              <span className='font-medium'>Link de indicação</span>

              <span className='w-full truncate'>{item.link}</span>

              <Button
                className='p-2 bg-primary-800/10 text-text border-primary-600 rounded-lg gap-1'
                onClick={() => handleCopyLink(item.link)}
              >
                <Icon className='text-lg' icon='tabler:copy' />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
