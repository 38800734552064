import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { useCreatePassword } from './useCreatePassword'
import { useFormSetPasswordScreen } from './useFormSetPasswordScreen'
import Swal from 'sweetalert2'

export const useSetPassword = () => {
  const navigate = useNavigate()
  const { email, token } = useParams()
  const { createPassword } = useCreatePassword()
  const formParams = useFormSetPasswordScreen({
    onSubmit: values => {
      createPassword(token, { password: values.password })
        .then(() => {
          toast.success('Sua conta foi ativada com sucesso!', {
            position: 'top-center',
            autoClose: 2000,
          })

          navigate('/login')
        })
        .catch(error => {
          if (error.response.data.data.error === 'alreadyVerified') {
            Swal.fire({
              title: 'Erro ao definir senha!',
              text: 'Esta conta foi ativada e já possui uma senha configurada!',
              icon: 'error',
              showCancelButton: false,
              confirmButtonText: 'Fechar',
            }).then(() => {
              navigate('/login')
            })

            return
          }
        })
    },
  })

  return {
    formParams,
    email,
  }
}
