import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { twMerge } from 'tailwind-merge'

export const Toggle = ({ isToggled, onChange }) => {
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => setIsChecked(isToggled), [])

  const handleToggle = e => {
    setIsChecked(!isChecked)
    e.isToggled = isChecked
    onChange(e)
  }

  const buttonClasses = twMerge(
    'bg-gray-200 flex items-center justify-start w-12 h-4 rounded-full px-1',
    isChecked && 'justify-end'
  )

  const innerCircleClasses = twMerge('w-3 h-3 rounded-full', isChecked && 'bg-primary', !isChecked && 'bg-white')

  return (
    <button type='button' className={buttonClasses} onClick={handleToggle}>
      <span className={innerCircleClasses} />
    </button>
  )
}

Toggle.propTypes = {
  isToggled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

Toggle.defaultProps = {
  isToggled: false,
  onChange: () => {},
}
