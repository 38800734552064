import * as Yup from 'yup'
import { useFormik } from 'formik'
import { UNDER_ANALYSIS } from '../constants'

export const useFilterPromoForm = ({ onSubmit }) => {
  const schema = Yup.object({
    status: Yup.string(),
    title: Yup.string(),
    initialDate: Yup.string(),
    partnerName: Yup.string(),
    endDate: Yup.string().when('initialDate', {
      is: value => (!value ? false : value.length > 1),
      then: () => Yup.string().required('Campo obrigatório!'),
      otherwise: Yup.string().notRequired(),
    }),
  })

  return useFormik({
    initialValues: {
      initialDate: '',
      endDate: '',
      status: UNDER_ANALYSIS,
      title: '',
      partnerName: '',
    },
    onSubmit,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
