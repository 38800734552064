import { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useCreateCustomer, useSignUpForm } from '../hooks'
import { jwtDecode } from 'jwt-decode'
import Swal from 'sweetalert2'
import httpClient from '@/utils/httpClient'
import { useApi, useAxios } from '@/hooks'
import { toast } from 'react-toastify'

const SignUpContext = createContext({
  uf: [],
  city: [],
  selectValues: [],
  multiSelectOptions: [],
  loading: false,
  loadingZipCode: false,
  loadingCity: false,
  values: {},
  errors: {},
  myRequestZipCode: () => {},
  handleSubmit: () => {},
  handleChange: () => {},
  setFieldValue: () => {},
  selectOptions: () => {},
})

export const SignUpProvider = ({ children }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [resultParams] = useSearchParams()

  const [uf, setUf] = useState()
  const [city, setCity] = useState()
  const [selectedUf, setSelectedUf] = useState('')
  const [selectValues, setIsSelectValues] = useState([])
  const [multiSelectOptions, setIsMultiSelectOptions] = useState([])

  const { makeRequest: getMultiOptions, data: dataMultiOptions } = useAxios(() =>
    httpClient.get(`/partners/segments/options?label=name`)
  )

  const {
    makeRequest: myRequestZipCode,
    data: dataZipCode,
    loading: loadingZipCode,
  } = useApi(zipCode => httpClient.get(`zipcode/${zipCode}`))

  const { makeRequest: makeRequestUf, data: dataUf } = useAxios(() =>
    httpClient.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/')
  )

  const {
    makeRequest: makeRequestCity,
    data: dataCity,
    loading: loadingCity,
  } = useAxios(selectedUf =>
    httpClient.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios`)
  )

  const { data, error, loading, createCustumer } = useCreateCustomer()

  const handleOptionsUf = (options = []) => {
    const arr = []
    options?.map(item => {
      arr.push({ label: item.nome, value: item.sigla })
    })
    return arr
  }

  const handleOptionsCity = (options = []) => {
    const arr = []
    options?.map(item => {
      arr.push({ label: item.nome, value: item.nome })
    })
    return arr
  }

  const { handleSubmit, handleChange, setFieldValue, values, errors, setFieldError } = useSignUpForm({
    onSubmit: async values => {
      const segmentValues = values.segments.map(option => option.value)
      await createCustumer({
        ...values,
        complement: values.complement.length > 0 ? values.complement : null,
        segments: segmentValues.length > 0 ? segmentValues : null,
      })
    },
  })

  const selectOptions = () => [
    {
      label: 'Pessoa Física',
      value: 'cpf',
    },
    {
      label: 'Pessoa Jurídica',
      value: 'cnpj',
    },
  ]

  useEffect(() => {
    const token = resultParams.get('token')
    if (!token) {
      navigate('/donate/fkx1234567')
      return
    }
    const decoded = jwtDecode(token)

    if (!decoded) {
      navigate('/donate/fkx1234567')
      return
    }

    setFieldValue('email', decoded.sub)
  }, [])

  useEffect(() => {
    if (!dataUf) return
    setUf(handleOptionsUf(dataUf))
  }, [dataUf])

  useEffect(() => {
    if (!dataZipCode) return
    setFieldValue('city', dataZipCode.localidade)
    setFieldValue('state', dataZipCode.uf)
    setFieldValue('street', dataZipCode.logradouro)
    setFieldValue('neighborhood', dataZipCode.bairro)
  }, [dataZipCode])

  useEffect(() => {
    if (!values.typeCustomer) return
    setFieldValue('cpfCnpj', '')
  }, [values.typeCustomer])

  useEffect(() => {
    if (!values.segments) return
    setIsSelectValues(values.segments)
  }, [values.segments])

  useEffect(() => {
    if (values.state === '') return
    setSelectedUf(values.state)
  }, [values.state])

  useEffect(() => {
    if (!selectedUf) return
    makeRequestCity(selectedUf)
  }, [selectedUf])

  useEffect(() => {
    if (!dataCity) return
    let newCity = handleOptionsCity(dataCity)
    setCity(newCity)
  }, [dataCity])

  useEffect(() => {
    makeRequestUf()
    getMultiOptions()
  }, [])

  useEffect(() => {
    if (!dataMultiOptions) return
    setIsMultiSelectOptions(dataMultiOptions.data)
  }, [dataMultiOptions])

  useEffect(() => {
    if (!data) return
    Swal.fire({
      title: `${t('signUp.successMessageTitle')}`,
      text: `${t('signUp.successMessageText')}`,
      icon: 'success',
      confirmButtonText: 'Ok',
    }).then(() => navigate('/login'))
  }, [data])

  useEffect(() => {
    if (!error) return
    if (error.error === 'cpfCnpjAlreadyInUse') {
      toast.error('O CPF/CNPJ já encontra-se cadastrado', {
        position: 'top-center',
        autoClose: 2000,
      })
      setFieldError('cpfCnpj', 'Este Cpf/Cnpj já está em uso.')
      return
    }
    if (error.error === 'emailAlreadyInUse') {
      toast.error('O E-mail já encontra-se cadastrado', {
        position: 'top-center',
        autoClose: 2000,
      })
      setFieldError('email', 'Este e-mail já está em uso.')
      return
    }
    if (error[0]?.message === `The 'email' must be a valid email`) setFieldError('email', 'Este não é um email válido.')
  }, [error])

  return (
    <SignUpContext.Provider
      value={{
        uf,
        city,
        selectValues,
        multiSelectOptions,
        loading,
        loadingZipCode,
        loadingCity,
        values,
        errors,
        myRequestZipCode,
        handleSubmit,
        handleChange,
        setFieldValue,
        selectOptions,
      }}
    >
      {children}
    </SignUpContext.Provider>
  )
}

export const useSignUp = () => ({ ...useContext(SignUpContext) })
