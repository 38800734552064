import { FieldMessage } from './FieldMessage'
import { Label } from '../Label'
import PropTypes from 'prop-types'
import { InputPassword } from '../InputPassword'
import { twMerge } from 'tailwind-merge'

export const PasswordField = ({
  label,
  name,
  id,
  onChange,
  value,
  noMargins,
  isDisabled,
  errorMessage,
  className,
  inputClassName,
  ...rest
}) => {
  const labelClasses = errorMessage !== '' ? 'text-negative' : ''

  return (
    <div className={twMerge(!noMargins && 'mb-3', className)}>
      <Label
        className={twMerge('text-base font-medium', labelClasses)}
        text={label}
        htmlFor={id ?? name}
        isInvalid={errorMessage !== ''}
      />
      <InputPassword
        name={name}
        id={id ?? name}
        isInvalid={errorMessage !== ''}
        isDisabled={isDisabled}
        onChange={onChange}
        value={value}
        className={inputClassName}
        {...rest}
      />
      {errorMessage && <FieldMessage message={errorMessage} />}
    </div>
  )
}

PasswordField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  noMargins: PropTypes.bool,
}

PasswordField.defaultProps = {
  isDisabled: false,
  errorMessage: '',
  noMargins: false,
}
