import { React, useEffect, useState } from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { useAuth } from '@/contexts'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import { SignInForm } from './components/SignInForm'
import { ModalForgotPassword } from './components/ModalForgotPassword'
import { useForgotPasswordForm, useForgotPassword, useFormSignIn } from './hooks'
import { useLoginCustomer } from './hooks/useLoginCustomer'

export const SignIn = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { token, setToken } = useAuth()
  const { data, error, loading, login } = useLoginCustomer()
  const { loading: loadingForgotPassword, forgotPassword } = useForgotPassword()

  const [searchParams] = useSearchParams()

  const {
    handleSubmit: forgotPasswordHandleSubmit,
    handleChange: forgotPasswordHandleChange,
    setFieldValue: forgotPasswordSetFieldValues,
    values: forgotPasswordValues,
    errors: forgotPasswordErrors,
  } = useForgotPasswordForm({
    onSubmit: async values => {
      try {
        await forgotPassword(values)

        Swal.fire({
          title: 'E-mail enviado com sucesso',
          text: 'Enviamos um email para você alterar sua senha!',
          icon: 'success',
          confirmButtonText: 'Ok',
        })
      } catch (e) {
        Swal.fire({
          title: 'Ocorreu um erro',
          text: 'Não foi possível enviar um email alteração de senha, tente novamente!',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    },
  })

  const {
    handleSubmit: singInHandleSubmit,
    handleChange: singInHandleChange,
    setFieldValue: singInSetFieldValues,
    values: singInValues,
    errors: singInErrors,
  } = useFormSignIn({
    onSubmit: values => {
      login({ email: values.email, password: values.password })
    },
  })

  useEffect(() => {
    if (token) navigate('/dashboard')
  }, [token])

  useEffect(() => {
    if (!data) return
    setToken(data.token)
    const from = location.state?.from?.pathname || '/dashboard'
    navigate(from, { replace: true })
  }, [data])

  useEffect(() => {
    if (!error) return
    Swal.fire({
      title: t('signIn.emailOrPasswordInvalid'),
      icon: 'error',
      confirmButtonText: 'Ok',
    })
  }, [error])

  useEffect(() => {
    const email = searchParams.get('email')
    if (email) {
      singInSetFieldValues('email', email)
    }
  }, [])

  const handleModalIsOpen = () => {
    setModalIsOpen(!modalIsOpen)
  }

  return (
    <div className='w-full flex justify-center items-center min-h-[80vh] px-6'>
      <SignInForm
        loading={loading}
        handleSubmit={singInHandleSubmit}
        handleChange={singInHandleChange}
        setFieldValue={singInSetFieldValues}
        values={singInValues}
        errors={singInErrors}
        handleModalIsOpen={handleModalIsOpen}
      />
      <ModalForgotPassword
        modalIsOpen={modalIsOpen}
        handleModalIsOpen={handleModalIsOpen}
        handleSubmit={forgotPasswordHandleSubmit}
        handleChange={forgotPasswordHandleChange}
        setFieldValue={forgotPasswordSetFieldValues}
        values={forgotPasswordValues}
        errors={forgotPasswordErrors}
        loading={loadingForgotPassword}
      />
    </div>
  )
}
