import { DateAsText } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
// import Swal from 'sweetalert2'
import { useGetPartnerDetails } from './useGetPartnerDetails'
import { Button } from '@/components/Form'
import { twMerge } from 'tailwind-merge'
// import { usePutBlockPartner } from './usePutBlockPartner'
import { Icon } from '@iconify/react'

export const PartnerDetails = ({ isOpen, onClose, partnerId }) => {
  const { data, makeRequest, loading } = useGetPartnerDetails()
  // const { makeRequest: requestBlock } = usePutBlockPartner()
  const gridCellStyles = twMerge('py-2 flex flex-col border-b first:border-t last:border-b-0 border-primary-300/50 gap-1')
  const formatCpf = (value = '') => {
    if (value.length === 11) {
      value = value.replace(/\D/g, '')
      value = value.replace(/(\d{3})(\d)/, '$1.$2')
      value = value.replace(/(\d{3})(\d)/, '$1.$2')
      value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
      return value
    }
    value = value.replace(/\D/g, '')
    value = value.replace(/^(\d{2})(\d)/, '$1.$2')
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    value = value.replace(/\.(\d{3})(\d)/, '.$1/$2')
    value = value.replace(/(\d{4})(\d)/, '$1-$2')
    return value
  }

  // const handlePartnerBlocked = () => {
  //   onClose()
  //   Swal.fire({
  //     title: 'Você tem certeza?',
  //     text: 'Deseja realmente bloquear este usuário?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#d33',
  //     cancelButtonColor: '#3085d6',
  //     reverseButtons: true,
  //     confirmButtonText: 'Bloquear!',
  //     cancelButtonText: 'Cancelar',
  //     showLoaderOnConfirm: true,
  //     preConfirm: async () => {
  //       await requestBlock(partnerId)
  //         .then(() => {
  //           toast.success('Parceiro bloqueado com sucesso!', {
  //             position: 'top-center',
  //             autoClose: 2000,
  //           })
  //         })
  //         .catch(() => {
  //           toast.error('Erro ao bloquear o parceiro!', {
  //             position: 'top-center',
  //             autoClose: 2000,
  //           })
  //         })
  //         .finally(() => {
  //           return
  //         })
  //     },
  //   })
  // }

  useEffect(() => {
    if (!partnerId) return

    makeRequest(partnerId)
  }, [partnerId])

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detalhes do Parceiro</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            {loading && <Icon className='mx-auto size-12 text-primary-400 animate-spin' icon='mingcute:loading-fill' />}

            {data && !loading && (
              <div>
                <div>
                  <div className={gridCellStyles}>
                    <span className='font-semibold'>Nome:</span> <span>{data.name}</span>
                  </div>
                  <div className={gridCellStyles}>
                    <span className='font-semibold'>E-mail:</span> <span>{data.email}</span>
                  </div>
                  <div className={gridCellStyles}>
                    <span className='font-semibold'>CPF/CNPJ:</span> <span>{formatCpf(data.cpfCnpj)}</span>
                  </div>
                  <div className={gridCellStyles}>
                    <span className='font-semibold'>Data do Cadastro:</span>
                    <span>
                      <DateAsText date={data.createdAt} />
                    </span>
                  </div>
                  <div className={gridCellStyles}>
                    <span className='font-semibold'>Arrecadação:</span> <MoneyAsText value={data.balance.collected} />
                  </div>
                  <div className={gridCellStyles}>
                    <span className='font-semibold'>CashBack:</span>
                    <span>
                      {data.balance.pts} pts = <MoneyAsText prefix='R$ ' value={data.balance.pts / 100} />
                    </span>
                  </div>
                  <div className={gridCellStyles}>
                    <span className='font-semibold'>Qtd de Doações:</span> <span>{data.donationQtd}</span>
                  </div>
                  <div className={gridCellStyles}>
                    <span className='font-semibold'>Status do Parceiro:</span>
                    <span className={data.isBlocked ? 'text-red-600 font-semibold' : 'text-green-600 font-semibold'}>
                      {data.isBlocked ? 'Bloqueado' : 'Ativo'}
                    </span>
                  </div>
                  <div className={gridCellStyles}>
                    <span className='font-semibold'>Link de Doação:</span>{' '}
                    <a
                      href={`${import.meta.env.VITE_BASE_URL}/donate/${data.indicationCode}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='underline w-full truncate'
                    >
                      {`${import.meta.env.VITE_BASE_URL}/donate/${data.indicationCode}`}
                    </a>
                    <Button
                      className='rounded py-1.5 px-4 ml-auto mt-2'
                      onClick={() => {
                        toast.success('Copiado com sucesso!', {
                          position: 'top-center',
                          autoClose: 2000,
                          toastId: 'copied',
                        })
                        navigator.clipboard.writeText(`${import.meta.env.VITE_BASE_URL}/donate/${data.indicationCode}`)
                      }}
                    >
                      Copiar Link
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </ModalBody>

          <ModalFooter gap={4}>
            {/* {!data?.isBlocked && (
              <Button className='bg-negative-dark py-1.5 rounded' onClick={() => handlePartnerBlocked(data?.id)}>
                Bloquear
              </Button>
            )} */}
            <Button className='bg-gray-300 py-1.5 text-text rounded' onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
