import { CheckoutForm, DonationInfos, ErrorFetch, PartnerModal, PaymentForm } from './components'
import { useDonation } from '@/contexts/DonationContext'

export const Donation = () => {
  const { dataPartner, loadingPartner, checkout, modalPartner, isDonationAppwins } = useDonation()
  return (
    <>
      <div className='w-full flex flex-col lg:flex-row px-4 pb-8 gap-6'>
        {!dataPartner && !loadingPartner && <ErrorFetch />}
        <DonationInfos />
        <div className={`w-full flex flex-col gap-4 animate-fadeIn ${isDonationAppwins && !checkout.isCheckout && 'hidden'}`}>
          {dataPartner && (!isDonationAppwins || checkout.isCheckout) && <CheckoutForm />}
          {checkout.isCheckout && <PaymentForm />}
        </div>
      </div>
      <PartnerModal
        verifyAppwins={dataPartner && isDonationAppwins}
        isOpen={modalPartner.isOpen}
        onClose={modalPartner.onClose}
      />
    </>
  )
}
