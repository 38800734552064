import React, { useEffect, useState } from 'react'
import { Button } from '@/components/Form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { DesktopSection, Header, MobileSection } from './components'
import { toast } from 'react-toastify'
import { twMerge } from 'tailwind-merge'
import { Icon } from '@iconify/react'
import { useGetStatus } from './hooks/useGetStatus'
import { ErrorFetch } from '../Donation/components'
import { useFavoritePartner } from './hooks/useFavoritePartner'
import { useFetchPartner } from '../Donation/hook'

export const ConfirmationPage = () => {
  const [isDonationAppwins, setIsDonationAppwins] = useState(false)
  const { order_id, indication_code } = useParams()
  const navigate = useNavigate()
  const [isFavorite, setIsFavorite] = useState(false)
  const { postFavorite } = useFavoritePartner()
  const { dataPartner, fetchPartner } = useFetchPartner()
  const { dataStatus, loadingStatus, getStatus } = useGetStatus()
  useEffect(() => {
    if (!dataPartner) return
    setIsDonationAppwins(dataPartner.id === '2916e835-c3b6-4cf0-a211-fab36a494846')
  }, [dataPartner])

  const handleFavorite = async () => {
    try {
      const response = await postFavorite(dataPartner.id, dataStatus.donor.id)

      if (response && !isFavorite) {
        toast.success('Loja adicionada aos Favoritos!', {
          position: 'top-center',
          autoClose: 2000,
          toastId: 'favorite',
        })
        setIsFavorite(true)
      } else {
        toast.success('Loja removida dos Favoritos!', {
          position: 'top-center',
          autoClose: 2000,
          toastId: 'rmvFavorite',
        })
        setIsFavorite(false)
      }
    } catch (e) {
      toast.error('Erro ao realizar ação. Tente novamente mais tarde!', {
        position: 'top-center',
        autoClose: 2000,
      })
    }
  }

  const handleViewVoucher = () => {
    navigate(`/voucher/${dataStatus.voucher.code}`)
  }

  useEffect(() => {
    getStatus(order_id).then(res => {
      if (res.data.data.donor.favorites.find(item => item.indicationCode === indication_code)) {
        setIsFavorite(true)
      }
    })

    fetchPartner(indication_code)
  }, [])

  if (loadingStatus && !dataStatus && !dataPartner)
    return (
      <div className='w-[90%] mx-auto max-w-xs flex justify-center items-center shadow-xl bg-surface rounded p-3'>
        <Icon icon='mingcute:loading-fill' className='size-16 animate-spin text-primary' />
      </div>
    )

  if (!dataStatus) return <ErrorFetch />

  return (
    <main className='w-full flex flex-col px-4 gap-4 mb-4'>
      <Header />

      <MobileSection dataStatus={dataStatus} />
      <DesktopSection
        dataStatus={dataStatus}
        isDonationAppwins={isDonationAppwins}
        indication_code={indication_code}
        onClick={handleFavorite}
        isFavorite={isFavorite}
      />

      <div className='flex flex-col w-full gap-4 lg:hidden max-w-lg mx-auto'>
        <Button onClick={handleViewVoucher} text='Visualizar Voucher' />
        <Link to='/donate' className='hidden lg:flex'>
          <Button text='Voltar para a tela de Doação' className='bg-transparent border border-primary text-primary w-full' />
        </Link>
        {!isDonationAppwins && (
          <button
            className={twMerge('py-3 px-5 text-white rounded-lg flex gap-2 items-center justify-center bg-red-500')}
            onClick={handleFavorite}
          >
            {isFavorite ? 'Remover dos favoritos' : 'Favoritar Loja'}
            <Icon icon={isFavorite ? 'mdi:heart' : 'mdi:heart-outline'} color='white' fontSize={24} />
          </button>
        )}
      </div>
    </main>
  )
}
