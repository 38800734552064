import { FieldMessage } from './FieldMessage'
import { Label } from '../Label'
import PropTypes from 'prop-types'
import { InputCpfCnpj } from '..'
import { twMerge } from 'tailwind-merge'
import { Tooltip } from '@chakra-ui/react'
import { Icon } from '@iconify/react'

export const CpfCnpjField = ({
  label,
  name,
  id,
  onChange,
  value,
  noMargins,
  isDisabled,
  errorMessage,
  className,
  format,
  mask,
  inputClassName,
  info,
  ...rest
}) => {
  const labelClasses = errorMessage !== '' ? 'text-negative' : ''

  return (
    <div className={twMerge(!noMargins && 'mb-3', className)}>
      {info ? (
        <Tooltip label={info}>
          <div className='w-fit flex items-center gap-2'>
            <Label
              className={twMerge('text-sm font-medium', labelClasses)}
              text={label}
              htmlFor={id ?? name}
              isInvalid={errorMessage !== ''}
            />

            <Icon icon='gg:info' className='text-base mb-1 max-md:hidden' />
          </div>
        </Tooltip>
      ) : (
        <Label
          className={twMerge('text-sm font-medium', labelClasses)}
          text={label}
          htmlFor={id ?? name}
          isInvalid={errorMessage !== ''}
        />
      )}
      <InputCpfCnpj
        format={format}
        mask={mask}
        name={name}
        id={id ?? name}
        isInvalid={errorMessage !== ''}
        isDisabled={isDisabled}
        onChange={onChange}
        value={value}
        className={inputClassName}
        {...rest}
      />
      {errorMessage && <FieldMessage message={errorMessage} />}

      {info && (
        <span className='text-zinc-600 text-sm flex gap-1 mt-1 items-start md:hidden'>
          <Icon icon='gg:info' className='text-base mt-1' /> {info}
        </span>
      )}
    </div>
  )
}

CpfCnpjField.propTypes = {
  label: PropTypes.string.isRequired,
  format: PropTypes.oneOf(['cpf', 'cnpj']),
  mask: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  noMargins: PropTypes.bool,
}

CpfCnpjField.defaultProps = {
  isDisabled: false,
  errorMessage: '',
  noMargins: false,
}
