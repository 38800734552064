// type of line = 'down' | 'left' | 'split'

export const FlowLine = ({ type = 'down' }) => {
  return (
    <div className='w-full flex flex-col items-center animate-fadeIn'>
      <Dot />

      {type === 'down' && <img className='h-32 mb-1' src='/images/arrowFlowDown.svg' />}

      {type === 'left' && (
        <div className='w-[55%] grid grid-cols-2 translate-x-1 max-lg:hidden'>
          <img className='w-full' src='/images/arrowFlowLeft.svg' />

          <div />
        </div>
      )}

      {type === 'split' && <img className='w-[55%] translate-x-1 max-lg:hidden' src='/images/arrowFlowDouble.svg' />}

      {type !== 'down' && <img className='h-32 mb-1 lg:hidden' src='/images/arrowFlowDown.svg' />}
    </div>
  )
}

const Dot = () => (
  <div className='size-8 rounded-full bg-primary-300/50 ring ring-primary flex justify-center items-center -mt-4'>
    <span className='size-2 bg-white border-2 border-primary rounded-full' />
  </div>
)
