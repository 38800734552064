import { Badge } from '@/components/Common'
import { getPromoBadgeColor, getStatusPromo } from '../constants'

export const PromoBadge = ({ status, className }) => (
  <Badge
    variant='solid'
    rounded='10px'
    className={`px-2 py-1 bg-${getPromoBadgeColor(status)} ${className}`}
    text={getStatusPromo(status)}
  />
)
