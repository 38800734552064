import { useAuth } from '@/contexts'
import { Icon } from '@iconify/react'
import { useLocation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

export const UserProfile = ({ className }) => {
  const { user } = useAuth()
  const { pathname } = useLocation()

  const getIcon = key => {
    switch (key) {
      case 'DONOR':
        return 'fluent:person-24-filled'

      case 'ADMIN':
        return 'fluent:shield-person-20-filled'

      case 'PARTNER':
        return 'fluent:person-star-20-filled'

      default:
        return 'fluent:person-24-filled'
    }
  }

  const getGroupName = key => {
    switch (key) {
      case 'DONOR':
        return 'Doador'

      case 'ADMIN':
        return 'Administrador'

      case 'PARTNER':
        return 'Parceiro'

      default:
        return 'Doador'
    }
  }

  if (!user) return null

  return (
    <div
      className={twMerge(
        'max-w-3xl mx-auto w-full flex items-center gap-2 font-Montserrat p-1 px-4 pl-3',
        pathname.includes('donate') && 'max-w-lg pl-0 lg:container lg:pl-4',
        className
      )}
    >
      <Icon icon={getIcon(user.group.key)} className='size-10 text-primary-600' />

      <div className='flex flex-col'>
        <p className='text-lg font-medium'>{user.name}</p>
        <p className='text-zinc-600 -mt-1'>{getGroupName(user.group.key)}</p>
      </div>
    </div>
  )
}
