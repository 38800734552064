import { Button, Label, RadioGroup } from '@/components/Form'
import { CpfCnpjField, DateField, TextField } from '@/components/Form/Fields'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react'
import { inputStyles } from '../../constants/styles'
import { twMerge } from 'tailwind-merge'
import { DateAsText } from '@/components/Common'

export const Filters = ({ filterParams, activeFilters, handleClearFilter }) => {
  const { values, errors, handleChange, handleSubmit, setFieldValue } = filterParams

  const clearCpf = (value = '') => {
    value = value.replace(/\D/g, '')
    value = value.slice(0, 11)

    return value
  }

  const renderTag = (label, value) => {
    switch (label) {
      case 'term':
        return `Paceiro: ${value}`
      case 'cpfCnpj':
        return value.length > 14 ? `CNPJ: ${value}` : `CPF: ${value}`
      case 'initialDate':
        return (
          <>
            Data inicial: <DateAsText date={value} />
          </>
        )
      case 'endDate':
        return (
          <>
            Data final: <DateAsText date={value} />
          </>
        )
    }
  }

  return (
    <Accordion allowToggle className='bg-surface shadow-lg rounded' padding={1} pt={2}>
      <AccordionItem border={0}>
        <AccordionButton>
          <div className='w-full'>
            <div className='w-full flex justify-between items-center'>
              <h3 className='text-lg font-medium'>Filtros de busca</h3>

              <AccordionIcon fontSize={28} />
            </div>
            {activeFilters.length > 0 && (
              <div className='flex gap-2 justify-start items-center flex-wrap py-2'>
                {activeFilters.map((filter, ind) => (
                  <span className='bg-tertiary-600 text-zinc-50 p-1 px-3 rounded text-sm' key={ind}>
                    {renderTag(filter.label, filter.value)}
                  </span>
                ))}
              </div>
            )}
          </div>
        </AccordionButton>

        <AccordionPanel>
          <form onSubmit={handleSubmit} className='grid lg:grid-cols-2 xl:grid-cols-4 items-start gap-3'>
            <div className='w-full lg:col-span-2 xl:col-span-4'>
              <Label className='text-sm font-medium'>Tipo de documento</Label>

              <RadioGroup
                value={values.isCpf ? '1' : '0'}
                onChange={e => {
                  setFieldValue('cpfCnpj', clearCpf(values.cpfCnpj))
                  setFieldValue('isCpf', e.target.value === '1')
                }}
                name='isCpf'
                options={[
                  { label: 'CPF', value: '1' },
                  { label: 'CNPJ', value: '0' },
                ]}
              />
            </div>

            <CpfCnpjField
              label={values.isCpf ? 'CPF' : 'CNPJ'}
              name='cpfCnpj'
              format={values.isCpf && 'cpf'}
              value={values.cpfCnpj}
              onChange={handleChange}
              errorMessage={errors.cpfCnpj}
              inputClassName={inputStyles}
              placeholder={values.isCpf ? '000.000.000-00' : '00.000.000/0000-00'}
            />

            <TextField
              label='Nome do parceiro'
              name='term'
              value={values.term}
              onChange={handleChange}
              errorMessage={errors.term}
              placeholder='Ex.: joao da silva'
              inputClassName={inputStyles}
            />

            <DateField
              label='Data inicial'
              name='initialDate'
              value={values.initialDate}
              onChange={handleChange}
              errorMessage={errors.initialDate}
              inputClassName={inputStyles}
            />

            <DateField
              label='Data final'
              name='endDate'
              value={values.endDate}
              onChange={handleChange}
              errorMessage={errors.endDate}
              inputClassName={twMerge(inputStyles, values.initialDate.length === 0 && 'text-opacity-50')}
              isDisabled={values.initialDate.length === 0}
              min={values.initialDate}
            />

            <div className='xl:col-span-2' />

            <Button
              onClick={handleClearFilter}
              type='button'
              className='w-full lg:col-span-2  xl:col-span-1 p-2 rounded min-w-[160px]'
            >
              Limpar
            </Button>
            <Button type='submit' className='w-full lg:col-span-2 xl:col-span-1 p-2 rounded min-w-[160px]'>
              Pesquisar
            </Button>
          </form>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
