import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export function useCreateCustomer() {
  const { data, error, loading, makeRequest } = useApi(customerData => httpClient.post('/partners/sign-up', customerData))

  return {
    data,
    error,
    loading,
    createCustumer: makeRequest,
  }
}
