import { CepField, NumberField, SelectField, TextField } from '@/components/Form/Fields'
import React from 'react'

export const AddressStep = ({ values, errors, setFieldValue, handleChange, ufOptions, cityOptions }) => {
  return (
    <>
      <CepField
        label='CEP'
        name='zipCode'
        placeholder='Ex.: 36088-450'
        value={values.zipCode}
        errorMessage={errors.zipCode}
        onValueChange={({ value }) => {
          setFieldValue('zipCode', value)
        }}
      />
      <TextField
        label='Endereço'
        name='street'
        placeholder='Ex.: Rua da Fé'
        value={values.street}
        errorMessage={errors.street}
        onChange={handleChange}
      />
      <div className='grid grid-cols-2 gap-4'>
        <NumberField
          label='Número'
          name='number'
          placeholder='Ex.: 729'
          value={values.number}
          errorMessage={errors.number}
          onChange={handleChange}
        />
        <TextField
          label='Complemento'
          name='complement'
          placeholder='Ex.: apto 23 bloco 3'
          value={values.complement}
          errorMessage={errors.complement}
          onChange={handleChange}
        />
      </div>
      <TextField
        label='Bairro'
        name='neighborhood'
        placeholder='Ex.: Santa Cruz'
        value={values.neighborhood}
        errorMessage={errors.neighborhood}
        onChange={handleChange}
      />
      <div className='grid grid-cols-2 gap-4'>
        <SelectField
          label='Estado'
          name='state'
          placeholder='Minas Gerais'
          value={values.state}
          options={ufOptions}
          errorMessage={errors.state}
          onChange={handleChange}
        />
        <SelectField
          label='Cidade'
          name='city'
          placeholder='Juiz de Fora'
          value={values.city}
          options={cityOptions}
          errorMessage={errors.city}
          onChange={handleChange}
        />
      </div>
    </>
  )
}
