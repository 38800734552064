import { Button } from '@/components/Form'
import { Tooltip } from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { toast } from 'react-toastify'
import { twMerge } from 'tailwind-merge'

export const Card = ({
  isMain = false,
  leg,
  isIndicate = false,
  onExpand,
  level = 1,
  name,
  handleClickDonation,
  indicationCode,
  isBackToTop,
  title,
}) => {
  const copyIndicationCode = () => {
    navigator.clipboard.writeText(indicationCode).then(() => {
      toast.success('Código copiado com sucesso!', { toastId: 'copy' })
    })
  }
  return (
    <div
      className={twMerge(
        'w-full lg:w-[380px] flex flex-col gap-3 bg-surface border border-primary rounded-lg p-3 py-4 animate-fadeIn',
        isMain && 'bg-gradient-to-bl bg-secondary-400/25 border-0 ring ring-tertiary'
      )}
    >
      <div className='w-full flex justify-between items-center'>
        <p className='text-xl font-medium'>
          {title}

          {leg && <span className='ml-2 text-base text-zinc-600 lg:hidden'>{leg === 'L' ? '(Esquerdo)' : '(Direito)'}</span>}
        </p>

        <LevelTag value={level} />
      </div>

      {name && (
        <CardCol label='Nome' border>
          <Tooltip label={name}>
            <p className='truncate max-sm:text-wrap'>{name}</p>
          </Tooltip>
        </CardCol>
      )}

      {indicationCode && (
        <CardCol border={onExpand || handleClickDonation} label='Código de indicação'>
          <div className='flex gap-1 items-center'>
            <p>{indicationCode}</p>

            <button className='text-xl' onClick={copyIndicationCode}>
              <Icon className='text-primary-600' icon='ion:copy' />
            </button>
          </div>
        </CardCol>
      )}

      {handleClickDonation && (
        <CardCol border={onExpand} label='Detalhes da doação'>
          <Button onClick={handleClickDonation} className='p-1.5 bg-zinc-400/50 text-zinc-950 rounded-md'>
            <Icon className='text-2xl' icon='ci:external-link' />
          </Button>
        </CardCol>
      )}

      {isBackToTop && (
        <CardCol label='Topo da rede' className='pb-0'>
          <Button onClick={onExpand} className='p-1.5 bg-zinc-400/50 text-zinc-950 rounded-md'>
            <Icon className='text-2xl' icon='icon-park-outline:to-top' />
          </Button>
        </CardCol>
      )}

      {leg && onExpand && (
        <CardCol label='Expandir rede' className='pb-0'>
          <Button onClick={onExpand} className='p-1.5 bg-zinc-400/50 text-zinc-950 rounded-md'>
            <Icon className='text-2xl' icon='fluent:arrow-split-24-filled' />
          </Button>
        </CardCol>
      )}

      {isIndicate && (
        <CardCol label='Subir rede' className='pb-0'>
          <Button onClick={onExpand} className='p-1.5 bg-zinc-400/50 text-zinc-950 rounded-md'>
            <Icon className='text-2xl' icon='lets-icons:up' />
          </Button>
        </CardCol>
      )}
    </div>
  )
}

const CardCol = ({ label = '', border = false, children, className }) => (
  <div
    className={twMerge(
      'w-full flex justify-between items-center gap-3 max-sm:flex-wrap text-zinc-950 py-2',
      border && 'border-b border-zinc-400',
      className
    )}
  >
    <p className='font-medium'>{label}</p>
    {children}
  </div>
)

const LevelTag = ({ value }) => <span className='bg-primary text-zinc-200 p-2 py-1 text-sm font-medium rounded'>Nv {value}</span>
