import { Button } from '@/components/Form'
import { TextField } from '@/components/Form/Fields'
import { useFormVerifySignIn } from './hooks/useFormSignIn'

export const VerifySign = () => {
  const { formParams } = useFormVerifySignIn()

  return (
    <form
      onSubmit={formParams.handleSubmit}
      className='w-[94%] m-auto max-w-md bg-surface rounded-lg shadow-lg p-5 py-8 flex flex-col items-center gap-8 font-Inter'
    >
      <img className='w-[50%] max-w-[140px]' src='/images/logo-icon.png' alt='wins brand' />

      <div className='flex flex-col gap-2 items-start'>
        <h2 className='font-semibold text-tertiary-600 text-xl'>Verificação por e-mail</h2>

        <p className='text-base max-sm:text-sm'>
          Informe um e-mail utilizado em uma de suas doações para prosseguir com a verificação do seu cadastro.
        </p>
      </div>

      <TextField
        className='w-full'
        inputClassName='border-primary-600 rounded py-1'
        label='E-mail'
        name='email'
        placeholder='Ex.: joao@email.com'
        value={formParams.values.email}
        onChange={e => formParams.setFieldValue('email', e.target.value.trim())}
        errorMessage={formParams.errors.email}
      />

      <Button
        type='submit'
        text='Enviar'
        className='w-full py-2 rounded'
        isLoading={formParams.isSubmitting}
        isDisabled={formParams.isSubmitting}
      />
    </form>
  )
}
