import { Hero } from '@/components/Home/Hero'
import { Companies } from '@/components/Home/Companies'
import { Footer } from '@/components/Home/Footer'
import { Video } from '@/components/Home/Video'
import { About } from '@/components/Home/About'

export const Home = () => {
  return (
    <main>
      <Hero />
      <Companies />
      <About />
      <Video />
      <Footer />
    </main>
  )
}
