import React from 'react'
import { ContainerSection, ExtractCard } from './components'
import { useDashboard } from './hooks/useDashboard'
import { DonationHistory, PartnerList } from './containers'
import { useAuth } from '@/contexts'
import { UserProfile } from '@/components/Common/UserProfile'
import { twMerge } from 'tailwind-merge'
import { PartnerLink } from './components/PartnerLink'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'

export const Dashboard = () => {
  const { isLoadingData, dataDashboard, dataPeriodBalance } = useDashboard()
  const { userGroup } = useAuth()
  const { isAdmin, isDonor } = userGroup

  return (
    <>
      <UserProfile className={twMerge(isAdmin && 'max-w-none')} />

      <main className={twMerge('w-full max-w-3xl flex flex-col mx-auto px-4 pb-8 gap-4', isAdmin && 'max-w-none')}>
        <h1 className='text-2xl font-bold text-primary'>Dashboard</h1>

        {userGroup.isPartner && <PartnerLink />}

        <ContainerSection>
          <div className={twMerge('grid md:grid-cols-2 gap-2 w-full items-stretch', isAdmin && '')}>
            <div className={twMerge('', userGroup.isAdmin && 'pr-1')}>
              <ExtractCard title='Saldo' value={dataDashboard.balance?.monetary || 0} isLoading={isLoadingData} />
            </div>

            {userGroup.isAdmin && (
              <div className={twMerge('pr-1')}>
                <ExtractCard
                  title='Cofre da rede'
                  value={dataDashboard.networkVault?.networkVault || 0}
                  isLoading={isLoadingData}
                />
              </div>
            )}

            <ExtractCard
              title={isDonor ? 'Cashback' : 'Lucros'}
              details={
                (userGroup.isAdmin || userGroup.isPartner) && {
                  mouth: userGroup.isPartner && format(new Date(), 'LLLL', { locale: ptBR }),
                  period: userGroup.isAdmin && {
                    total: null,
                    day: dataPeriodBalance.adminBalance.day,
                    week: dataPeriodBalance.adminBalance.week,
                    mouth: dataPeriodBalance.adminBalance.month,
                  },
                }
              }
              value={isDonor ? dataDashboard.balance?.pts : dataPeriodBalance.partnerCashback}
              hideBalance={userGroup.isAdmin}
              pointsConversion={userGroup.isDonor && 0.01}
              isLoading={isLoadingData}
            />

            {userGroup.isAdmin && (
              <ExtractCard
                title='Doações'
                details={{
                  period: {
                    total: dataPeriodBalance.adminCashback.total,
                    day: dataPeriodBalance.adminCashback.day,
                    week: dataPeriodBalance.adminCashback.week,
                    mouth: dataPeriodBalance.adminCashback.month,
                  },
                }}
                hideBalance={userGroup.isAdmin}
                value={dataDashboard.balance?.pts || 0}
                isLoading={isLoadingData}
              />
            )}
          </div>
        </ContainerSection>

        {isDonor && <DonationHistory />}

        {isAdmin && <PartnerList />}
      </main>
    </>
  )
}
