import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFetchVoucher } from './useFetchVoucher'
import { toast } from 'react-toastify'
import { useFavoritePartner } from '@/pages/Payment/hooks/useFavoritePartner'
import { useGetStatus } from '@/pages/Payment/hooks/useGetStatus'

export const useVoucherPage = () => {
  const { voucher } = useParams()
  const { dataVoucher, fetchVoucher, loadingVoucher } = useFetchVoucher()
  const { getStatus } = useGetStatus()
  const { postFavorite } = useFavoritePartner()
  const [isFavorite, setIsFavorite] = useState(false)
  const navigate = useNavigate()

  const goToDonatePage = () => {
    navigate('/donate/FXK1234567')
  }

  const handleFavorite = async () => {
    try {
      const response = await postFavorite(dataVoucher.partner.id, dataVoucher.donor.id)

      if (response && !isFavorite) {
        toast.success('Loja adicionada aos Favoritos!', {
          position: 'top-center',
          autoClose: 2000,
        })
        setIsFavorite(true)
      } else {
        toast.success('Loja removida dos Favoritos!', {
          position: 'top-center',
          autoClose: 2000,
        })
        setIsFavorite(false)
      }
    } catch (e) {
      toast.error('Erro ao realizar ação. Tente novamente mais tarde!', {
        position: 'top-center',
        autoClose: 2000,
      })
    }
  }

  const fetchStatus = async () => {
    getStatus(dataVoucher.donation.id).then(res => {
      if (res.data.data.donor.favorites.find(item => item.indicationCode === 'FXK1234567')) {
        setIsFavorite(true)
      }
    })
  }

  useEffect(() => {
    if (!dataVoucher) return

    fetchStatus()
  }, [dataVoucher])

  useEffect(() => {
    fetchVoucher(voucher)
  }, [])

  return {
    isFavorite,
    loadingVoucher,
    dataVoucher,
    handleFavorite,
    goToDonatePage,
  }
}
