import React from 'react'
import PropTypes from 'prop-types'
import { twMerge } from 'tailwind-merge'

export const InputText = ({ name, value, onChange, type, className, isDisabled, isInvalid, placeholder, ...rest }) => {
  const inputClassName = twMerge(
    'w-full block bg-zinc-50 py-2 px-3 border rounded-lg outline-none transition ease-in-out duration-300 delay-100 focus:border-gray-300',
    !isInvalid && 'text-gray-700 border-gray-200',
    isDisabled && 'text-gray-600 opacity-80 cursor-not-allowed',
    className,
    isInvalid && 'text-negative border-negative'
  )

  return (
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      disabled={isDisabled}
      className={inputClassName}
      placeholder={placeholder}
      {...rest}
    />
  )
}

InputText.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  placeholder: PropTypes.string,
}

InputText.defaultProps = {
  type: 'text',
  placeholder: 'Digite Aqui',
  isDisabled: false,
  isInvalid: false,
}
