import MoneyAsText from '@/components/Common/MoneyAsText'
import { StatusAsText } from '@/components/Common/StatusAsText'
import { Button } from '@/components/Form'
import { useAuth } from '@/contexts'
import { ModalVoucher } from '@/pages/DonationList/components/ModalVoucher'
import { VoucherStatus } from '@/pages/DonationList/components/VoucherStatus'
import { useTableList } from '@/pages/DonationDetails/hooks/useTableLIst'
import { formatPhoneString } from '@/utils'
import { useDisclosure } from '@chakra-ui/react'
import { twMerge } from 'tailwind-merge'
import { DateAsText } from '@/components/Common'

export const TableData = ({ dataDonation, fetchDonation }) => {
  const { userGroup } = useAuth()
  const { handleApproveDonation, handleRefuseDonation, dataPartnerDetails } = useTableList({
    fetchDonations: fetchDonation,
    partnerId: dataDonation?.partner.id,
  })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const formatCpfCnpj = (value = '') => {
    if (value.length === 11) {
      value = value.replace(/\D/g, '')
      value = value.replace(/(\d{3})(\d)/, '$1.$2')
      value = value.replace(/(\d{3})(\d)/, '$1.$2')
      value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2')

      return value
    }

    value = value.replace(/\D/g, '')
    value = value.replace(/^(\d{2})(\d)/, '$1.$2')
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    value = value.replace(/\.(\d{3})(\d)/, '.$1/$2')
    value = value.replace(/(\d{4})(\d)/, '$1-$2')
    return value
  }

  return (
    <div className='flex flex-col gap-8'>
      <div className='flex flex-col gap-3'>
        <Title>Dados do Doador</Title>

        <div className='flex flex-col bg-surface rounded-md shadow-lg'>
          <GridItem label='Nome' value={dataDonation?.donor.name} />
          <GridItem label='Telefone' value={formatPhoneString(dataDonation?.donor.phone)} />
          <GridItem label='E-mail' value={dataDonation?.donor.email} />
        </div>
      </div>

      {userGroup.isAdmin && dataPartnerDetails && (
        <div className='flex flex-col gap-3'>
          <Title>Dados do Parceiro</Title>

          <div className='flex flex-col bg-surface rounded-md shadow-lg'>
            <GridItem label='Nome' value={dataDonation?.partner.name} />
            <GridItem label='E-mail' value={dataPartnerDetails.email} />
            <GridItem
              label={dataPartnerDetails.cpfCnpj.length === 11 ? 'CPF' : 'CNPJ'}
              value={formatCpfCnpj(dataPartnerDetails.cpfCnpj)}
            />
            <GridItem
              label='Link de doação'
              value={
                <a
                  href={`${import.meta.env.VITE_BASE_URL}/donate/${dataPartnerDetails.indicationCode}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='underline underline-offset-2 truncate text-end ml-auto self-end'
                >
                  {`${import.meta.env.VITE_BASE_URL}/donate/${dataPartnerDetails.indicationCode}`}
                </a>
              }
            />
          </div>
        </div>
      )}

      <div className='flex flex-col gap-3'>
        <Title>Dados da Doação</Title>

        <div className='flex flex-col bg-surface rounded-md shadow-lg'>
          <GridItem label='Código' value={dataDonation?.number} />
          <GridItem
            label='Cotas'
            value={
              <div>
                {dataDonation?.payment.quantity}x <MoneyAsText value={dataDonation?.payment.unitAmount} />
              </div>
            }
          />
          <GridItem label='Valor Total' value={<MoneyAsText value={dataDonation?.payment.finalAmount} />} />

          <GridItem label='Data da solicitação' value={<DateAsText date={dataDonation?.createdAt} type='datetime' />} />

          <GridItem label='Status' value={<StatusAsText status={dataDonation?.payment.status} />} />

          {dataDonation?.payment.paidAt && (
            <GridItem label='Data da Aprovação' value={<DateAsText date={dataDonation?.payment.paidAt} type='datetime' />} />
          )}

          {dataDonation?.payment.refusedAt && (
            <GridItem label='Data de Recusa' value={<DateAsText date={dataDonation?.payment.refusedAt} type='datetime' />} />
          )}

          <GridItem
            label='Forma de pagamento'
            value={dataDonation?.payment.activeMethod === 'STATIC_PIX' ? 'Pix Comprovante' : 'Pix Copia e Cola'}
          />
          {dataDonation?.payment.proofFile && (
            <GridItem
              label='Comprovante'
              col
              value={<img className='max-h-96 mx-auto' src={dataDonation?.payment.proofFile} alt='comprovante de pagamento' />}
            />
          )}
        </div>
      </div>

      {dataDonation?.voucher && (
        <div className='flex flex-col gap-3'>
          <Title>Dados do Voucher</Title>

          <div className='flex flex-col bg-surface rounded-md shadow-lg'>
            {dataDonation?.voucher && (
              <>
                <GridItem label='Código' value={<div className='flex gap-2 items-center'>{dataDonation?.voucher.code}</div>} />
                <GridItem label='Status' value={<VoucherStatus status={dataDonation.voucher.isAvailable} />} />
              </>
            )}
          </div>
        </div>
      )}

      <div className='flex flex-col'>
        {dataDonation?.voucher?.isAvailable && userGroup.isPartner && (
          <Button className='rounded' onClick={onOpen}>
            Visualizar Voucher
          </Button>
        )}

        {dataDonation?.payment.status === 1 && userGroup.isAdmin && (
          <div className='w-full grid md:grid-cols-2 gap-4'>
            <Button onClick={() => handleApproveDonation(dataDonation?.id)} className='rounded bg-positive-dark'>
              Aprovar Doação
            </Button>

            <Button onClick={() => handleRefuseDonation(dataDonation?.id)} className='rounded bg-negative-dark'>
              Recusar Doação
            </Button>
          </div>
        )}
      </div>

      <ModalVoucher data={dataDonation} isOpen={isOpen} onClose={onClose} fetchDonation={fetchDonation} />
    </div>
  )
}

const Title = ({ children }) => {
  return <h3 className='text-xl text-primary-600'>{children}</h3>
}

const GridItem = ({ label, value, col }) => {
  return (
    <div
      className={twMerge(
        'flex flex-wrap justify-between items-center gap-2 p-4 border-y border-primary-300/50 first:border-t-0 last:border-b-0',
        col && 'flex-col gap-4 items-stretch'
      )}
    >
      <p className='font-medium'>{label}</p>
      {value}
    </div>
  )
}
