import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export function useGetDonationsPeriod() {
  const { data, error, loading, makeRequest } = useApi(params => httpClient.get('/donations/sum-per-period', { params }))

  return {
    dataDonationsPeriod: data,
    errorDonationsPeriod: error,
    loadingDonationsPeriod: loading,
    fetchDonationsPeriod: makeRequest,
  }
}
