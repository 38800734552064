import { useEffect, useState } from 'react'
import { useGetVerifyPayment } from './useGetVerifyPayment'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSubmitDonation } from './useSubmitDonation'
import { paymentStatus } from '../constants'

export const useFormPayment = ({ payment, indication_code }) => {
  const paymentId = payment.id
  const isPixStatic = payment.method
  const { getVerifyPayment } = useGetVerifyPayment()
  const navigate = useNavigate()
  const [paymentProof, setFile] = useState({
    file: null,
    preview: null,
    error: null,
  })
  const { postDonation, loadingSubmitDonation } = useSubmitDonation()

  const handleChangeImg = file => {
    const acceptFiles = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf']

    if (!acceptFiles.includes(file.type)) {
      toast.warn('Arquivo com extensão inválida.')
      return
    }

    const previewUrl = file.type.includes('image') ? URL.createObjectURL(file) : null

    setFile({
      file,
      preview: previewUrl,
      error: null,
    })
  }

  const handleSubmitFile = e => {
    e.preventDefault()

    if (!paymentProof.file) {
      setFile(prev => ({ ...prev, error: 'Campo obrigatório!' }))

      return
    }

    const payloadData = Object.entries(payment.payload)

    const formData = new FormData()
    formData.append('paymentProofFile', paymentProof.file)
    payloadData.forEach(item => {
      formData.append(item[0], item[1])
    })

    Swal.fire({
      title: 'Confirmar Envio?',
      html: `
      <div>
        <p>Você está prestes a enviar sua doação para análise do pagamento.</p>
        <small class='text-red-500 font-medium' >Essa ação não poderá ser desfeita!</small>
      </div>
    `,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'Enviar',
      cancelButtonText: 'Cancelar',
      icon: 'question',
    }).then(result => {
      if (result.isConfirmed) {
        postDonation(formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(res => {
            toast.success('Pagamento enviado!')
            navigate(`/process/${res.data.data.id}/${indication_code}`)
          })
          .catch(error => {
            if (error.response?.data.data.error === 'fieldAlreadyInUse' && error.response?.data.data.field === 'phone') {
              toast.error('Número de telefone já está em uso!')
              return
            }

            if (error.response?.data.data.error === 'fieldAlreadyInUse' && error.response?.data.data.field === 'email') {
              toast.error('Email já está em uso!')
              return
            }

            if (error.response?.data.data.error === 'fieldAlreadyInUse' && error.response?.data.data.field === 'cpfCnpj') {
              toast.error('Documento já está em uso!')
              return
            }

            if (error.response?.data.data.error === 'invalidCpf') {
              toast.error('Cpf inválido!')
              return
            }

            toast.error('Ocorreu um erro ao gerar seu pedido!')
            return
          })
      }
    })
  }

  useEffect(() => {
    if (!paymentId) return

    if (isPixStatic) return

    getVerifyPayment(paymentId).then(res => {
      if (res.data.data.status === paymentStatus.REFUSED) {
        Swal.fire({
          title: 'Sua doação foi recusada!',
          text: 'Verifique as informações fornecidas e tente novamente.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'Fechar',
        }).then(() => {
          navigate(`/donate/${indication_code}`)
        })

        return
      }

      const timer = setInterval(() => {
        getVerifyPayment(paymentId)
          .then(res => {
            if (res.data.data.status === paymentStatus.APPROVED) {
              clearInterval(timer)
              navigate(`/confirmation-payment/${indication_code}/${paymentId}`)
            }

            if (res.data.data.status === paymentStatus.REFUSED) {
              clearInterval(timer)
              Swal.fire({
                title: 'Sua doação foi recusada!',
                text: 'Verifique as informações fornecidas e tente novamente.',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'Fechar',
              }).then(() => {
                navigate(`/donate/${indication_code}`)
              })

              return
            }
          })
          .catch(() => {
            clearInterval(timer)
          })
      }, 2000)
    })
  }, [paymentId])

  return {
    paymentProof,
    handleChangeImg,
    handleSubmit: handleSubmitFile,
    loadingSubmitPayment: loadingSubmitDonation,
    isPixStatic,
  }
}
