import { Icon } from '@iconify/react'
import { SkeletonText } from '@chakra-ui/react'
import { Copy } from '@/components/Common/Copy'

export const VoucherCard = ({ dataVoucher, favorite, isLoading, onClick }) => {
  const { isFavorite, handleFavorite } = favorite

  return (
    <div className='w-full max-w-[500px] self-center flex flex-col items-center gap-5'>
      <div className='w-full flex items-center gap-1'>
        <Icon icon='ci:ticket-voucher' fontSize={32} color='rgb(93 58 141)' />{' '}
        <p className='text-2xl font-bold text-primary'>Voucher do pedido</p>
      </div>

      <div className='w-full flex flex-col relative gap-2 bg-surface rounded-lg shadow-lg p-6 justify-center'>
        <div className='border-b border-b-primary pb-2 flex flex-col gap-1'>
          <div className='flex flex-col items-center'>
            <Copy
              value={dataVoucher?.voucher?.code}
              className='w-full overflow-hidden justify-center flex items-center gap-1 rounded cursor-pointer'
            >
              <Icon icon='basil:copy-solid' className='text-xl  shrink-0' />
              <span className='truncate text-lg'>{dataVoucher?.voucher?.code}</span>
            </Copy>
          </div>
        </div>
        <img className='max-w-[320px] w-full mx-auto' src={dataVoucher?.voucher?.qrCode} alt='qr-code' />
      </div>

      <div className='w-full flex flex-col relative gap-2 bg-surface rounded-lg shadow-lg p-6 justify-center'>
        <div className='flex flex-col gap-3'>
          <div className='flex flex-col gap-1'>
            <p className='font-bold mb-1'>Doador</p>
            <p className='border-b pb-1'>{dataVoucher?.donor?.name}</p>
            <p className='border-b pb-1'>{dataVoucher?.donor?.email}</p>
            <p className='border-b pb-1'>{dataVoucher?.donor?.phone}</p>
          </div>
          <div className='flex flex-col gap-1'>
            <p className='font-bold mb-1'>Pedido</p>
            <div className='flex items-center justify-between border-b pb-1'>
              <p>Loja parceira</p>
              <p>{dataVoucher?.partner?.name}</p>
            </div>
            <div className='flex items-center justify-between border-b pb-1'>
              <p>Quantidade</p>
              <p>{dataVoucher?.donation?.quantity}</p>
            </div>
            <div className='flex items-center justify-between pb-1'>
              <p>Valor doado</p>
              <div className='px-2.5 py-1 rounded-md text-white bg-yellow-700'>
                <p>{dataVoucher?.donation?.finalAmount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='w-full flex flex-col relative gap-2 bg-surface rounded-lg shadow-lg p-6 justify-center'>
        <div className='w-full flex gap-3 justify-between items-center'>
          <SkeletonText isLoaded={!isLoading} fadeDuration={0.8} noOfLines={1} skeletonHeight={3} minW={24}>
            <h5 className='text-xl font-semibold self-center'>{dataVoucher?.settings?.companyName}</h5>
          </SkeletonText>
        </div>
        <SkeletonText isLoaded={!isLoading} fadeDuration={0.8} noOfLines={3} skeletonHeight='4'>
          <div className='text-sm'>{dataVoucher?.settings?.companyDescription}</div>
        </SkeletonText>
        <div className='px-2.5 py-0.5 rounded-md text-white bg-sky-700 w-fit self-end'>
          <p>{dataVoucher?.donation?.unitAmount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
        </div>
      </div>

      <button onClick={onClick} className='w-full py-2 bg-primary rounded shadow-lg text-white'>
        Fazer uma nova doação
      </button>
      <button onClick={handleFavorite} className='w-full py-2.5 bg-red-500 rounded shadow-lg text-white'>
        <span className='flex gap-1 items-center w-full justify-center'>
          {isFavorite ? 'Remover dos favoritos' : 'Favoritar Loja'}
          <span className='text-xl'>{isFavorite ? <Icon icon='ph:heart-fill' color='#fff' /> : <Icon icon='ph:heart' />}</span>
        </span>
      </button>
    </div>
  )
}
