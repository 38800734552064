import { Copy } from '@/components/Common/Copy'
import { Button } from '@/components/Form'
import { Icon } from '@iconify/react'
import { twMerge } from 'tailwind-merge'
import { useDonation } from '@/contexts/DonationContext'
import { FieldMessage } from '@/components/Form/Fields'

export const PaymentForm = () => {
  const { setIsCheckout, dataCreateDonation, paymentForm, dataPartner } = useDonation()
  const paymentData = dataCreateDonation
  const { paymentProof, handleChangeImg, loadingSubmitPayment, handleSubmit, isPixStatic } = paymentForm

  const AccountInfoCell = ({ label, value, className }) => (
    <div className={twMerge('w-full pb-1 flex flex-col items-start gap-1 flex-wrap overflow-hidden', className)}>
      <span className='text-sm font-semibold'>{label}</span>
      <span className='max-w-full truncate'>{value}</span>
    </div>
  )

  return (
    <div className='w-full max-lg:max-w-lg max-lg:mx-auto flex flex-col gap-1 p-4 py-6 bg-surface rounded-lg shadow-xl animate-fadeIn'>
      <div className='w-full flex justify-between items-end border-b border-primary-blue/50 pb-2 mb-3'>
        <div className='flex justify-center items-center gap-1'>
          {!isPixStatic && <Icon icon='svg-spinners:ring-resize' className='text-primary text-2xl' repeatDur={0} />}

          <h4 className='text-lg font-semibold'>Pagamento</h4>
        </div>

        <Icon icon='ic:round-warning' className='text-2xl text-yellow-600' />
      </div>

      <div className='mx-auto max-w-lg w-full grid gap-4 overflow-hidden'>
        <div className='w-full overflow-hidden flex flex-col gap-3'>
          <img className='max-w-[320px] w-full mx-auto' src={paymentData?.payment.qrCode || ''} alt='qr-code' />

          <div className='flex flex-col gap-4'>
            <AccountInfoCell
              label='Chave pix:'
              value={
                <Copy
                  value={paymentData?.payment.copyPasteKey || ''}
                  className='w-full overflow-hidden flex items-center gap-1 bg-primary/10 p-1 px-3 rounded cursor-pointer'
                >
                  <span className='truncate'>{paymentData?.payment.copyPasteKey || ''}</span>{' '}
                  <Icon icon='basil:copy-solid' className='text-xl text-green-800 shrink-0' />
                </Copy>
              }
            />

            <AccountInfoCell label='Titular da conta:' value={dataPartner.settings.companyName} />
          </div>

          <form onSubmit={handleSubmit} className='size-full flex justify-between flex-col gap-5 overflow-hidden'>
            {isPixStatic && (
              <label htmlFor='paymentProof' className='cursor-pointer w-full overflow-hidden'>
                <div className='w-full h-[220px] flex justify-center items-center gap-2 bg-slate-400/40 rounded font-medium overflow-hidden relative'>
                  {paymentProof.preview && (
                    <img src={paymentProof.preview} alt='file' className='absolute top-0 left-0 w-full opacity-50 object-top' />
                  )}

                  <div className='flex flex-col items-center px-4 gap-2'>
                    <div className='z-10 flex gap-2 break-all text-center'>
                      <Icon icon='tabler:file-filled' className='text-xl shrink-0' />
                      {paymentProof.file?.name || 'Anexar comprovante'}
                    </div>
                    <div className='z-10 flex gap-2 text-sm text-zinc-700 px-4 text-center'>
                      Aviso: O arquivo deve estar no formato: JPG, JPEG, PNG ou PDF.
                    </div>
                  </div>
                </div>
                <FieldMessage message={paymentProof.error} />

                <input
                  type='file'
                  name='paymentProof'
                  id='paymentProof'
                  className='hidden'
                  onChange={e => handleChangeImg(e.target.files[0])}
                  accept='image/jpeg,image/gif,image/png,application/pdf'
                />
              </label>
            )}

            <div className='w-full flex justify-end gap-4'>
              {isPixStatic && (
                <Button
                  type='button'
                  onClick={() => {
                    setIsCheckout({ isCheckout: false, infos: null })
                  }}
                  className='bg-transparent ring ring-inset ring-slate-600 text-zinc-900 font-semibold'
                >
                  Cancelar
                </Button>
              )}

              {isPixStatic && (
                <Button type='submit' isLoading={loadingSubmitPayment} isDisabled={loadingSubmitPayment} className='rounded px-8'>
                  Enviar
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
