import { useApi } from '@/hooks'
// import { fakeRequest } from '@/utils'
// import { paymentStatus } from '../constants'
import httpClient from '@/utils/httpClient'

// const mockedData = {
//   status: paymentStatus.APPROVED,
// }

export const useGetVerifyPayment = () => {
  const { data, makeRequest, loading } = useApi(id => httpClient.get(`/donations/${id}/status`))

  return {
    dataVerifyPayment: data,
    getVerifyPayment: makeRequest,
    isLoadingVerifyPayment: loading,
  }
}
