import { FieldMessage } from '@/components/Form/Fields'
import { Icon } from '@iconify/react'
import { twMerge } from 'tailwind-merge'

export const InputImage = ({ fileValue, error, handleChangeImg }) => {
  return (
    <div className='w-full'>
      <p className={twMerge('text-sm font-medium mb-1', error && 'text-negative font-bold')}>Imagem</p>

      <label
        htmlFor='paymentProof'
        className={twMerge(
          'w-full h-[240px] flex justify-center items-center gap-2 bg-slate-400/40 rounded font-medium overflow-hidden relative cursor-pointer',
          error && 'border border-negative'
        )}
      >
        {fileValue.preview && <img src={fileValue.preview} alt='file' className='absolute top-0 left-0 w-full object-cover' />}

        <div className='flex flex-col items-center px-4 gap-2'>
          <div
            className={twMerge(
              'z-10 flex gap-2 break-all text-center absolute top-0 left-0 w-full h-full justify-center items-center transition',
              fileValue.file && 'bg-zinc-300/0 opacity-0 hover:bg-zinc-300/55 hover:opacity-100'
            )}
          >
            <Icon icon='tabler:file-filled' className='text-xl shrink-0' />
            Anexar Arquivo
          </div>
        </div>

        <input
          type='file'
          name='paymentProof'
          id='paymentProof'
          className='hidden'
          onChange={e => handleChangeImg(e.target.files[0])}
          accept='image/jpeg,image/gif,image/png,application/pdf'
        />
      </label>

      <div className='flex justify-between items-center flex-wrap'>
        <FieldMessage message={error} />

        <div className='z-10 flex items-center mt-2 gap-1 text-sm text-zinc-700 text-start'>
          <Icon className='text-xl shrink-0 text-primary-600' icon='tabler:info-circle-filled' />O arquivo deve estar no formato:
          JPG, JPEG, PNG.
        </div>
      </div>
    </div>
  )
}
