import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const usePostCreatePayment = () => {
  const { data, makeRequest, loading } = useApi(params => httpClient.post('/donations/generate-payment', null, { params }))

  return {
    dataCreatePayment: data,
    postCreatePayment: makeRequest,
    isLoadingCreatePayment: loading,
  }
}
