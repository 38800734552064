import { useAuth } from '@/contexts'
import { Icon } from '@iconify/react'
import { toast } from 'react-toastify'
import { ModalQRCodeIndication } from './ModalQRCodeIndication'
import { useDisclosure } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export const PartnerLink = () => {
  const { user } = useAuth()
  const link = `${import.meta.env.VITE_BASE_URL}/donate/${user.indicationCode || ''}`

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link).then(() => {
      toast.success('Link de indicação copiado!')
    })
  }
  const { isOpen, onClose, onOpen } = useDisclosure()

  if (!user || !user.indicationCode || user.indicationCode.length === 0) return null

  return (
    <div>
      <label className='font-medium mb-1 text-primary-600'>Link de Indicação</label>
      <div className='flex gap-x-1.5 '>
        <div className='w-full overflow-hidden rounded shadow-md'>
          <div className='bg-surface p-3 px-4 flex w-full justify-between items-center gap-2 underline underline-offset-2 text-neutral-700'>
            <Link to={`/donate/${user.indicationCode || 'AA'}`} className='text-start break-all truncate cursor-pointer'>
              {link}
            </Link>

            <button onClick={handleCopyLink} className='shrink-0'>
              <Icon className='text-primary-600 text-2xl shrink-0' icon='solar:copy-bold' />
            </button>
          </div>
        </div>
        <div
          onClick={onOpen}
          className='bg-surface rounded p-1.5 shadow-md cursor-pointer items-center hover:bg-gray-300 shrink-0'
        >
          <Icon icon='ph:qr-code-fill' className='text-primary-600 text-4xl' />
        </div>
      </div>
      <ModalQRCodeIndication isOpen={isOpen} onClose={onClose} />
    </div>
  )
}
