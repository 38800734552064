import axios from 'axios'
import Swal from 'sweetalert2'

const httpClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'ngrok-skip-browser-warning': '1',
  },
})

httpClient.interceptors.request.use(
  async config => {
    const token = window.localStorage.getItem('auth:user')
    if (token !== null) config.headers.Authorization = `Bearer ${token}`
    return config
  },
  error => Promise.reject(error)
)

httpClient.interceptors.response.use(undefined, error => {
  if ([401, 403].includes(error.response.status)) {
    Swal.fire({
      title: 'Sessão expirada!',
      text: 'Faça login novamente.',
      icon: 'error',
      confirmButtonText: 'Fazer login',
      backdrop: 'rgba(0,0,0,0.9)',
      didClose() {
        window.localStorage.removeItem('auth:user')

        window.location.href = '/login'
      },
    })
  }
  return Promise.reject(error)
})

export const fakeRequest = (data, time = 1500, meta = [], status = 'success') => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        status,
        data: {
          data,
          meta,
        },
      })
    }, time)
  })
}

export const addHeader = (name, value) => {
  httpClient.defaults.headers.common[name] = value
}

export const removeHeader = name => {
  delete httpClient.defaults.headers.common[name]
}

export default httpClient
