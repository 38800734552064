import { DateAsText } from '@/components/Common'
import { GridFragment } from '@/components/Common/GridFragment'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { PromoBadge } from './PromoBadge'
import { useApprovePromo, useGetPromoDetail } from '../hooks/useApi'
import { useEffect } from 'react'
import { Loading } from '@/components/Common/Loading'
import { APPROVED, PUBLISHED, REFUSED, UNDER_ANALYSIS } from '../constants'
import { toast } from 'react-toastify'
import { ModalReasonPromo } from './ModalReasonPromo'
import { Button } from '@/components/Form'
import Swal from 'sweetalert2'
import { useAuth } from '@/contexts'

export const ModalPromoDetails = ({ getPromos, isOpen, onClose, onOpen, id }) => {
  const { userGroup } = useAuth()
  const { isOpen: isOpenRefuseModal, onClose: onCloseRefuseModal, onOpen: onOpenRefuseModal } = useDisclosure()
  const { getPromoDetail, promoDetailData, promoDetailLoading } = useGetPromoDetail()
  const { postPromoApprove, promoApproveLoading } = useApprovePromo()

  const handleApprovePromo = () => {
    onClose()
    Swal.fire({
      title: 'Aprovar Promoção!',
      html: `
        <p>Você está prestes a aprovar esta promoção.</p>
        <span style="color: red;font-size: 14px;font-weight: 500">Esta ação não poderá ser desfeita!</span>
      `,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Continuar',
      confirmButtonColor: '#66BB6A',
      cancelButtonColor: '#186885',
      reverseButtons: true,
    }).then(async result => {
      if (result.isConfirmed)
        return postPromoApprove(id)
          .then(() => {
            getPromos()
            getPromoDetail(id)
            handleUseToast('success', 'Promoção aprovada com sucesso!', true)
            onClose()
          })
          .catch(() => {
            handleUseToast('error', 'Erro ao aprovar promoção!')
            onOpen()
          })
      onOpen()
    })
  }

  const handleUseToast = (status, text, close) => {
    toast[status](text, {
      position: 'top-center',
      autoClose: 2000,
    })
    if (close) onClose()
  }

  useEffect(() => {
    if (!id) return
    getPromoDetail(id).catch(() => handleUseToast('error', 'Ocorreu um erro ao buscar os detalhes da promoção.', true))
  }, [id])

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size='2xl' isCentered scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <div className='flex items-center justify-between'>
              <h2 className='text-2xl'>Detalhes</h2>
              {!promoDetailLoading && promoDetailData && <PromoBadge status={promoDetailData.status} className='mr-8' />}
            </div>

            <ModalCloseButton top={4} />
          </ModalHeader>
          <ModalBody className='flex flex-col'>
            {promoDetailLoading && (
              <div className='flex my-10'>
                <Loading />
              </div>
            )}
            {!promoDetailLoading && promoDetailData && (
              <div className='flex flex-col gap-5'>
                <div>
                  <h4 className='font-semibold text-lg mb-1 ml-[2px]'>Pré-visualização:</h4>
                  <div className='border-2 border-primary rounded-lg flex flex-col gap-6 p-4'>
                    <a className='text-2xl text-primary underline cursor-pointer mx-auto text-center break-all'>
                      {promoDetailData.title}
                    </a>
                    <img
                      src={promoDetailData.imageUrl}
                      className='rounded-lg object-contain mx-auto max-h-[150px]'
                      alt='Imagem da promoção'
                    />
                    <div className='w-full'>
                      <p className='text-primary-800 font-semibold cursor-pointer mb-2'>
                        {JSON.parse(promoDetailData.partner.address).neighborhood}
                      </p>
                      <p className='text-primary-800  font-semibold cursor-pointer'>
                        {JSON.parse(promoDetailData.partner.address).city} / {JSON.parse(promoDetailData.partner.address).state}
                      </p>
                      <p>{promoDetailData.description}</p>
                    </div>
                  </div>
                </div>
                <div className={`grid grid-cols-2 ${promoDetailData.status !== REFUSED && 'mb-4'}`}>
                  {userGroup.isAdmin && (
                    <GridFragment title='Nome do Parceiro' className='col-span-2' body={promoDetailData.partner.name} truncate />
                  )}

                  <GridFragment
                    title='Data de Criação'
                    body={promoDetailData.createdAt ? <DateAsText date={promoDetailData.createdAt} /> : '-'}
                  />
                  {promoDetailData.status === REFUSED && (
                    <GridFragment
                      title='Data de Recusa'
                      body={promoDetailData.refusedAt ? <DateAsText date={promoDetailData.refusedAt} /> : '-'}
                    />
                  )}

                  {promoDetailData.status === PUBLISHED && (
                    <GridFragment
                      title='Data de Publicação'
                      body={promoDetailData.publishedAt ? <DateAsText date={promoDetailData.publishedAt} /> : '-'}
                    />
                  )}

                  {promoDetailData.status === APPROVED && (
                    <GridFragment
                      title='Data da aprovação'
                      body={promoDetailData.approvedAt ? <DateAsText date={promoDetailData.approvedAt} /> : '-'}
                    />
                  )}
                </div>
                {promoDetailData.status === REFUSED && (
                  <div className='mb-4'>
                    <h4 className='font-semibold text-lg'>Motivo da Recusa:</h4>
                    <p>{promoDetailData.refusedReason}</p>
                  </div>
                )}
              </div>
            )}
          </ModalBody>
          {userGroup.isAdmin && !promoDetailLoading && promoDetailData && promoDetailData.status === UNDER_ANALYSIS && (
            <ModalFooter>
              <Button
                isLoading={promoApproveLoading}
                isDisabled={promoApproveLoading}
                onClick={() => {
                  onClose()
                  onOpenRefuseModal()
                }}
                className='mr-3 bg-negative'
              >
                Recusar
              </Button>
              <Button isLoading={promoApproveLoading} isDisabled={promoApproveLoading} onClick={handleApprovePromo}>
                Aprovar
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
      <ModalReasonPromo
        getPromos={getPromos}
        getPromoDetail={getPromoDetail}
        id={id}
        isOpen={isOpenRefuseModal}
        onClose={onCloseRefuseModal}
        onOpenRefuseModal={onOpenRefuseModal}
        onCloseMainModal={onClose}
        onOpenMainModal={onOpen}
      />
    </>
  )
}
