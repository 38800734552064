import Swal from 'sweetalert2'
import { useAproveDonation } from '../../DonationList/hooks/useAproveDonation'
import { toast } from 'react-toastify'
import { useRefuseDonation } from '../../DonationList/hooks/useRefuseDonation'
import { useGetPartnerDetails } from './useGetPartnerDetails'
import { useEffect } from 'react'

export const useTableList = ({ fetchDonations, partnerId }) => {
  const { makeRequest: putAprove } = useAproveDonation()
  const { makeRequest: putRefuse } = useRefuseDonation()
  const { data: dataPartnerDetails, makeRequest: getPartnerDetails } = useGetPartnerDetails()

  const handleApproveDonation = id => {
    Swal.fire({
      title: 'Confirmar Aprovação?',
      html: `
            <div>
              <p>Você está prestes a aprovar essa doação.</p>
              <small class='text-red-500 font-medium' >Essa ação não poderá ser desfeita!</small>
            </div>
          `,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'Aprovar',
      cancelButtonText: 'Cancelar',
      icon: 'warning',
      showLoaderOnConfirm: true,
      confirmButtonColor: '#66BB6A',
      cancelButtonColor: '#13536A',
      preConfirm: async () => {
        await putAprove(id)
          .then(() => {
            toast.success('Doação aprovada com sucesso!')
            fetchDonations()
          })
          .catch(() => {
            toast.error('Não foi possível aprovar a doação!')
          })
          .finally(() => {
            return
          })
      },
    })
  }

  const handleRefuseDonation = id => {
    Swal.fire({
      title: 'Recusar Doação?',
      html: `
              <div>
                <p>Você está prestes a reprovar essa doação.</p>
                <small class='text-red-500 font-medium' >Essa ação não poderá ser desfeita!</small>
              </div>
            `,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'Recusar',
      cancelButtonText: 'Cancelar',
      icon: 'warning',
      showLoaderOnConfirm: true,
      confirmButtonColor: '#F44336',
      cancelButtonColor: '#13536A',
      preConfirm: async () => {
        await putRefuse(id)
          .then(() => {
            toast.warn('Doação recusada!')
            fetchDonations()
          })
          .catch(() => {
            toast.error('Não foi possível aprovar a doação!')
          })
          .finally(() => {
            return
          })
      },
    })
  }

  useEffect(() => {
    if (!partnerId) return null

    getPartnerDetails(partnerId)
  }, [partnerId])

  return {
    handleApproveDonation,
    handleRefuseDonation,
    dataPartnerDetails,
  }
}
