import { CheckBox } from '@/components/Form'
import { FieldMessage } from '@/components/Form/Fields'

export const TermsStep = ({ values, errors, setFieldValue }) => {
  return (
    <div className='flex flex-col gap-3'>
      <iframe
        className='h-[55vh] max-h-[620px]'
        id='termsFile'
        title='Temos e Condições'
        src='/termos-e-condicoes.pdf#toolbar=0'
      ></iframe>

      <div className='mb-4'>
        <CheckBox
          label={
            <span>
              Eu concordo com os <span className='underline underline-offset-2'>Termos e condições de uso</span>
            </span>
          }
          name='check'
          value={values.check}
          onChange={e => {
            setFieldValue('check', e.target.checked)
          }}
          checked={values.check}
        />
        {errors.check && <FieldMessage message={errors.check} />}
      </div>
    </div>
  )
}
