import { Logo } from '../Common'
import { Menu } from './Menu'

export const Header = () => {
  return (
    <nav className='w-full flex items-center justify-between gap-x-2 max-sm:flex-wrap mb-4 bg-surface border-b border-primary-400 px-4 max-[420px]:pb-1'>
      <div className='flex items-center'>
        <Menu />
      </div>

      <div className='flex items-center text-white py-3'>
        <Logo src='/images/logo-transparent.png' className='w-full max-w-[160px]' />
      </div>
    </nav>
  )
}
