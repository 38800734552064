import { useAuth } from '@/contexts'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export function useGetPromos() {
  const { userGroup } = useAuth()
  const { data, error, loading, meta, makeRequest } = useApi(params =>
    httpClient.get(userGroup.isAdmin ? `admin/partners/promotions?sort=createdAt` : `partners/promotions?sort=createdAt`, {
      params,
    })
  )
  return {
    promoData: data,
    promoMeta: meta,
    promoError: error,
    promoLoading: loading,
    getPromos: makeRequest,
  }
}

export function useGetPromoDetail() {
  const { userGroup } = useAuth()
  const { data, error, loading, meta, makeRequest } = useApi(id =>
    httpClient.get(userGroup.isAdmin ? `admin/partners/promotions/${id}` : `/partners/promotions/${id}`)
  )

  return {
    promoDetailData: data,
    promoDetailMeta: meta,
    promoDetailError: error,
    promoDetailLoading: loading,
    getPromoDetail: makeRequest,
  }
}

export function useRefusePromo() {
  const { data, error, loading, meta, makeRequest } = useApi((id, values) =>
    httpClient.put(`/admin/partners/promotions/${id}/refuse`, { ...values })
  )

  return {
    promoRefuseData: data,
    promoRefuseMeta: meta,
    promoRefuseError: error,
    promoRefuseLoading: loading,
    postPromoRefuse: makeRequest,
  }
}

export function useApprovePromo() {
  const { data, error, loading, meta, makeRequest } = useApi(id => httpClient.put(`/admin/partners/promotions/${id}/approve`))

  return {
    promoApproveData: data,
    promoApproveMeta: meta,
    promoApproveError: error,
    promoApproveLoading: loading,
    postPromoApprove: makeRequest,
  }
}

export const usePostNewPromo = () => {
  const { data, error, loading, makeRequest } = useApi(params =>
    httpClient.post(`/partners/promotions`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  )

  return { dataPostPromo: data, postPromo: makeRequest, errorPostPromo: error, loadingPostPromo: loading }
}
