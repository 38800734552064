// Promo Status
export const UNDER_ANALYSIS = 1
export const APPROVED = 2
export const REFUSED = 3
export const PUBLISHED = 4

export const getStatusPromo = status => {
  switch (status) {
    case UNDER_ANALYSIS:
      return 'Em Análise'
    case APPROVED:
      return 'Aprovado'
    case REFUSED:
      return 'Recusado'
    case PUBLISHED:
      return 'Publicado'
    default:
      return 'Desconhecido'
  }
}

export const getStatusOptionsPromo = () => {
  return [
    { value: UNDER_ANALYSIS, label: getStatusPromo(UNDER_ANALYSIS) },
    { value: APPROVED, label: getStatusPromo(APPROVED) },
    { value: REFUSED, label: getStatusPromo(REFUSED) },
    { value: PUBLISHED, label: getStatusPromo(PUBLISHED) },
  ]
}

export const getPromoBadgeColor = status => {
  switch (status) {
    case UNDER_ANALYSIS:
      return 'yellow-500'
    case APPROVED:
      return 'positive-light'
    case REFUSED:
      return 'negative-light'
    case PUBLISHED:
      return 'primary'
  }
}
