import { useEffect, useState } from 'react'
import { useGetBalance } from './useGetBalanace'
import { useGetBalancePeriod } from './useGetBalancePeriod'
import { useAuth } from '@/contexts'
import { format, startOfDay, startOfMonth, startOfWeek } from 'date-fns'
import { useGetDonationsPeriod } from './useGetDonationPeriod'
import { useGetNetworkVault } from './useGetNetworkVault'

export const useDashboard = () => {
  const { dataBalance, loadingBalance, fetchBalance } = useGetBalance()
  const { dataNetworkVault, fetchNetworkVault, loadingNetworkVault } = useGetNetworkVault()
  const { fetchBalancePeriod, loadingBalancePeriod } = useGetBalancePeriod()
  const { fetchDonationsPeriod, loadingDonationsPeriod } = useGetDonationsPeriod()
  const { userGroup, user } = useAuth()
  const [dataPeriodBalance, setPeriodBalance] = useState({
    partnerCashback: 0,
    adminBalance: {
      day: 0,
      week: 0,
      month: 0,
    },
    adminCashback: {
      day: 0,
      week: 0,
      month: 0,
      total: 0,
    },
  })

  const todayDate = new Date()

  const isLoadingData = loadingBalance || loadingBalancePeriod || loadingDonationsPeriod || loadingNetworkVault

  const dataDashboard = {
    balance: dataBalance,
    networkVault: dataNetworkVault,
  }

  const fetchAllData = async () => {
    await fetchBalance()

    if (userGroup.isPartner) {
      await fetchBalancePeriod({
        startDate: format(startOfMonth(todayDate), 'yyyy-MM-dd'),
        endDate: format(todayDate, 'yyyy-MM-dd'),
      }).then(response => {
        setPeriodBalance(prev => ({ ...prev, partnerCashback: response.data.data.pts * 0.01 }))
      })
    }

    if (userGroup.isAdmin) {
      await Promise.all([
        fetchBalancePeriod({
          startDate: format(startOfMonth(todayDate), 'yyyy-MM-dd'),
          endDate: format(todayDate, 'yyyy-MM-dd'),
        }),
        fetchBalancePeriod({
          startDate: format(startOfWeek(todayDate), 'yyyy-MM-dd'),
          endDate: format(todayDate, 'yyyy-MM-dd'),
        }),
        fetchBalancePeriod({
          startDate: format(startOfDay(todayDate), 'yyyy-MM-dd'),
          endDate: format(todayDate, 'yyyy-MM-dd'),
        }),
        fetchDonationsPeriod({
          startDate: format(startOfMonth(todayDate), 'yyyy-MM-dd'),
          endDate: format(todayDate, 'yyyy-MM-dd'),
        }),
        fetchDonationsPeriod({
          startDate: format(startOfWeek(todayDate), 'yyyy-MM-dd'),
          endDate: format(todayDate, 'yyyy-MM-dd'),
        }),
        fetchDonationsPeriod({
          startDate: format(startOfDay(todayDate), 'yyyy-MM-dd'),
          endDate: format(todayDate, 'yyyy-MM-dd'),
        }),
        fetchDonationsPeriod(),
        fetchNetworkVault(),
      ]).then(response => {
        setPeriodBalance(prev => ({
          ...prev,
          adminBalance: {
            month: response[0].data.data.monetary,
            week: response[1].data.data.monetary,
            day: response[2].data.data.monetary,
          },
          adminCashback: {
            month: Number(response[3].data.data.sum),
            week: response[4].data.data.sum,
            day: response[5].data.data.sum,
            total: Number(response[6].data.data.allSum),
          },
        }))
      })
    }
  }

  useEffect(() => {
    user && fetchAllData()
  }, [user])

  return {
    dataDashboard,
    dataPeriodBalance,
    isLoadingData,
  }
}
