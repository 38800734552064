import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'

export const Copy = ({ value, children, className }) => {
  return (
    <>
      <CopyToClipboard
        text={value}
        onCopy={() =>
          toast.success('Copiado com sucesso!', {
            position: 'top-center',
            autoClose: 2000,
            toastId: 'copied',
          })
        }
      >
        <div className={className}>{children}</div>
      </CopyToClipboard>
    </>
  )
}
