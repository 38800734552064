import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useGetPartnerTableList } from './hook/useGetPartnerTableList'
import { FilterBuilder } from '@/utils'

export const usePartnerList = () => {
  const { fetchPartnerDetails, dataPartnerDetails, metaPartnerDetails } = useGetPartnerTableList()
  const [filtersParams, setFilters] = useState({})
  const [activeFilters, setActiveFilters] = useState([])

  const onFetchPartner = params => {
    fetchPartnerDetails({ ...filtersParams, ...params })
  }

  const filterSchema = yup.object({
    term: yup.string(),
    cpfCnpj: yup.string(),
    initialDate: yup.string(),
    endDate: yup.string(),
    isCpf: yup.boolean(),
  })

  useEffect(() => {
    fetchPartnerDetails()
  }, [])

  const onSubmit = values => {
    if (values.isCpf && values.cpfCnpj.length > 0 && values.cpfCnpj.replace(/\D/g, '').length !== 11) {
      filterParams.setFieldError('cpfCnpj', 'O CPF deve conter 11 números')
      return
    }
    if (!values.isCpf && values.cpfCnpj.length > 0 && values.cpfCnpj.replace(/\D/g, '').length !== 14) {
      filterParams.setFieldError('cpfCnpj', 'O CNPJ deve conter 14 números')
      return
    }

    const filters = FilterBuilder()
      .equal('cpfCnpj', values.cpfCnpj.length > 0 ? values.cpfCnpj.replace(/\D/g, '') : null)
      .like('name', values.term.length > 0 ? values.term : null)
      .between(
        'user.createdAt',
        values.initialDate.length > 0 ? `${values.initialDate}${values.endDate.length > 0 ? `,${values.endDate}` : ''}` : null
      )
      .build()

    setFilters(filters)
    fetchPartnerDetails(filters)
    const newFilters = Object.entries(values)
    setActiveFilters(newFilters.filter(i => i[1] !== '' && i[0] !== 'isCpf').map(i => ({ label: i[0], value: i[1] })))
    return
  }

  const handleCpfCnpjChange = () => {
    filterParams.setFieldValue('cpfCnpj', '')
    filterParams.setFieldError('cpfCnpj', '')
  }

  const filterParams = useFormik({
    initialValues: {
      term: '',
      cpfCnpj: '',
      initialDate: '',
      endDate: '',
      isCpf: true,
    },
    onSubmit,
    validationSchema: filterSchema,
    validateOnBlur: false,
    validateOnChange: false,
  })

  const handleClearFilter = () => {
    filterParams.resetForm()
    setActiveFilters([])
    fetchPartnerDetails()
  }

  useEffect(() => {
    filterParams.values.initialDate.length === 0 && filterParams.setFieldError('endDate', '')
  }, [filterParams.values.initialDate])

  useEffect(() => {
    handleCpfCnpjChange()
  }, [filterParams.values.isCpf])

  return { filterParams, dataPartnerDetails, metaPartnerDetails, onFetchPartner, activeFilters, handleClearFilter }
}
