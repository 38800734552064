import { useFormik } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

export const useFormCheckout = ({ setIsCheckout, requestVerify, onOpenPartnerModal, isChecked, checkout, onSubmitDonation }) => {
  const { t } = useTranslation()
  const handleClearVerify = () => {
    formParams.setFieldValue('isVerified', false)
    setIsCheckout({ isCheckout: false, infos: null })
    formParams.resetForm()
  }

  useEffect(() => {
    if (!checkout?.isCheckout) {
      formParams.setFieldValue('isVerified', false)
      return
    }
  }, [checkout])

  const Schema = Yup.object({
    email: Yup.string().email(t('global.validation.invalidEmail')).required(t('global.validation.required')),
    name: Yup.string().when('isVerified', {
      is: true,
      then: Yup.string()
        .matches(/^[a-zA-Z áàãâéêíóôõúüçÁÀÃÂÉÊÍÓÔÕÚÜÇ]*$/, t('global.validation.noSpecialCharacters'))
        .required(t('global.validation.required')),
      otherwise: Yup.string().notRequired(),
    }),
    phone: Yup.string().when('isVerified', {
      is: true,
      then: Yup.string().min(11, t('global.validation.invalidPhone')).required(t('global.validation.required')),
      otherwise: Yup.string().notRequired(),
    }),
    cpf: Yup.string().when('isVerified', {
      is: true,
      then: Yup.string()
        .test('lengthMinCpf', 'Digite um CPF válido', value => {
          return value?.replace(/\D/g, '').length === 11 || !value
        })
        .required(t('global.validation.required')),
      otherwise: Yup.string().notRequired(),
    }),
    donorId: Yup.string(),
    isVerified: Yup.boolean(),
    isFilled: Yup.boolean(),
  })

  const onSubmit = async values => {
    if (!formParams.values.isVerified) {
      await requestVerify(formParams.values.email)
        .then(response => {
          if (response.data) {
            const { name, phone, cpf } = response.data.data

            formParams.setFieldValue('phone', phone)
            formParams.setFieldValue('name', name)
            formParams.setFieldValue('cpf', cpf)

            formParams.setFieldValue('isVerified', true)
            formParams.setFieldValue('isFilled', true)
            formParams.setFieldValue('donorId', response.data.data.id)
          }
        })
        .catch(() => {
          toast.warn('Nenhum cadastro encontrado')
          if (isChecked) {
            onOpenPartnerModal()
            return
          }
          formParams.setFieldValue('phone', '')
          formParams.setFieldValue('name', '')
          formParams.setFieldValue('cpf', '')
          formParams.setFieldValue('isVerified', true)
        })

      return
    }

    onSubmitDonation({ DonorValues: values })
  }

  const formParams = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      donorId: '',
      isVerified: false,
      isFilled: false,
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
  return {
    Schema,
    onSubmit,
    handleClearVerify,
    formParams,
  }
}
