import { useDisclosure } from '@chakra-ui/react'

export const useDonationCard = ({ donation, onViewLinks }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const donationValue = donation ? donation.payment.unitAmount * donation.payment.quantity : 0

  const handleVoucher = () => {
    const voucherCode = donation?.voucher?.code ? donation.voucher.code : ''
    window.open(`/voucher/${voucherCode}`, '_blank')
  }

  const handleLinks = async () => {
    await onViewLinks(donation?.id)
    onOpen()
  }

  const indicationUrl = donation ? `${import.meta.env.VITE_BASE_URL}/donate/${donation.partner.url}` : null

  const getDate = () => {
    if (!donation) return null

    if (donation.payment.status === 1) {
      return donation.createdAt
    }
    if (donation.payment.status === 2) {
      return donation.payment.paidAt
    }
    if (donation.payment.status === 3) {
      return donation.payment.refusedAt
    }
  }

  return {
    isOpen,
    onClose,
    donationValue,
    handleVoucher,
    handleLinks,
    indicationUrl,
    getDate,
  }
}
