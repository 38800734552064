import { EmptyList } from '@/components/Common/EmptyList'
import { ContainerSection } from '../../components'
import { Filters } from './Filters'
import { TableList } from './TableList'
import { usePartnerList } from './usePartnerList'
// import { Icon } from '@iconify/react'
// import { useDisclosure } from '@chakra-ui/react'
// import { ModalInvitePartner } from '../../components/ModalInvitePartner'
// import { Button } from '@/components/Form'
import { Pagination } from '@/components/Common/Pagination'

export const PartnerList = () => {
  const { filterParams, dataPartnerDetails, metaPartnerDetails, onFetchPartner, activeFilters, handleClearFilter } =
    usePartnerList()
  // const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <>
      <ContainerSection>
        <div className='flex justify-between items-baseline'>
          <h2 className='text-xl font-semibold text-primary'>Lista de Parceiros</h2>
          {/* <Button onClick={onOpen} className='bg-primary-600 py-1.5 px-4 rounded'>
            <Icon icon='mingcute:send-plane-fill' className='text-2xl text-slate-200 text-center p-0' />
          </Button> */}
        </div>
        <div className='flex flex-col gap-4'>
          <Filters filterParams={filterParams} activeFilters={activeFilters} handleClearFilter={handleClearFilter} />

          {dataPartnerDetails && dataPartnerDetails.length > 0 ? (
            <TableList dataPartnerDetails={dataPartnerDetails} />
          ) : (
            <EmptyList message='Nenhum parceiro encontrado!' />
          )}
        </div>

        {metaPartnerDetails && <Pagination pagination={metaPartnerDetails} onPageChange={onFetchPartner} />}
      </ContainerSection>

      {/* <ModalInvitePartner isOpen={isOpen} onClose={onClose} /> */}
    </>
  )
}
