import { CpfCnpjField, MultiselectField, SelectField } from '@/components/Form/Fields'
import React from 'react'

export const BusinessStep = ({ values, errors, selectOptions, handleChange, multiSelectOptions, setFieldValue }) => {
  return (
    <>
      <SelectField
        label='Tipo de Parceiro'
        name='typeCustomer'
        optionPrimary={null}
        value={values.typeCustomer}
        errorMessage={errors.typeCustomer}
        options={selectOptions()}
        onChange={e => {
          handleChange(e)
        }}
      />
      {values.typeCustomer === 'cpf' && (
        <CpfCnpjField
          label='CPF'
          name='cpfCnpj'
          placeholder='Ex.: 349.251.155-45'
          value={values.cpfCnpj}
          errorMessage={errors.cpfCnpj}
          onChange={handleChange}
        />
      )}
      {values.typeCustomer === 'cnpj' && (
        <CpfCnpjField
          label='CNPJ'
          name='cpfCnpj'
          format='cnpj'
          placeholder='Ex.: 00.000.000/0000-00'
          value={values.cpfCnpj}
          errorMessage={errors.cpfCnpj}
          onChange={handleChange}
        />
      )}
      <MultiselectField
        label='Segmento'
        className='mb-4'
        name='segments'
        value={values.segments}
        options={multiSelectOptions.map(option => ({ label: option.name, value: option.id }))}
        onChange={event => {
          setFieldValue('segments', event)
        }}
        errorMessage={errors.segments}
      />
    </>
  )
}
