import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export function useGetBalancePeriod() {
  const { data, error, loading, makeRequest } = useApi(params => httpClient.get('/financial/balance-by-period', { params }))

  return {
    dataBalancePeriod: data,
    errorBalancePeriod: error,
    loadingBalancePeriod: loading,
    fetchBalancePeriod: makeRequest,
  }
}
