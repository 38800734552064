import { EmptyList } from '@/components/Common/EmptyList'
import { CashbackFilter } from './CashbackFilter'
import { CashbackTable } from './CashbackTable'
import { useCashback } from './hooks/useCashback'
import { Pagination } from '@/components/Common/Pagination'
import { LoadingData } from '@/components/Common/LoadingData'

export const CashbackStatement = () => {
  const {
    filterParams,
    dataCashbackStatement,
    metaCashbackStatement,
    onFetchCashback,
    loadingCashbackStatement,
    activeFilters,
    handleClearFilter,
  } = useCashback()

  return (
    <section className='flex flex-col justify-center gap-3 px-4 mb-6'>
      <div className='flex justify-between items-baseline'>
        <h2 className='text-2xl font-semibold text-primary'>Extrato de Cashback</h2>
      </div>

      <CashbackFilter
        activeFilters={activeFilters}
        handleClearFilter={handleClearFilter}
        filtersParams={filterParams}
        loadingPartnerCashback={loadingCashbackStatement}
      />

      {loadingCashbackStatement ? <LoadingData /> : <CashbackTable dataCashbackStatement={dataCashbackStatement} />}

      {dataCashbackStatement?.length === 0 && !loadingCashbackStatement && <EmptyList message='Nenhum resultado encontrado!' />}

      {metaCashbackStatement && <Pagination pagination={metaCashbackStatement} onPageChange={onFetchCashback} />}
    </section>
  )
}
