import { Menu as ChakraMenu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '@/contexts'

export const Menu = () => {
  const navigate = useNavigate()
  const { token, user, userGroup, setToken } = useAuth()

  const handleLogin = () => {
    navigate('/login')
  }

  const handleLogout = () => {
    setToken(null)
    navigate('/login')
  }

  const handleNavigate = path => {
    navigate(path)
  }

  return (
    <ChakraMenu>
      {({ isOpen }) => (
        <>
          <MenuButton>
            <div className='flex gap-6 items-center justify-center ring-2 ring-inset p-1 text-primary ring-primary-400 rounded font-bold text-2xl'>
              <Icon icon={isOpen ? 'mingcute:close-fill' : 'mingcute:menu-line'} />
            </div>
          </MenuButton>

          <MenuList minW={160}>
            {token && (
              <MenuItem justifyContent='flex-start' onClick={() => handleNavigate('/dashboard')}>
                <div className='flex gap-2 items-center'>
                  <Icon icon='ic:round-home' className='text-primary text-2xl' />
                  Dashboard
                </div>
              </MenuItem>
            )}

            {(userGroup.isAdmin || userGroup.isPartner) && (
              <MenuItem justifyContent='flex-start' onClick={() => handleNavigate('/donationList')}>
                <div className='flex gap-2 items-center'>
                  <Icon icon='mdi:donation' className='text-primary text-xl' />
                  Doações
                </div>
              </MenuItem>
            )}

            {(userGroup.isDonor || userGroup.isPartner) && (
              <MenuItem justifyContent='flex-start' onClick={() => handleNavigate('/page-withdrawal')}>
                <div className='flex gap-2 items-center'>
                  <Icon icon='fluent-mdl2:money' className='text-primary text-xl' />
                  Saque
                </div>
              </MenuItem>
            )}

            {user && (
              <MenuItem justifyContent='flex-start' onClick={() => handleNavigate('/cashback')}>
                <div className='flex gap-2 items-center'>
                  <Icon icon='ri:coins-line' className='text-primary text-xl' />
                  Cashback
                </div>
              </MenuItem>
            )}
            {userGroup.isAdmin && (
              <MenuItem justifyContent='flex-start' onClick={() => handleNavigate('/network')}>
                <div className='flex gap-2 items-center'>
                  <Icon icon='lucide:network' className='text-primary text-xl' />
                  Rede
                </div>
              </MenuItem>
            )}

            {(userGroup.isAdmin || userGroup.isPartner) && (
              <MenuItem justifyContent='flex-start' onClick={() => handleNavigate('/promos')}>
                <div className='flex gap-2 items-center'>
                  <Icon icon='hugeicons:promotion' className='text-primary text-xl' />
                  Promoções
                </div>
              </MenuItem>
            )}

            {token ? (
              <MenuItem justifyContent='flex-start' onClick={handleLogout}>
                <div className='flex gap-2 items-center'>
                  <Icon icon='tabler:logout' className='text-negative-dark text-2xl' /> Sair
                </div>
              </MenuItem>
            ) : (
              <MenuItem justifyContent='flex-start' onClick={handleLogin}>
                <div className='flex gap-2 items-center'>
                  <Icon icon='mingcute:user-3-fill' className='text-primary text-lg' /> Entrar
                </div>
              </MenuItem>
            )}
          </MenuList>
        </>
      )}
    </ChakraMenu>
  )
}
