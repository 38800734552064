import React from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

export const StatusAsText = ({ status, statusClassName }) => {
  const { t } = useTranslation()

  let statusText = ''
  let color = ''

  switch (status) {
    case 1:
      statusText = `${t('status.pending')}`
      color = 'bg-warning-light'
      break
    case 2:
      statusText = `${t('status.approved')}`
      color = 'bg-positive-light'
      break
    case 3:
      statusText = `${t('status.refused')}`
      color = 'bg-negative-light'
      break
    case 4:
      statusText = `${t('status.awaitingPayment')}`
      color = 'bg-warning-light'
      break
    default:
      statusText = status
      color = 'bg-gray-500'
      break
  }

  return (
    <span
      className={twMerge(
        'text-center py-2 px-4 w-fit text-white font-medium font-Inter rounded-full text-sm',
        statusClassName,
        color
      )}
    >
      {statusText}
    </span>
  )
}
