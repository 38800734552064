import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export function useGetNetworkVault() {
  const { data, error, loading, makeRequest } = useApi(() => httpClient.get('/admin/network-vault'))

  return {
    dataNetworkVault: data,
    errorNetworkVault: error,
    loadingNetworkVault: loading,
    fetchNetworkVault: makeRequest,
  }
}
