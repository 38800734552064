import { useAuth } from '@/contexts'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useGetDonation = () => {
  const { userGroup } = useAuth()
  const { data, makeRequest, loading, error } = useApi(id =>
    httpClient.get(userGroup.isAdmin ? `/admin/donations/${id}` : `/partners/donations/${id}`)
  )

  return { data, makeRequest, loading, error }
}
