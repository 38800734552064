import { useEffect, useState } from 'react'
import { useGetPartnerAddress } from './useGetPartnerAddress'
import { usePartnerCheckoutForm } from './usePartnerCheckoutForm'
import { useAxios } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const usePartnerCheckout = ({ onClosePartnerModal, onSubmitDonation }) => {
  const [step, setStep] = useState(1)
  const [multiSelectOptions, setIsMultiSelectOptions] = useState([])

  const partnersForm = usePartnerCheckoutForm({
    step,
    onSubmit: values => {
      if (step === 5) {
        onClosePartnerModal()
        delete values.confirmPassword
        onSubmitDonation({ partnerValues: values })
        return
      }
      setStep(step + 1)
    },
  })
  const { cityOptions, loadingCity, loadingZipCode, ufOptions } = useGetPartnerAddress({ partnersForm })

  const { makeRequest: getMultiOptions, data: dataMultiOptions } = useAxios(() =>
    httpClient.get(`/partners/segments/options?label=name`)
  )

  const selectOptions = () => [
    {
      label: 'Pessoa Física',
      value: 'cpf',
    },
    {
      label: 'Pessoa Jurídica',
      value: 'cnpj',
    },
  ]

  useEffect(() => {
    const { values, setFieldValue } = partnersForm
    if (!values.typeCustomer) return
    setFieldValue('cpfCnpj', '')
  }, [partnersForm.values.typeCustomer])

  useEffect(() => {
    if (!dataMultiOptions) return
    setIsMultiSelectOptions(dataMultiOptions.data)
  }, [dataMultiOptions])

  return {
    getMultiOptions,
    cityOptions,
    loadingCity,
    loadingZipCode,
    ufOptions,
    selectOptions,
    multiSelectOptions,
    partnersForm,
    step,
    setStep,
    onClosePartnerModal,
  }
}
