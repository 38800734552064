import { VscCopy } from 'react-icons/vsc'
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import { twMerge } from 'tailwind-merge'

const ReferenceLink = ({ referenceCn, indicationUrl }) => {
  return (
    <>
      <CopyToClipboard
        text={indicationUrl}
        onCopy={() =>
          toast.success('Copiado com sucesso!', {
            position: 'top-center',
            autoClose: 2000,
          })
        }
      >
        <div
          className={twMerge(
            'flex p-4 rounded gap-2 items-center justify-center bg-gray-200 w-full text-center cursor-pointer',
            referenceCn
          )}
        >
          <div className='text-sm truncate'>{indicationUrl}</div>
          <div className='text-lg'>
            <VscCopy />
          </div>
        </div>
      </CopyToClipboard>
    </>
  )
}

export default ReferenceLink
