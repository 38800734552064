import React, { useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react'
import { VoucherStatus } from './VoucherStatus'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { DateAsText } from '@/components/Common'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { useFetchVoucher } from '@/pages/Voucher/hooks'
import { Icon } from '@iconify/react'
import { usePutRedeemVoucher } from '../hooks/usePutRedeemVoucher'
import { useAuth } from '@/contexts'

export const ModalVoucher = ({ isOpen, onClose, data, fetchDonation }) => {
  const { makeRequest: putRedeemVoucher } = usePutRedeemVoucher()
  const { userGroup } = useAuth()

  const handleValidate = () => {
    onClose()
    Swal.fire({
      title: 'Confirmar Validação?',
      text: 'A ação de validar Voucher é irreversível. Deseja realmente prosseguir?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Validar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#66BB6A',
      cancelButtonColor: '#F44336',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        await putRedeemVoucher(data.voucher.code)
          .then(() => {
            toast.success('Voucher validado!', {
              position: 'top-center',
              autoClose: 2000,
            })
            fetchDonation()
          })
          .catch(() => {
            toast.error('Ocorreu um erro ao validar voucher!', {
              position: 'top-center',
              autoClose: 2000,
            })
          })
          .finally(() => {
            return
          })
      },
    })
  }

  const { dataVoucher, fetchVoucher, loadingVoucher } = useFetchVoucher()

  useEffect(() => {
    if (!data?.voucher?.code) return

    fetchVoucher(data.voucher.code)
  }, [data])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Voucher Nº {data?.voucher?.code} <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          {loadingVoucher ? (
            <Icon className='animate-spin text-4xl text-primary-600 mx-auto' icon='mingcute:loading-fill' />
          ) : (
            <>
              <img src={dataVoucher?.voucher?.qrCode} alt='QrCode' className='w-full m-auto' />

              <div className='mt-3 flex flex-col gap-2 border-t pt-3'>
                <p className='text-lg'>
                  Doador: <span className='font-semibold'>{data?.donor.name}</span>
                </p>
                <div className='flex items-center gap-4'>
                  <p className='text-lg'>
                    Valor: <MoneyAsText value={data?.payment.finalAmount} className='font-semibold' />
                  </p>
                  <p className='text-lg'>
                    Data: <DateAsText date={data?.createdAt} dateClassName='font-semibold' />
                  </p>
                </div>
                <p className='text-lg'>
                  Status: <VoucherStatus status={data?.voucher?.isAvailable} />
                </p>
              </div>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          {data?.voucher?.isAvailable && userGroup.isPartner && (
            <div className='flex items-center justify-around w-full'>
              <Button colorScheme='green' onClick={() => handleValidate()} className='w-full'>
                Validar Voucher
              </Button>
            </div>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
