import { Icon } from '@iconify/react'

export const EmptyList = ({ message }) => {
  return (
    <div className='w-full bg-surface p-2 rounded shadow-lg min-h-[120px] flex justify-center items-center text-negative-dark font-medium text-lg'>
      <Icon className='mr-2 text-xl' icon='ic:outline-info' />
      <p>{message}</p>
    </div>
  )
}
