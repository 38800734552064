import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { useNewPromo } from '../hooks/useNewPromo'
import { TextField, TextareaField } from '@/components/Form/Fields'
import { twMerge } from 'tailwind-merge'
import { InputImage } from './InputImage'
import { Button } from '@/components/Form'

export const ModalNewPromo = ({ isOpen, onClose, onOpen }) => {
  const inputStyles = twMerge('rounded border-primary-blue/40 focus:border-primary bg-zinc-300/50 placeholder:text-zinc-800')
  const { formParams, bannerFile, loadingPostPromo, handleChangeBanner, handleCancel, step } = useNewPromo({ onClose, onOpen })
  const { handleSubmit, handleChange, values, errors } = formParams

  return (
    <Modal isOpen={isOpen} onClose={handleCancel} size='2xl' isCentered scrollBehavior='inside'>
      <ModalOverlay />

      <ModalContent as='form' onSubmit={handleSubmit} zIndex={20}>
        <ModalHeader>
          <h2 className='text-2xl'>{step === 1 ? 'Nova Promoção' : 'Pré-visualização:'}</h2>

          <ModalCloseButton />
        </ModalHeader>

        <ModalBody className='flex flex-col gap-4'>
          {step === 1 ? (
            <>
              <TextField
                label='Título'
                name='title'
                onChange={handleChange}
                value={values.title}
                errorMessage={errors.title}
                inputClassName={inputStyles}
                maxlength={40}
                noMargins
              />
              <div className={twMerge('flex justify-end items-center -mt-3 text-sm', errors.title && '-mt-9')}>
                Caracteres: {values.title.length}/40
              </div>

              <TextareaField
                label='Descrição'
                name='body'
                onChange={handleChange}
                value={values.body}
                errorMessage={errors.body}
                inputClassName={inputStyles}
                rows={8}
                maxlength={240}
              />
              <div className={twMerge('flex justify-end items-center -mt-3 text-sm', errors.body && '-mt-9')}>
                Caracteres: {values.body.length}/240
              </div>

              <InputImage fileValue={bannerFile} handleChangeImg={handleChangeBanner} error={errors.banner} />
            </>
          ) : (
            <div className='border-2 border-primary rounded-lg flex flex-col gap-6 p-4'>
              <a className='text-2xl text-primary underline cursor-pointer mx-auto break-all'>{values.title}</a>
              <img
                src={bannerFile.preview}
                className='rounded-lg max-h-[150px] object-contain mx-auto'
                alt='Imagem da promoção'
              />
              <p>{values.body}</p>
            </div>
          )}
        </ModalBody>

        <ModalFooter className='flex gap-3'>
          {step === 1 && (
            <Button type='button' onClick={handleCancel} className='bg-zinc-500 rounded'>
              Cancelar
            </Button>
          )}

          <Button type='submit' isDisabled={loadingPostPromo} isLoading={loadingPostPromo} className='rounded bg-primary'>
            {step === 1 ? 'Continuar' : 'Salvar'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
