import { DateAsText } from '@/components/Common'
import { Button } from '@/components/Form'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { toast } from 'react-toastify'
import { PartnerDetails } from '../../components/PartnerDetails'
import { useState } from 'react'

export const TableList = ({ dataPartnerDetails }) => {
  const formatCpf = (value = '') => {
    if (value.length === 11) {
      value = value.replace(/\D/g, '')
      value = value.replace(/(\d{3})(\d)/, '$1.$2')
      value = value.replace(/(\d{3})(\d)/, '$1.$2')
      value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2')

      return value
    }

    value = value.replace(/\D/g, '')
    value = value.replace(/^(\d{2})(\d)/, '$1.$2')
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    value = value.replace(/\.(\d{3})(\d)/, '.$1/$2')
    value = value.replace(/(\d{4})(\d)/, '$1-$2')
    return value
  }

  const handleCopyLink = code => {
    navigator.clipboard.writeText(`${import.meta.env.VITE_BASE_URL}/donate/${code}`)

    toast.success('Copiado com sucesso!', {
      position: 'top-center',
      autoClose: 2000,
      toastId: 'copied',
    })
  }

  const [partnerId, setPartnerId] = useState(null)
  const modalDetails = useDisclosure()

  const handlePartnerDetails = id => {
    setPartnerId(id)
    modalDetails.onOpen()
  }

  return (
    <>
      <TableContainer className='max-md:!hidden bg-surface shadow-xl rounded mt-4 overflow-hidden'>
        <Table size='md'>
          <Thead>
            <Tr>
              <Th>Nome</Th>
              <Th>E-mail</Th>
              <Th>Cpf/Cnpj</Th>
              <Th>Data de cadastro</Th>
              <Th>Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {dataPartnerDetails?.map(item => (
              <Tr key={item.id}>
                <Td className='max-w-[320px]'>
                  <Tooltip label={item.name}>
                    <div className='w-full flex items-center truncate'>{item.name}</div>
                  </Tooltip>
                </Td>

                <Td className='max-w-[360px]'>
                  <Tooltip label={item.email}>
                    <div className='w-full truncate'>{item.email}</div>
                  </Tooltip>
                </Td>

                <Td>{formatCpf(item.cpfCnpj)}</Td>

                <Td>
                  <DateAsText date={item.createdAt} />
                </Td>

                <Td className='flex justify-center'>
                  <Menu>
                    <MenuButton>
                      <Icon icon='simple-line-icons:options-vertical' />
                    </MenuButton>

                    <MenuList>
                      <MenuItem onClick={() => handlePartnerDetails(item.id)}>
                        <Icon className='text-2xl mr-1.5 text-primary-400' icon='mdi:file-search-outline' />
                        Ver detalhes
                      </MenuItem>
                      <MenuItem onClick={() => handleCopyLink(item.indicationCode || null)}>
                        <Icon icon='basil:copy-solid' className='text-2xl mr-1.5 text-primary-400' />
                        Copiar link
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <PartnerDetails isOpen={modalDetails.isOpen} onClose={modalDetails.onClose} partnerId={partnerId} />

      <div className='md:hidden flex flex-col gap-4'>
        {dataPartnerDetails?.map(item => (
          <div key={item.id} className='flex flex-col bg-surface rounded-lg w-full my-2 animate-fadeIn gap-2 text-text shadow-lg'>
            <div className='flex items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
              <span className='font-medium'>Nome</span>
              <div className='max-w-[80%] text-end truncate'>{item.name}</div>
            </div>
            <div className='flex items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
              <span className='font-medium whitespace-nowrap'>E-mail</span>
              <span className='truncate max-w-[75%]'>{item.email}</span>
            </div>
            <div className='flex items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
              <span className='font-medium'>CPF/CNPJ</span>
              <span>{formatCpf(item.cpfCnpj)}</span>
            </div>
            <div className='flex items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
              <span className='font-medium'>Data de cadastro</span>
              <DateAsText date={item.createdAt} />
            </div>

            <div className='flex items-center justify-end gap-4 p-3 w-full'>
              <Button
                text='Ver detalhes'
                className='px-4 py-2 bg-primary rounded w-full '
                onClick={() => handlePartnerDetails(item.id)}
              />
              <Button onClick={() => handleCopyLink(item.indicationCode)} className='px-4 py-2 bg-primary rounded w-full '>
                <Icon icon='basil:copy-solid' className='text-xl mr-1.5' />
                Copiar link
              </Button>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
