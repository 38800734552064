import React from 'react'
import { twMerge } from 'tailwind-merge'

export const VoucherStatus = ({ status, statusClassName }) => {
  let statusText = ''
  let color = ''

  switch (status) {
    case false:
      statusText = 'Utilizado'
      color = 'bg-warning-light'
      break
    case true:
      statusText = 'Ativo'
      color = 'bg-positive-light'
      break
    default:
      statusText = status
      color = 'bg-gray-500'
      break
  }

  return (
    <span
      className={twMerge(
        'text-center py-2 px-6 w-fit text-white font-medium font-Inter rounded-3xl text-sm',
        statusClassName,
        color
      )}
    >
      {statusText}
    </span>
  )
}
