import { EmptyList } from '@/components/Common/EmptyList'
import { ContainerSection, DonationCards } from '../../components'
import { useDonations } from './useDonations'

export const DonationHistory = () => {
  const { isLoadingData, data, onViewLinks } = useDonations()
  return (
    <ContainerSection title='Histórico de Doação'>
      {isLoadingData ? (
        <>
          <DonationCards isLoading />
        </>
      ) : (
        <>
          {data.donations?.map((item, index) => (
            <DonationCards key={index} onViewLinks={onViewLinks} donation={item} dataLinks={data.links} />
          ))}
        </>
      )}

      {!isLoadingData && (!data.donations || data.donations.length === 0) && <EmptyList message='Nenhuma doação encontrada!' />}
    </ContainerSection>
  )
}
