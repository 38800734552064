import { Icon } from '@iconify/react'
import { Button } from '../Form'
import { twMerge } from 'tailwind-merge'

export const Pagination = ({ pagination, onPageChange }) => {
  const getPageList = () => {
    let list = []
    for (let i = 1; i <= pagination.pagesCount; i++) {
      list.push(i)
    }

    return list
  }
  return (
    <div className='flex justify-between md:justify-end items-center gap-4 bg-surface shadow-md p-2 rounded-md'>
      <p className='min-w-28'>
        Exibindo <span>{pagination.recordsCount}</span> de <span>{pagination.count}</span>
      </p>

      <div className='flex gap-2 items-start overflow-hidden'>
        {pagination.current > 1 && (
          <Button
            onClick={() => onPageChange({ page: pagination.current - 1 })}
            className='bg-transparent border border-primary-700 p-1 rounded-md'
          >
            <Icon icon='mingcute:left-fill' className='text-primary-700 text-2xl' />
          </Button>
        )}

        <div className='w-full flex items-center gap-2 overflow-auto scroll-pb-0 noScrollBar'>
          {getPageList().map(item => (
            <button
              key={item}
              onClick={() => onPageChange({ page: item })}
              disabled={item === pagination.current}
              className={twMerge(
                'text-primary-800 bg-transparent  ring-1 ring-inset ring-primary-700 aspect-square h-8 rounded-md font-semibold',
                pagination.current === item ? 'bg-primary-400 text-white ring-primary-400' : 'hover:bg-primary-200/25'
              )}
            >
              {item}
            </button>
          ))}
        </div>

        {pagination.pagesCount > pagination.current && (
          <Button
            onClick={() => onPageChange({ page: pagination.current + 1 })}
            className='bg-transparent hover:bg-primary-200/25 border border-primary-700 p-1 rounded-md'
          >
            <Icon icon='mingcute:right-fill' className='text-primary-700 text-2xl' />
          </Button>
        )}
      </div>
    </div>
  )
}
