import { Button } from '@/components/Form'
import { Icon } from '@iconify/react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import { useAnalysis } from './hooks'
import { ErrorFetch } from '../Donation/components'

export const PaymentScreenAnalysis = () => {
  const { dataPartner, dataStatus, isLoading, indication_code, order_id, isDonationAppwins } = useAnalysis()

  if (isLoading && !dataStatus)
    return (
      <div className='w-[90%] mx-auto max-w-xs flex justify-center items-center shadow-xl bg-surface rounded p-3'>
        <Icon icon='mingcute:loading-fill' className='size-16 animate-spin text-primary' />
      </div>
    )

  if (!dataStatus) return <ErrorFetch />

  if (dataStatus)
    return (
      <>
        <div className='py-4 px-6 flex flex-col lg:max-w-[34.375rem] lg:mx-auto'>
          <div className='w-full bg-white p-2 mb-7 relative rounded-lg shadow-lg'>
            <img className='w-full' src='/images/donationBanner.png' alt='Banner de doação' />
          </div>
          <div className='bg-white rounded-lg shadow-lg p-4 gap-2 mb-7'>
            <div className='flex flex-col items-center py-3 mb-10'>
              <img src='/images/iconAmpulheta.gif' alt='Ampulheta' className='w-16 pt-14 pb-2' />
              <h5 className='text-xl font-semibold text-center mb-2'>Processando doação...</h5>
              <span className='flex text-center text-sm font-medium'>
                Sua doação está em processo de análise. Assim que for concluída, enviaremos um e-mail com o voucher da sua
                contribuição.
              </span>
            </div>
            {!isDonationAppwins && dataPartner && (
              <div className='flex flex-col gap-1 items-start'>
                <span className='text-xs font-medium'>Loja parceira:</span>
                <span className='bg-secondary-600 text-slate-50 text-xs p-[2px] px-2 rounded w-'>{dataPartner.name}</span>
              </div>
            )}
          </div>

          <CopyToClipboard
            text={`${import.meta.env.VITE_BASE_URL}/process/${order_id}/${indication_code}`}
            onCopy={() =>
              toast.success('Copiado com sucesso!', {
                position: 'top-center',
                autoClose: 2000,
                toastId: 'copied',
              })
            }
          >
            <Button className='py-3 px-5 w-full text-sm font-medium bg-primary hover:bg-primary-600 rounded'>
              <Icon icon='solar:copy-bold' />
              Copiar link do pedido
            </Button>
          </CopyToClipboard>
        </div>
      </>
    )
}
