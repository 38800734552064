import { LoadingData } from '@/components/Common/LoadingData'
import { Card } from './components/Card'
import { FlowLine } from './components/FlowLines'
import { UserProfile } from '@/components/Common/UserProfile'
import { usePageNetwork } from './hook/usePageNetwork'
import { Filters } from './components/Filters'
import { Empty } from './components/Empty'

export const NetworkMap = () => {
  const { onExpandNetwork, handleClickDonation, filterParams, getNetwork, isLoading, handleResetFilter, activeFilters } =
    usePageNetwork()
  const { dataNetwork } = getNetwork

  const leftLeg = dataNetwork && dataNetwork.children?.find(item => item.leg === 'L')
  const rightLeg = dataNetwork && dataNetwork.children?.find(item => item.leg === 'R')

  return (
    <>
      <UserProfile className='max-w-none' />

      <div className='flex flex-col gap-3 p-4'>
        <h2 className='text-2xl font-bold text-primary'>Mapa da rede</h2>

        <Filters handleResetFilter={handleResetFilter} activeFilters={activeFilters} filterParams={filterParams} />

        <div className='mt-6'>
          {!isLoading && !dataNetwork && (
            <div>
              <div className='mr-auto max-[425px]:w-full min-[425px]:min-w-[380px] mb-6 top-0 left-0'>
                <Card name={null} onExpand={() => onExpandNetwork(null)} level={0} isBackToTop title='Wins' isMain />
              </div>

              <Empty message='Nenhuma rede foi encontrada!' />
            </div>
          )}

          {isLoading ? (
            <LoadingData />
          ) : (
            dataNetwork && (
              <div className='w-full flex flex-col items-center mb-8 relative'>
                {dataNetwork.level >= 1 && (
                  <div className='mr-auto max-[425px]:w-full min-[425px]:min-w-[380px] xl:absolute mb-6 top-0 left-0'>
                    <Card name={null} onExpand={() => onExpandNetwork(null)} level={0} isBackToTop title='Wins' isMain />
                  </div>
                )}

                <Card
                  name={
                    dataNetwork.level === '-'
                      ? null
                      : dataNetwork.children.length > 0
                      ? dataNetwork.children[0].upMember.name
                      : dataNetwork.upMember.children[0].name
                  }
                  isIndicate={dataNetwork.level !== '-'}
                  onExpand={() => {
                    onExpandNetwork(dataNetwork.level > 1 ? dataNetwork.upMember.linkCode : null)
                  }}
                  level={dataNetwork.level === '-' ? 0 : dataNetwork.level}
                  isMain={dataNetwork.level === '-'}
                  title={dataNetwork.level === '-' ? 'Wins' : 'Indicado'}
                />

                {dataNetwork.level === '-' ? (
                  dataNetwork.children.length > 0 && (
                    <FlowLine
                      type={dataNetwork.children.length === 2 ? 'split' : dataNetwork.children.length === 1 ? 'left' : 'down'}
                    />
                  )
                ) : (
                  <>
                    {dataNetwork.children.length > 0 && <FlowLine type={dataNetwork.children.length === 2 ? 'split' : 'left'} />}
                  </>
                )}

                {(leftLeg || rightLeg) && (
                  <div className='w-full grid lg:grid-cols-2 items-center gap-9 max-lg:border border-zinc-800 max-lg:bg-surface max-lg:p-6 rounded-lg'>
                    {leftLeg ? (
                      <div className='mx-auto max-lg:w-full self-start'>
                        <Card
                          leg='L'
                          onExpand={leftLeg.children?.length > 0 ? () => onExpandNetwork(leftLeg.linkCode) : null}
                          level={leftLeg.level}
                          name={leftLeg.name}
                          indicationCode={leftLeg.linkCode}
                          title={leftLeg.isPartner ? 'Parceiro' : 'Doador'}
                          handleClickDonation={leftLeg.donation ? () => handleClickDonation(leftLeg.donation.id) : null}
                        />
                      </div>
                    ) : (
                      <div />
                    )}

                    {rightLeg ? (
                      <div className='mx-auto max-lg:w-full self-start'>
                        <Card
                          leg='R'
                          onExpand={rightLeg.children?.length > 0 ? () => onExpandNetwork(rightLeg.linkCode) : null}
                          level={rightLeg.level}
                          name={rightLeg.name}
                          indicationCode={rightLeg.linkCode}
                          title={rightLeg.isPartner ? 'Parceiro' : 'Doador'}
                          handleClickDonation={rightLeg.donation ? () => handleClickDonation(rightLeg.donation.id) : null}
                        />
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                )}
              </div>
            )
          )}
        </div>
      </div>
    </>
  )
}
