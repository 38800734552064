import ReferenceLink from '@/components/Common/ReferenceLink'
import { Button } from '@/components/Form'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Tooltip,
} from '@chakra-ui/react'
import { Icon } from '@iconify/react'

export const LinksModal = ({ data = [], isOpen, onClose, partnerUrl }) => {
  const getIndicationUrl = code => {
    return `${import.meta.env.VITE_BASE_URL}/donate/${partnerUrl}/${code}`
  }

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Meus Links</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <div className='flex flex-col gap-4'>
            {data?.length === 0 || !data ? (
              <div className='flex flex-col items-center gap-3'>
                <Icon icon='carbon:folder-off' className='size-11 text-negative-dark' />
                <h5 className='text-lg font-medium'>Nenhum link encontrado!</h5>
              </div>
            ) : (
              data.map((item, ind) => (
                <div key={ind} className='flex flex-col gap-1'>
                  <label className='font-Inter'>Link {ind + 1}:</label>
                  <Tooltip label={getIndicationUrl(item.link)}>
                    <ReferenceLink indicationUrl={getIndicationUrl(item.link)} referenceCn='p-2 px-3' />
                  </Tooltip>
                </div>
              ))
            )}
          </div>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} className='p-2 px-4 rounded'>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
