import MoneyAsText from '@/components/Common/MoneyAsText'
import { Icon } from '@iconify/react'
import React from 'react'
import { Skeleton } from '@chakra-ui/react'

export const ExtractCard = ({ pointsConversion, isLoading, value, title, details, hideBalance }) => {
  return (
    <div className='flex flex-col justify-between gap-2 p-6 bg-surface rounded-lg shadow-lg'>
      <div className='flex items-center gap-1 flex-wrap'>
        <Icon icon='ri:coins-line' fontSize={24} />
        <p className='text-xl font-semibold'>{title}</p>

        <span className='text-sm font-medium w-full capitalize'>{details?.mouth}</span>
      </div>

      {isLoading ? (
        <Skeleton height='18px' className='w-1/2 ml-auto' />
      ) : (
        <>
          <div className='text-end text-xl flex flex-col gap-1 my-auto'>
            {!hideBalance && (
              <>
                {pointsConversion ? (
                  <p className='text-end'>{value} pts</p>
                ) : (
                  <MoneyAsText prefix='R$ ' value={value} className='text-xl text-end' />
                )}
              </>
            )}

            {pointsConversion && (
              <span className='text-xs'>
                1pt = <MoneyAsText prefix='R$ ' value={pointsConversion} />
              </span>
            )}
          </div>

          {details?.period && (
            <div className='flex flex-col mt-auto pt-2'>
              {details.period.total !== null && (
                <div className='flex justify-between items-center py-2 border-b border-primary-400/40 hover:bg-gray-500/5 transition'>
                  <p className='text-xl font-medium'>Total:</p>
                  <span className='text-xl font-medium'>
                    <MoneyAsText value={details.period.total} />
                  </span>
                </div>
              )}

              <div className='flex justify-between items-center py-2 border-b border-primary-400/40 hover:bg-gray-500/5 transition'>
                <p className='font-medium'>Dia:</p>
                <span>
                  <MoneyAsText value={details.period.day} />
                </span>
              </div>

              <div className='flex justify-between items-center py-2 border-b border-primary-400/40 hover:bg-gray-500/5 transition'>
                <p className='font-medium'>Semana:</p>
                <span>
                  <MoneyAsText value={details.period.week} />
                </span>
              </div>

              <div className='flex justify-between items-center py-2 border-b border-primary-400/40 hover:bg-gray-500/5 transition'>
                <p className='font-medium'>Mês:</p>
                <span>
                  <MoneyAsText value={details.period.mouth} />
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}
