export const formatUsDecimalToFloat = (stringValue, precision = 2) => {
  return parseFloat(parseFloat(String(stringValue).replace(',', '')).toFixed(precision))
}

export const formatBrDecimalToFloat = (stringValue, precision = 2) => {
  return parseFloat(parseFloat(String(stringValue).replace(',', '.')).toFixed(precision))
}

export const formatPhoneString = (stringValue = '') => {
  return `(${stringValue.slice(0, 2)}) ${stringValue.slice(2, 7)}-${stringValue.slice(7)}`
}

export const formatCpfCnpj = cpfCnpj => {
  if (cpfCnpj.length === 11) {
    return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }
  return cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}
