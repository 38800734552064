import { Button } from '@/components/Form'
import LogoIcon from '@/assets/images/logoTransparent.svg'
import PhoneComputer from '@/assets/images/phoneComputer.png'
import { Link, useNavigate } from 'react-router-dom'

export const Hero = () => {
  const navigate = useNavigate()

  const handleNavigate = path => {
    navigate(path)
  }

  return (
    <section className='bg-building-section pt-12 pb-4 w-full flex flex-col justify-between h-screen bg-no-repeat bg-cover bg-center lg:pb-16 lg:h-[120vh] lg:bg-building-section-desktop lg:bg-top'>
      <div className='w-full px-6 flex justify-between items-center lg:px-14'>
        <div className='flex gap-1 items-center'>
          <img src={LogoIcon} alt='Logo Wins' className='w-7 lg:w-20' />
          <p className='font-semibold text-lg text-white lg:text-5xl'>Win$</p>
        </div>
        <div className='lg:flex lg:items-center lg:gap-5'>
          <Button
            onClick={() => handleNavigate('/donate/FXK1234567')}
            className='hidden bg-transparent font-semibold w-fit border-none text-base lg:block'
          >
            Tornar-se parceiro
          </Button>
          <Link
            target='_blank'
            to='https://wa.me/558588496851'
            className='bg-transparent rounded-lg transition ease-in-out duration-300 text-base delay-50 hover:opacity-80 text-white px-8 border-2 font-semibold border-white w-36 py-3 lg:border-none lg:w-fit'
          >
            Contatar
          </Link>
          <Button
            onClick={() => handleNavigate('/login')}
            className='hidden bg-transparent border-2 font-semibold border-white w-40 py-3 lg:block lg:hover:bg-white lg:hover:text-black'
          >
            Entrar
          </Button>
        </div>
      </div>
      <div className='h-4/5 flex flex-col justify-between lg:items-center lg:flex-row lg:h-full'>
        <h1 className='px-6 font-bold text-[#F5F5F5] xl:px-12 xl:min-w-[900px] xl:pr-0'>
          <span className='block text-2.5xl lg:text-7.5xl lg:leading-tight'>Converta</span>
          <span className='block text-base lg:text-5xl lg:leading-none'>Suas Compras em Doações</span>
          <span className='block text-2.5xl lg:text-7.5xl lg:leading-tight'>Receba Recompensas</span>
          <span className='block text-base lg:text-5xl lg:leading-none'>ao Indicar Amigos!</span>
        </h1>
        <div className='relative w-full flex justify-end lg:items-end lg:h-full lg:w-[46%] lg:pb-6'>
          <div className='h-44 w-full max-w-72 rounded-es-full rounded-s-full bg-white lg:max-w-4xl lg:h-[430px]' />
          <img
            className='absolute w-60 bottom-7 lg:bottom-20 lg:w-[690px]'
            src={PhoneComputer}
            alt='Imagem computador e telefone'
          />
        </div>
        <div className='w-full flex gap-4 flex-col px-10 lg:hidden'>
          <Button
            onClick={() => handleNavigate('/donate/FXK1234567')}
            className='w-full bg-transparent border-2 font-semibold border-white py-2'
          >
            Tornar-se parceiro
          </Button>
          <Button onClick={() => handleNavigate('/login')} className='w-full'>
            Entrar
          </Button>
        </div>
      </div>
    </section>
  )
}
