import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export function useGetPartnerTableList() {
  const { data, error, loading, meta, makeRequest } = useApi(params => httpClient.get(`/admin/partners`, { params }))

  return {
    dataPartnerDetails: data,
    metaPartnerDetails: meta,
    errorPartnerDetails: error,
    loadingPartnerDetails: loading,
    fetchPartnerDetails: makeRequest,
  }
}
