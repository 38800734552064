import Financial from '@/assets/images/finance.png'
import Relationships from '@/assets/images/relationships.png'
import { useEffect, useState } from 'react'

export const About = () => {
  const [hovered, setHovered] = useState(false)

  const handleTouchStart = () => {
    setHovered(true)
  }

  const handleTouchEnd = () => {
    setHovered(false)
  }

  const handleScroll = () => {
    const isMobile = window.innerWidth < 1025
    if (isMobile) {
      const currentPosition = window.scrollY

      const heightToTriggerStart = 165
      const heightToTriggerEnd = 1780

      if (currentPosition >= heightToTriggerStart) {
        handleTouchStart()
      }

      if (currentPosition >= heightToTriggerEnd || currentPosition < heightToTriggerStart) {
        handleTouchEnd()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <section
      onMouseEnter={handleTouchStart}
      onMouseLeave={handleTouchEnd}
      className='pt-28 pb-14 bg-[#F4EDE4] flex flex-col gap-24 lg:pb-28 lg:pt-36 overflow-hidden'
    >
      <div className='flex flex-col gap-12 md:gap-2 md:flex-row md:items-center'>
        <div className='w-full'>
          <div className={`relative flex justify-start`}>
            <div
              className={`h-48 ${
                hovered ? 'w-full' : 'w-56'
              } max-w-[90%] rounded-e-full bg-[#A64115] lg:h-[380px] lg:max-w-[700px] duration-[1.5s] ease-in-out`}
            />
            <div
              className={`absolute left-2 -bottom-2.5 lg:-bottom-6 lg:left-5 transition-all duration-1000 ease-in-out ${
                hovered ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'
              }`}
              style={{ transition: 'transform 1.5s, opacity 1s' }}
            >
              <img className='w-64 lg:size-[540px]' src={Financial} alt='Imagem computador e telefone' />
            </div>
          </div>
        </div>

        <div className='px-10 flex flex-col gap-6 lg:w-full lg:justify-start'>
          <h2 className='font-bold text-0.5xl text-[#252525] lg:text-5xl lg:max-w-3xl'>
            Faça doação e ganhe cashback e descontos
          </h2>
          <p className='font-medium text-justify text-[#414141] lg:text-0.5xl lg:max-w-3xl'>
            A <span className='font-semibold lg:text-2xl'>Wins</span> incentiva os usuários a doarem para instituições de
            caridade, oferecendo em troca uma parte do valor de volta e descontos exclusivos em diversas lojas parceiras. Faça o
            bem e ainda economize!
          </p>
        </div>
      </div>

      <div className='flex flex-col gap-12 md:gap-2 md:flex-row-reverse md:items-center'>
        <div className='w-full'>
          <div className={`relative flex justify-end w-full`}>
            <div
              className={`h-48 ${
                hovered ? 'w-full' : 'w-56'
              } duration-[1.5s] ease-in-out max-w-[90%] rounded-es-full rounded-s-full bg-[#357095] lg:h-[380px] lg:max-w-[700px]`}
            />
            <div
              className={`absolute -bottom-6 right-2 lg:-bottom-12 lg:right-3 transition-all duration-1000 ease-in-out ${
                hovered ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'
              }`}
              style={{ transition: 'transform 1.5s, opacity 1s' }}
            >
              <img className='w-64 lg:size-[500px]' src={Relationships} alt='Imagem computador e telefone' />
            </div>
          </div>
        </div>

        <div className='px-10 flex flex-col gap-6 lg:w-full lg:justify-end'>
          <h2 className='font-bold text-0.5xl text-[#252525] lg:text-5xl lg:max-w-3xl'>Indique amigos e ganhe</h2>
          <p className='font-medium text-justify text-[#414141] lg:text-0.5xl lg:max-w-3xl'>
            A <span className='font-semibold lg:text-2xl'>Wins</span> oferece benefícios aos usuários que recomendam amigos para
            se inscreverem. Ao trazer novos membros, os indicados recebem recompensas, como créditos em cashback e descontos
            exclusivos.
          </p>
          <p className='font-medium text-justify text-[#414141] lg:text-0.5xl lg:max-w-3xl'>
            Dessa maneira, compartilhar a plataforma com amigos se torna ainda mais vantajoso e lucrativo para todos.
          </p>
        </div>
      </div>
    </section>
  )
}
