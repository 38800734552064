import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import { usePostEmailVerify } from './usePostEmailVerify'
import { useNavigate } from 'react-router-dom'

export const useFormVerifySignIn = () => {
  const { t } = useTranslation()
  const { requestVerify } = usePostEmailVerify()
  const navigate = useNavigate()

  const schema = Yup.object({
    email: Yup.string().email(t('global.validation.invalidEmail')).required(t('global.validation.required')),
  })

  const onSubmit = async values => {
    await requestVerify(values.email)
      .then(() => {
        Swal.fire({
          title: 'E-mail verificado com sucesso!',
          text: 'Enviamos um e-mail para você definir sua senha e ter acesso a nossa plataforma!',
          icon: 'success',
          confirmButtonText: 'Ok',
        })
      })
      .catch(error => {
        if (error.response.data.data.error === 'userAlreadyVerified') {
          navigate(`/login?email=${formParams.values.email}`)
          return
        }
        Swal.fire({
          title: 'Seu cadastro não foi encontrado no sistema!',
          icon: 'error',
          confirmButtonText: 'Ok',
          html: `<p class='text-negative-dark text-base font-Inter'>Por favor, tente um e-mail diferente ou verifique se sua doação foi aprovada!</p>`,
        })
        return
      })
  }

  const formParams = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
  })

  return { formParams }
}
