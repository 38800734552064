import { DateAsText } from '@/components/Common'
import { Button } from '@/components/Form'
import { Table, TableContainer, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { PromoBadge } from './PromoBadge'
import { useAuth } from '@/contexts'
import { APPROVED } from '../constants'
import { isFuture } from 'date-fns'

export const PromoTable = ({ promoData, onOpenPromoDetails }) => {
  const { userGroup, user } = useAuth()

  const getDateCol = (status, publishedAt) => {
    if (status === APPROVED) {
      const sendingTime = {
        hours: user.settings.promotionSendingTime.split(':')[0],
        min: user.settings.promotionSendingTime.split(':')[1],
      }
      const todayPublishedTime = new Date().setHours(sendingTime.hours, sendingTime.min)

      return isFuture(todayPublishedTime) ? `Hoje as ${sendingTime.hours} h` : `Amanhã as ${sendingTime.hours} h`
    }

    if (publishedAt) return <DateAsText date={publishedAt} />

    return '-'
  }

  return (
    <>
      <TableContainer className='max-lg:!hidden bg-surface shadow-xl rounded mt-4'>
        <Table size='md'>
          <Thead>
            <Tr>
              {userGroup.isAdmin && <Th textAlign='start'>Parceiro</Th>}
              <Th>Título</Th>
              <Th textAlign='center'>Data de criação</Th>
              <Th textAlign='center'>Status</Th>
              <Th textAlign='center'>Data de publicação</Th>
              <Th textAlign='center'>Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {promoData?.map(item => (
              <Tr key={item.id}>
                {userGroup.isAdmin && (
                  <Td textAlign='start'>
                    <Tooltip label={item.partner.name}>
                      <p className='truncate max-w-60 md:max-w-20 lg:max-w-[200px] xl:max-w-[360px]'>
                        {userGroup.isAdmin && item.partner.name}
                      </p>
                    </Tooltip>
                  </Td>
                )}

                <Td>
                  <Tooltip label={item.title}>
                    <p className='truncate max-w-60 md:max-w-20 lg:max-w-[280px] xl:max-w-[380px]'>{item.title}</p>
                  </Tooltip>
                </Td>

                <Td textAlign='center'>
                  <DateAsText date={item.createdAt} />
                </Td>

                <Td textAlign='center'>
                  <PromoBadge status={item.status} />
                </Td>

                <Td textAlign='center'>{getDateCol(item.status, item.publishedAt)}</Td>

                <Td className='max-w-[360px]'>
                  <div className='w-full flex justify-center items-center gap-2'>
                    <Tooltip label='Visualizar'>
                      <div onClick={() => onOpenPromoDetails(item.id)}>
                        <Button className='p-2 bg-primary-800/10 text-text border-primary-600 rounded-lg gap-1'>
                          <Icon className='text-lg' icon='mdi:eye-outline' />
                        </Button>
                      </div>
                    </Tooltip>
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <div className='lg:hidden flex flex-col gap-4'>
        {promoData?.map(item => (
          <div key={item.id} className='flex flex-col bg-surface rounded-lg w-full my-2 animate-fadeIn gap-2 text-text shadow-lg'>
            {userGroup.isAdmin && (
              <div className='flex flex-wrap items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
                <span className='font-medium'>Nome do Parceiro</span>
                <p className='ml-auto'>{item.partner.name}</p>
              </div>
            )}
            <div className='flex flex-wrap items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
              <span className='font-medium'>Título</span>
              <p className='text-end truncate'>{item.title}</p>
            </div>

            <div className='flex flex-wrap items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
              <span className='font-medium'>Data de criação</span>
              <DateAsText date={item.createdAt} />
            </div>

            <div className='flex flex-wrap items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
              <span className='font-medium'>Status</span>
              <PromoBadge status={item.status} />
            </div>

            <div className='flex flex-wrap items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
              <span className='font-medium'>Data de publicação</span>
              {getDateCol(item.status, item.publishedAt)}
            </div>

            <div className='flex items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
              <Button className='w-full' onClick={() => onOpenPromoDetails(item.id)}>
                Ver detalhes
              </Button>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
