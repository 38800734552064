import { Button } from '@/components/Form'
import { PasswordField } from '@/components/Form/Fields'

import { useSetPassword } from './hooks/useSetPassword'

export const SetPassword = () => {
  const { formParams, email } = useSetPassword()
  const { errors, handleChange, handleSubmit, values, loading } = formParams

  return (
    <form
      onSubmit={handleSubmit}
      className='bg-zinc-100 w-[94%] flex flex-col justify-center items-center gap-4 p-4 py-8 rounded-lg shadow-lg animate-fadeIn md:w-[480px] m-auto'
    >
      <div className='w-full'>
        <h3 className='font-bold text-tertiary-600 text-xl'>Ativação da conta</h3>
        <p className='text-justify'>Por favor, defina sua senha para acessar sua conta dentro da plataforma!</p>
      </div>
      <div className='w-full flex flex-col items-start'>
        <label className='font-semibold text-sm'>E-mail</label>
        <p className='text-zinc-500 cursor-default'>{email}</p>
      </div>
      <PasswordField
        className='font-medium w-full'
        label='Defina sua senha'
        value={values.password}
        errorMessage={errors.password}
        onChange={e => {
          handleChange('password')(e.target.value)
        }}
        isDisabled={loading}
        placeholder='******'
      />
      <PasswordField
        className='font-medium w-full'
        label='Confirme a senha'
        value={values.confirmPassword}
        errorMessage={errors.confirmPassword}
        onChange={e => {
          handleChange('confirmPassword')(e.target.value)
        }}
        isDisabled={loading}
        placeholder='******'
      />
      <Button
        type='submit'
        className='font-normal w-full text-white bg-sky-800'
        isLoading={formParams.isSubmitting}
        isDisabled={formParams.isSubmitting}
      >
        Continuar
      </Button>
    </form>
  )
}
