import { DateAsText } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import ReferenceLink from '@/components/Common/ReferenceLink'
import { StatusAsText } from '@/components/Common/StatusAsText'
import { Button } from '@/components/Form'
import React from 'react'
import { Skeleton } from '@chakra-ui/react'
import { LinksModal } from '../LinksModal'
import { useDonationCard } from './useDonationCard'

export const DonationCards = ({ onViewLinks, donation, isLoading, dataLinks }) => {
  const buttonCn = 'px-4 py-2 bg-primary rounded w-full'
  const { donationValue, getDate, handleLinks, handleVoucher, indicationUrl, isOpen, onClose } = useDonationCard({
    donation,
    onViewLinks,
  })

  return (
    <main className='flex flex-col bg-surface rounded-lg shadow-lg w-full my-2 animate-fadeIn gap-2'>
      <LinksModal isOpen={isOpen} onClose={onClose} data={dataLinks} partnerUrl={donation?.partner.url} />

      <header className='flex items-center justify-between w-full text-text p-4 rounded-t-lg border-b border-primary-400'>
        {isLoading ? (
          <Skeleton height={4} startColor='gray.600' endColor='gray.100' className='w-[50%] my-2' />
        ) : (
          <p className='text-lg font-semibold'>{donation?.partner.name}</p>
        )}

        {!isLoading && <StatusAsText status={donation?.payment.status || 1} statusClassName='px-3 py-1' />}
      </header>

      <div className='flex flex-col gap-3 px-4 py-2.5'>
        <p className='flex items-center justify-between pb-1 border-b border-primary-200'>
          Cotas:
          {isLoading ? (
            <Skeleton height={4} startColor='gray.600' endColor='gray.100' className='w-12 my-2' />
          ) : (
            <div className='flex items-center gap-1'>
              <span className='font-semibold'>{donation?.payment.quantity}x</span>
              <MoneyAsText className='font-semibold' prefix='R$ ' value={donation?.payment.unitAmount} />
            </div>
          )}
        </p>

        <p className='flex items-center justify-between pb-1 border-b border-primary-200'>
          Valor da Doação:
          {isLoading ? (
            <Skeleton height={4} startColor='gray.600' endColor='gray.100' className='w-24 my-2' />
          ) : (
            <MoneyAsText className='font-semibold' prefix='R$ ' value={donationValue} />
          )}
        </p>

        <p className='flex items-center justify-between pb-1 border-b border-primary-200'>
          Data:
          {isLoading ? (
            <Skeleton height={4} startColor='gray.600' endColor='gray.100' className='w-24 my-2' />
          ) : (
            <DateAsText date={getDate()} type='date' dateClassName='font-semibold' />
          )}
        </p>

        <p className='flex items-center justify-between pb-1 border-b border-primary-200'>
          Código:
          {isLoading ? (
            <Skeleton height={4} startColor='gray.600' endColor='gray.100' className='w-24 my-2' />
          ) : (
            <span className='font-semibold text-sm'>{donation.number}</span>
          )}
        </p>

        <div className='flex flex-col gap-2'>
          <p>Link da loja: </p>
          {!isLoading && indicationUrl && <ReferenceLink indicationUrl={indicationUrl} referenceCn='px-2 py-1' />}
        </div>
      </div>

      {donation?.payment.status === 2 && (
        <footer className='flex items-center justify-end gap-4 pb-2.5 px-4 w-full'>
          <Button onClick={handleVoucher} text='Ver Voucher' className={buttonCn} />
          <Button onClick={handleLinks} text='Meus Links' className={buttonCn} />
        </footer>
      )}
    </main>
  )
}
