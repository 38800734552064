import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useFavoritePartner = () => {
  const { data, error, loading, makeRequest } = useApi((id, donorId) =>
    httpClient.post(`donations/partners/${id}/favorite`, { donorId })
  )

  return { dataFavorite: data, errorFavorite: error, loadingFavorite: loading, postFavorite: makeRequest }
}
