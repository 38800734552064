import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export function useGetDonations() {
  const { data, error, loading, makeRequest } = useApi(() => httpClient.get(`/donations/my-donations`))

  return {
    dataDonations: data,
    errorDonations: error,
    loadingDonations: loading,
    fetchDonations: makeRequest,
  }
}
