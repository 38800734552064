import YouTubeIcon from '@/assets/images/youtubeIcon.svg'
import InstaIcon from '@/assets/images/instaIcon.svg'
import WppIcon from '@/assets/images/wppIcon.svg'
import FaceIcon from '@/assets/images/faceIcon.svg'
import { Link } from 'react-router-dom'
import LogoIcon from '@/assets/images/logoTransparent.svg'

const LinkImg = ({ link, src, alt }) => {
  return (
    <Link target='_blank' to={link}>
      <img className='lg:w-10' src={src} alt={alt} />
    </Link>
  )
}

export const Footer = () => {
  return (
    <footer className='py-8 bg-white px-10 flex flex-col gap-8 lg:gap-24 lg:px-28 lg:py-16'>
      <div className='flex flex-col gap-8 lg:flex-row lg:gap-48 lg:justify-center lg:items-center'>
        <div className='flex justify-between items-center lg:justify-normal lg:flex-row-reverse gap-14'>
          <div className='flex flex-col gap-4 lg:gap-6'>
            <h3 className='text-2xl font-semibold lg:text-4xl'>Links úteis</h3>
            <div className='flex flex-col gap-2 text-xs lg:gap-6'>
              <Link to='https://impostometro.com.br/' className='underline lg:text-0.5xl'>
                Impostômetro
              </Link>
              <Link to='https://www.gov.br/pt-br' className='underline lg:text-0.5xl'>
                gov.br
              </Link>
              <Link to='https://www.detran.ce.gov.br/' className='underline lg:text-0.5xl'>
                Detran
              </Link>
            </div>
          </div>
          <img className='w-16 lg:w-32' src={LogoIcon} alt='Logo' />
        </div>
        <div className='border-b border-[#D9D9D9] lg:border-r lg:border-b-0 lg:min-h-40' />
        <div className='flex flex-col gap-6 items-center'>
          <div className='flex gap-x-10 gap-y-5 px-8 justify-center flex-wrap lg:justify-start lg:items-center'>
            <LinkImg link='https://www.youtube.com/@AppWins' src={YouTubeIcon} alt='Ícone YouTube' />
            <LinkImg link='https://www.instagram.com/appwins/' src={InstaIcon} alt='Ícone Instagram' />
            <LinkImg link='https://wa.me/558588496851' src={WppIcon} alt='Ícone WhatsApp' />
            <LinkImg link='https://www.instagram.com/appwins/' src={FaceIcon} alt='Ícone FaceBook' />
          </div>
        </div>
      </div>
      <Link
        target='_blank'
        to='https://wa.me/558588496851'
        className='w-full py-3 px-5 rounded-lg flex gap-2 items-center justify-center bg-transparent text-[#633B89] border border-[#633B89] lg:max-w-2xl lg:mx-auto'
      >
        Entre em contato conosco
      </Link>
    </footer>
  )
}
