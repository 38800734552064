import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export function useGetPartnerCashback() {
  const { data, error, loading, meta, makeRequest } = useApi(params =>
    httpClient.get(`/admin/partners/options?label=name`, { params })
  )

  return {
    dataPartnerCashback: data,
    metaePartnerCashback: meta,
    errorPartnerCashback: error,
    loadingPartnerCashback: loading,
    fetchPartnerCashback: makeRequest,
  }
}
