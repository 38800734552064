import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export function useGetBalance() {
  const { data, error, loading, makeRequest } = useApi(() => httpClient.get('/financial/balance'))

  return {
    dataBalance: data,
    errorBalance: error,
    loadingBalance: loading,
    fetchBalance: makeRequest,
  }
}
