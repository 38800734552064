import React from 'react'
import PropTypes from 'prop-types'
import { DateInput, Label } from '@/components/Form'
import { FieldMessage } from '@/components/Form/Fields/FieldMessage'
import { twMerge } from 'tailwind-merge'

export const DateField = ({
  name,
  onChange,
  type,
  label,
  noMargins,
  value,
  isDisabled,
  errorMessage,
  inputClassName,
  className,
  id,
  ...rest
}) => {
  const labelClasses = errorMessage !== '' ? 'text-negative' : ''

  return (
    <div className={twMerge(!noMargins && 'mb-3', className)}>
      <div className='flex justify-between items-center'>
        <Label
          className={twMerge('text-sm font-medium', labelClasses)}
          text={label}
          htmlFor={id ?? name}
          isInvalid={errorMessage !== ''}
        />
      </div>
      <DateInput
        name={name}
        type={type}
        disabled={isDisabled}
        value={value}
        isInvalid={errorMessage !== ''}
        onChange={onChange}
        placeholder='dd/mm/aaaa'
        className={inputClassName}
        {...rest}
      />
      {errorMessage && <FieldMessage message={errorMessage} />}
    </div>
  )
}

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  noMargins: PropTypes.bool,
  type: PropTypes.oneOf(['date', 'month', 'year']),
}

DateField.defaultProps = {
  className: '',
  isDisabled: false,
  errorMessage: '',
  noMargins: false,
  type: 'date',
}
