import { UserProfile } from '@/components/Common/UserProfile'
import { TableData } from './components/TableData'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetDonation } from './hooks/useGetDonation'
import { LoadingData } from '../../components/Common/LoadingData'
import { useEffect } from 'react'
import { useAuth } from '@/contexts'
import { Icon } from '@iconify/react'
import { Button } from '@chakra-ui/react'

export const DonationDetails = () => {
  const { id } = useParams()
  const { user } = useAuth()
  const { data, makeRequest: fetchDonation, loading } = useGetDonation()
  const navigate = useNavigate()

  useEffect(() => {
    user && fetchDonation(id)
  }, [user])

  return (
    <div className='w-full flex flex-col gap-4 px-6 max-w-5xl justify-center mx-auto mb-8'>
      <UserProfile className='w-full max-w-none px-0' />

      <div className='flex items-center gap-1'>
        <Button variant='ghost' paddingX={2} onClick={() => navigate(-1)}>
          <Icon icon='ep:arrow-left-bold' className='text-2xl' />
        </Button>
        <p className='text-2xl text-primary-700 font-semibold'>Detalhes da Doação</p>
      </div>

      {loading || !data ? <LoadingData /> : <TableData dataDonation={data} fetchDonation={() => fetchDonation(id)} />}
    </div>
  )
}
