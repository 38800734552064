import { Tooltip } from '@chakra-ui/react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export const GridFragment = ({ title, body, className = '', truncate }) => {
  return (
    <Tooltip label={truncate && body}>
      <div className={classNames('flex flex-col gap-1 bg-surface', className)}>
        <p className={`pl-2 py-1 bg-gray-200 font-semibold `}>{title}</p>
        <p className={`pl-2 font-normal pb-2 pt-1 ${truncate && 'truncate'}`}>{body}</p>
      </div>
    </Tooltip>
  )
}

GridFragment.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
}

GridFragment.defaultProps = {
  title: '',
  className: '',
  body: '',
}
