import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { useAuth } from '@/contexts'
export const ModalQRCodeIndication = ({ isOpen, onClose }) => {
  const { user } = useAuth()

  if (!user) return null

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          QR Code <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <img src={user.indication.qrcode} alt='Qr Code' className='w-full m-auto' />
        </ModalBody>

        <ModalFooter>
          <Button
            as='a'
            href={user.indication.qrcode}
            download='QRCode.png'
            colorScheme='green'
            className='w-full flex items-center gap-x-4'
          >
            Baixar imagem
            <Icon icon='uil:down-arrow' className='text-2xl' />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
