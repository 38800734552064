import { useAuth } from '@/contexts'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useFetchDonationList = () => {
  const { userGroup } = useAuth()
  const { data, loading, error, makeRequest, meta } = useApi(params =>
    httpClient.get(userGroup.isAdmin ? '/admin/donations' : '/partners/donations', { params })
  )

  return {
    donationList: data || [],
    isLoadingDonation: loading,
    fetchDonation: makeRequest,
    errorDonation: error,
    metaDonation: meta,
  }
}
