import MoneyAsText from '@/components/Common/MoneyAsText'
import { Button, NumberInput } from '@/components/Form'
import { Icon } from '@iconify/react'
import { Skeleton, SkeletonText } from '@chakra-ui/react'
import { useDonation } from '@/contexts/DonationContext'

export const DonationInfos = () => {
  const {
    checkout,
    donationAmount,
    isDonationAppwins,
    setDonationAmount,
    isFavorite,
    setIsFavorite,
    loadingPartner,
    dataPartner,
    modalPartner,
    isChecked,
  } = useDonation()

  if (!dataPartner && !loadingPartner) return null

  const donationValue = dataPartner?.settings.donationPrice || 0

  return (
    <div className='flex flex-col gap-1 p-4 py-8 bg-surface rounded-lg self-start shadow-lg w-full max-w-lg mx-auto'>
      <div className='w-full bg-zinc-300 rounded mb-3 relative'>
        <Skeleton isLoaded={!loadingPartner} minH={loadingPartner && 160} fadeDuration={0.8}>
          <img className='max-h-[320px] w-full rounded-lg' src='/images/donationBanner.png' alt='' />
        </Skeleton>

        <span className='absolute bottom-1 right-1 bg-primary-600 text-slate-50 text-sm font-medium p-[2px] px-2 rounded'>
          <MoneyAsText value={donationValue} />
        </span>
      </div>

      <div className='w-full flex gap-3 justify-between items-start'>
        <SkeletonText isLoaded={!loadingPartner} fadeDuration={0.8} noOfLines={1} skeletonHeight={3} minW={24}>
          <h5 className='text-lg font-semibold self-center'>{dataPartner?.settings.companyName}</h5>
        </SkeletonText>
      </div>

      <SkeletonText isLoaded={!loadingPartner} fadeDuration={0.8} noOfLines={3} skeletonHeight='4'>
        <div className='text-sm'>
          Wins programa fidelíssimo de cashback social, doação recíproca e filantrópica, que lhe possibilita, descontos e outras
          restituições.
        </div>
      </SkeletonText>

      {dataPartner && !isDonationAppwins && (
        <div className='flex items-end mt-1 w-full justify-start gap-2'>
          <div className='flex flex-col items-start justify-start gap-1'>
            <span className='text-sm font-semibold'>Loja Parceira:</span>
            <span className='bg-secondary-600 text-slate-50 text-sm font-medium p-[2px] px-2 rounded w-fit'>
              {dataPartner?.name}
            </span>
          </div>

          {!loadingPartner && (
            <div className='flex items-end gap-1'>
              <button className='p-0 text-rose-600 text-2xl' onClick={() => setIsFavorite(prev => !prev)}>
                {isFavorite ? <Icon icon='ph:heart-fill' /> : <Icon icon='ph:heart' />}
              </button>

              <span className='text-base text-black font-medium'>Favorite</span>
            </div>
          )}
        </div>
      )}

      <div className='flex flex-col gap-2 mt-4'>
        <label className='text-sm font-medium'>Selecione a quantidade de cotas:</label>
        <div className='w-full flex items-center gap-3'>
          <Button
            className='px-2 rounded'
            isDisabled={isChecked || checkout.isCheckout || (dataPartner && isDonationAppwins)}
            onClick={() => setDonationAmount(prev => (prev > 1 ? prev - 1 : 1))}
          >
            <Icon icon='tabler:minus' />
          </Button>

          <NumberInput
            className='w-full self-stretch bg-slate-300 rounded flex justify-center text-center ring-1 ring-primary/50 p-0 disabled:text-zinc-900 disabled:opacity-100'
            name='donationAmount'
            value={isChecked ? 1 : donationAmount}
            isAllowed={values => {
              if (values.floatValue > 0) return true
            }}
            onChange={e => (e.numberValue > 0 ? setDonationAmount(e.numberValue) : setDonationAmount(1))}
            isDisabled={isChecked || checkout.isCheckout || (dataPartner && isDonationAppwins)}
          />

          <Button
            className='px-2 rounded'
            isDisabled={isChecked || checkout.isCheckout || (dataPartner && isDonationAppwins)}
            onClick={() => setDonationAmount(prev => prev + 1)}
          >
            <Icon icon='tabler:plus' />
          </Button>
        </div>

        <div className='underline underline-offset-2 mt-2'>
          Total: <MoneyAsText value={isChecked ? donationValue : donationValue * donationAmount} />
        </div>
      </div>
      {!checkout.isCheckout && dataPartner && isDonationAppwins && (
        <Button className='mt-3' onClick={modalPartner.onOpen}>
          Tornar-se Parceiro
        </Button>
      )}
    </div>
  )
}
