import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'

export const ErrorFetch = () => {
  const navigate = useNavigate()
  return (
    <div className='md:mx-auto flex flex-col justify-center items-center gap-6 md:min-w-[480px] min-h-[480px] p-4 py-8 bg-zinc-100 rounded-lg shadow-lg animate-fadeIn'>
      <Icon icon='akar-icons:chat-error' className='text-6xl text-red-700' />

      <p className='font-Montserrat font-semibold'>Ocorreu um erro desconhecido!</p>

      <button onClick={() => navigate(0, { replace: true })} className='text-blue-900 underline'>
        Tentar Novamente
      </button>
    </div>
  )
}
