import { DateAsText } from '@/components/Common'
import { Button } from '@/components/Form'
import { DateField, SelectField, TextField } from '@/components/Form/Fields'
import { useAuth } from '@/contexts'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

export const Filters = ({ filterFormParams, handleResetFilter }) => {
  const [ActiveFilters, setFilters] = useState([{ label: 'status', value: 1 }])
  const { values, handleChange, handleSubmit: handleSubmitFilter, errors } = filterFormParams
  const inputStyles = twMerge('rounded border-primary-blue/40 focus:border-primary bg-zinc-300/50 placeholder:text-zinc-800')
  const { userGroup } = useAuth()

  const handleSubmit = async e => {
    await handleSubmitFilter(e)
    const newFilters = Object.entries(values)
    setFilters(newFilters.filter(i => i[1] !== '').map(i => ({ label: i[0], value: i[1] })))
  }

  const handleClearFilter = () => {
    handleResetFilter()
    setFilters([{ label: 'status', value: 1 }])
  }

  const renderTag = (label, value) => {
    switch (label) {
      case 'term':
        return `Nome do doador: ${value}`
      case 'partner':
        return `Nome do parceiro: ${value}`
      case 'status':
        return `Status: ${
          value == '1'
            ? 'Pendente'
            : value == '2'
            ? 'Aprovado'
            : value == '3'
            ? 'Recusado'
            : value == '4'
            ? 'Aguardando Pgto.'
            : 'Todos'
        }`
      case 'initialDate':
        return (
          <>
            Data inicial: <DateAsText date={value} />
          </>
        )
      case 'endDate':
        return (
          <>
            Data final: <DateAsText date={value} />
          </>
        )
      case 'donationCode':
        return `Código da doação: ${value}`
      default:
        return `${label}: ${value}`
    }
  }

  return (
    <Accordion allowToggle defaultIndex={[0]} className='bg-surface shadow-lg rounded' padding={1} pt={2}>
      <AccordionItem border={0}>
        <AccordionButton>
          <div className='w-full flex flex-col items-start'>
            <div className='w-full flex justify-between gap-2'>
              <h3 className='text-lg font-medium text-left'>Filtros de busca</h3>
              <AccordionIcon fontSize={28} />
            </div>

            {ActiveFilters.length > 0 && (
              <div className='flex gap-2 justify-start items-center flex-wrap py-2'>
                {ActiveFilters.map((filter, ind) => (
                  <span className='bg-tertiary-600 text-zinc-50 p-1 px-3 rounded text-sm' key={ind}>
                    {renderTag(filter.label, filter.value)}
                  </span>
                ))}
              </div>
            )}
          </div>
        </AccordionButton>

        <AccordionPanel>
          <form
            onSubmit={handleSubmit}
            className={`grid lg:grid-cols-2 ${userGroup.isPartner ? 'xl:grid-cols-5' : 'xl:grid-cols-4'} items-start gap-3`}
          >
            <TextField
              label='Nome do doador'
              name='term'
              value={values.term}
              onChange={handleChange}
              errorMessage={errors.term}
              placeholder='Ex.: joao da silva'
              inputClassName={inputStyles}
            />

            {!userGroup.isPartner && (
              <TextField
                label='Nome do parceiro'
                name='partner'
                value={values.partner}
                onChange={handleChange}
                errorMessage={errors.partner}
                placeholder='Ex.: Empresa LTDA'
                inputClassName={inputStyles}
              />
            )}

            <DateField
              label='Data inicial'
              name='initialDate'
              value={values.initialDate}
              onChange={handleChange}
              errorMessage={errors.initialDate}
              inputClassName={inputStyles}
            />

            <DateField
              label='Data final'
              name='endDate'
              value={values.endDate}
              onChange={handleChange}
              errorMessage={errors.endDate}
              inputClassName={twMerge(inputStyles, values.initialDate.length === 0 && 'text-opacity-50')}
              isDisabled={values.initialDate.length === 0}
              min={values.initialDate}
            />

            <TextField
              label='Código da doação'
              name='donationCode'
              placeholder='Ex.: D00000000000000'
              value={values.donationCode}
              onChange={handleChange}
              errorMessage={errors.donationCode}
              inputClassName={inputStyles}
            />

            <SelectField
              label='Status'
              name='status'
              value={values.status}
              onChange={handleChange}
              errorMessage={errors.status}
              optionPrimary='Todos'
              options={[
                {
                  label: 'Pendente',
                  value: 1,
                },
                {
                  label: 'Aprovado',
                  value: 2,
                },
                {
                  label: 'Recusado',
                  value: 3,
                },
                {
                  label: 'Aguardando Pgto.',
                  value: 4,
                },
              ]}
              inputClassName={twMerge(inputStyles)}
            />

            {userGroup.isPartner && <div className='max-lg:hidden xl:col-span-3' />}
            <Button
              type='button'
              onClick={handleClearFilter}
              className='w-full p-2.5 rounded min-w-[160px] bg-primary-600 xl:mt-6 self-start'
            >
              Limpar
            </Button>
            <Button type='submit' className='w-full p-2.5 rounded min-w-[160px] bg-primary-600 xl:mt-6 self-start'>
              Pesquisar
            </Button>
          </form>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
