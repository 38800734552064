import React from 'react'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { Button } from '@/components/Form'
import { AddressStep, BusinessStep, PersonalStep } from '.'
import { useDonation } from '@/contexts/DonationContext'
import { PasswordField } from '@/components/Form/Fields'
import { TermsStep } from './TermsStep'

export const PartnerModal = ({ isOpen, onClose, verifyAppwins }) => {
  const { partnerCheckout } = useDonation()
  const { step, setStep, selectOptions, multiSelectOptions, ufOptions, cityOptions } = partnerCheckout
  const { values, errors, handleSubmit, handleChange, setFieldValue } = partnerCheckout.partnersForm

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={step === 5 ? '3xl' : undefined} scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Seja Parceiro</ModalHeader>
        <ModalCloseButton />
        <ModalBody as='form' onSubmit={handleSubmit} marginBottom={4}>
          {step === 1 && (
            <PersonalStep
              values={values}
              errors={errors}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              verifyAppwins={verifyAppwins}
            />
          )}

          {step === 2 && (
            <BusinessStep
              values={values}
              errors={errors}
              handleChange={handleChange}
              multiSelectOptions={multiSelectOptions}
              selectOptions={selectOptions}
              setFieldValue={setFieldValue}
            />
          )}

          {step === 3 && (
            <>
              <PasswordField
                label='Senha'
                name='password'
                placeholder='******'
                value={values.password}
                errorMessage={errors.password}
                onChange={handleChange}
              />
              <PasswordField
                label='Confirmar senha'
                name='confirmPassword'
                placeholder='******'
                value={values.confirmPassword}
                errorMessage={errors.confirmPassword}
                onChange={handleChange}
              />
            </>
          )}

          {step === 4 && (
            <AddressStep
              cityOptions={cityOptions}
              errors={errors}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              ufOptions={ufOptions}
              values={values}
            />
          )}

          {step === 5 && <TermsStep errors={errors} setFieldValue={setFieldValue} values={values} />}

          <div className='w-full flex items-center justify-between gap-3'>
            <Button
              className='w-full bg-transparent border border-primary text-primary'
              onClick={() => {
                if (step <= 1) onClose()
                if (step > 1) {
                  setStep(step - 1)
                }
              }}
            >
              Voltar
            </Button>
            <Button type='submit' className='w-full'>
              {step === 5 ? 'Salvar' : 'Avançar'}
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
