import React from 'react'
import PropTypes from 'prop-types'
import { twMerge } from 'tailwind-merge'
import { Icon } from '@iconify/react'

export const Button = ({ text, type, className, isDisabled, isLoading, children, onClick, ...rest }) => {
  const buttonClasses = twMerge(
    'py-3 px-5 bg-primary text-white rounded-lg flex gap-2 items-center justify-center',
    className,
    !isDisabled && 'transition ease-in-out duration-300 delay-50 hover:opacity-80',
    isDisabled && 'opacity-40 cursor-not-allowed'
  )

  return (
    <button type={type} className={buttonClasses} onClick={onClick} disabled={isDisabled} {...rest}>
      {isLoading && <Icon icon='mingcute:loading-line' className='animate-spin' />}
      {text !== '' ? text : children}
    </button>
  )
}

Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
}

Button.defaultProps = {
  text: '',
  type: 'button',
  isLoading: false,
  isDisabled: false,
  onClick: () => {},
}
