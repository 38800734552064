import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export function useCreatePassword() {
  const { data, error, loading, makeRequest } = useApi((token, values) => httpClient.post(`/define-password/${token}`, values))

  return {
    dataCreatePassword: data,
    errorCreatePassword: error,
    loadingCreatePassword: loading,
    createPassword: makeRequest,
  }
}
