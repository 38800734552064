import { MultiSelect } from 'chakra-multiselect'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import { Label } from '..'
import { FieldMessage } from '.'
import PropTypes from 'prop-types'

export const MultiselectField = ({
  className,
  noMargins,
  label,
  id,
  name,
  errorMessage,
  inputClasses,
  isDisabled,
  values,
  options,
  onChange,
  ...rest
}) => {
  const labelClasses = errorMessage !== '' ? 'text-negative' : ''

  return (
    <div className={twMerge(!noMargins && 'mb-3', className)}>
      <Label className={twMerge(labelClasses)} text={label} htmlFor={id ?? name} isInvalid={errorMessage !== ''} />
      <MultiSelect
        name={name}
        id={id ?? name}
        className={twMerge(errorMessage !== '' ? 'border border-negative' : '', inputClasses)}
        _invalid={errorMessage !== ''}
        disabled={isDisabled}
        value={values}
        options={options}
        onChange={onChange}
        {...rest}
      />
      {errorMessage && <FieldMessage message={errorMessage} />}
    </div>
  )
}

MultiselectField.propTypes = {
  noMargins: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

MultiselectField.defaultProps = {
  disabled: false,
  options: [],
  errorMessage: '',
  noMargins: false,
}
