import { UserProfile } from '@/components/Common/UserProfile'
import { Filters } from './components/Filters'
import { TableList } from './components/TableList'
import { useDonationList } from './hooks/useDonationList'
import { LoadingData } from '../../components/Common/LoadingData'
import { useAuth } from '@/contexts'
import { EmptyList } from '@/components/Common/EmptyList'
import { Pagination } from '@/components/Common/Pagination'

export const DonationList = () => {
  const { filterFormParams, onFetchDonation, dataDonationList, isLoading, metaDonation, handleResetFilter } = useDonationList()
  const { userGroup } = useAuth()

  return (
    <div className='flex flex-col gap-3 px-4 pb-8'>
      <UserProfile className='px-0 max-w-none' />

      <h1 className='text-2xl font-semibold text-primary'>{userGroup.isPartner ? 'Minhas doações' : 'Doações'}</h1>

      <Filters filterFormParams={filterFormParams} isLoading={isLoading} handleResetFilter={handleResetFilter} />

      {isLoading ? (
        <LoadingData />
      ) : (
        <TableList dataDonationList={dataDonationList} onFetchDonation={onFetchDonation} metaDonation={metaDonation} />
      )}

      {!isLoading && dataDonationList.length === 0 && <EmptyList message='Nenhuma doação encontrada!' />}

      {metaDonation && <Pagination pagination={metaDonation} onPageChange={onFetchDonation} />}
    </div>
  )
}
