import { useEffect, useState } from 'react'

export const Video = () => {
  const [hovered, setHovered] = useState(false)

  const handleTouchStart = () => {
    setHovered(true)
  }

  const handleTouchEnd = () => {
    setHovered(false)
  }

  const handleScroll = () => {
    const isMobile = window.innerWidth < 1025
    if (isMobile) {
      const currentPosition = window.scrollY

      const heightToTriggerStart = 1200
      const heightToTriggerEnd = 2000

      if (currentPosition >= heightToTriggerStart) {
        handleTouchStart()
      }

      if (currentPosition >= heightToTriggerEnd || currentPosition < heightToTriggerStart) {
        handleTouchEnd()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <section
      onMouseEnter={handleTouchStart}
      onMouseLeave={handleTouchEnd}
      className='pt-28 pb-14 bg-[#F4EDE4] flex flex-col gap-24 lg:pb-44 lg:pt-56'
    >
      <div className='flex flex-col gap-12 md:gap-24 2xl:flex-row 2xl:gap-0'>
        <div className='relative w-full flex justify-start lg:max-w-4xl'>
          <div className={`relative flex justify-start duration-[1.5s] ease-in-out ${hovered ? 'w-full' : 'w-56'}`}>
            <div className='h-48 w-full max-w-[90%] rounded-e-full bg-[#683D8F] lg:h-96' />
          </div>
          <iframe
            src='https://www.youtube.com/embed/TJbLAUbWlTQ'
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            title='Embedded YouTube Video'
            className='absolute bottom-9 left-1/2 -translate-x-1/2 max-w-80 h-48 lg:translate-x-0 lg:left-auto lg:right-10 lg:bottom-20 lg:max-w-full lg:w-[600px] lg:h-96'
          />
        </div>
        <div className='px-10 flex flex-col gap-6'>
          <h2 className='font-bold text-0.5xl text-[#252525] lg:w-3/4 lg:text-4xl'>Conheça nossa proposta</h2>
          <p className='font-medium text-justify text-[#414141] lg:w-3/4 lg:text-0.5xl'>
            Nossa proposta é oferecer uma plataforma de cashback que transforma suas compras em economia real. Queremos que cada
            transação traga benefícios duplos: para seu bolso e para o mundo.
          </p>
          <p className='font-medium text-justify text-[#414141] lg:w-3/4 lg:text-0.5xl'>
            Ao usar nossos serviços, você não apenas ganha dinheiro de volta em suas compras, mas também tem a oportunidade de
            apoiar causas sociais e receber descontos exclusivos.
          </p>
        </div>
      </div>

      <div className='flex flex-col gap-4 px-10 justify-center max-w-7xl mx-auto mt-6 md:mt-20'>
        <h2 className='font-bold text-0.5xl text-[#252525] lg:text-4xl text-justify md:text-center'>
          Ações Diárias que Transformam
        </h2>

        <p className='font-medium text-[#414141] lg:text-0.5xl text-justify md:text-center'>
          Nosso objetivo é alcançar empresas, fidelizar clientes, qualificar e sustentabilizar pessoas, que tenha disponibilidade
          de ser um captador de recursos para juntos, também garantirmos a sustentabilidade das organizações não governamentais e
          outras, apenas com ações do nosso cotidiano.
        </p>
      </div>
    </section>
  )
}
