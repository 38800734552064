import { Header } from '@/components/Menu/Header'
import React from 'react'
import { Outlet } from 'react-router-dom'

export const Layout = () => {
  return (
    <div className='flex flex-col justify-start bg-secondary-100 w-full min-h-screen'>
      <Header />

      <main className='container w-full grow mx-auto font-Montserrat flex flex-col gap-3'>
        <Outlet />
      </main>
    </div>
  )
}
