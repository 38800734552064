import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export function useGetLinks() {
  const { data, error, loading, makeRequest } = useApi(donationId =>
    httpClient.get(`/donations/my-links/?donationId=${donationId}`)
  )

  return {
    dataLinks: data,
    errorLinks: error,
    loadingLinks: loading,
    fetchLinks: makeRequest,
  }
}
