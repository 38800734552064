import React from 'react'
import { Link } from 'react-router-dom'
import { Button, CheckBox } from '@/components/Form'
import { CepField, CpfCnpjField, FieldMessage, PasswordField, PhoneField, SelectField, TextField } from '@/components/Form/Fields'
import { useSignUp } from '../context/SignUpContext'
import { MultiSelect } from 'chakra-multiselect'
import '../styles/styles.scss'

export const Form = () => {
  const {
    uf,
    city,
    selectValues,
    loading,
    errors,
    handleChange,
    handleSubmit,
    values,
    setFieldValue,
    loadingZipCode,
    loadingCity,
    myRequestZipCode,
    selectOptions,
    multiSelectOptions,
  } = useSignUp()

  return (
    <div className='grid lg:grid-cols-2 lg:gap-4 max-lg:px-4'>
      <h1 className='text-4xl font-bold mb-6 lg:hidden'>Junte-se a Wins para fazer a diferença!</h1>

      <div className='w-full hidden lg:block h-screen p-4'>
        <h1 className='text-4xl font-bold mb-6'>Junte-se a Wins para fazer a diferença!</h1>
        <img src='/images/banner.png' alt='/' className='object-cover rounded-lg h-screen' />
      </div>

      <div>
        <form onSubmit={handleSubmit} className='lg:p-4'>
          <div className='flex flex-col gap-4 max-lg:bg-surface rounded-lg max-lg:p-2 max-lg:px-4 max-lg:shadow-md'>
            <h3 className='text-2xl font-semibold my-1 text-primary-400'>Dados Pessoais</h3>
            <div className='flex flex-col gap-2'>
              <TextField
                label='Nome'
                name='name'
                placeholder='Ex.: Eliane Louise Giovana Castro'
                value={values.name}
                errorMessage={errors.name}
                onChange={handleChange}
                isDisabled={loading}
              />
              <SelectField
                label='Tipo de Parceiro'
                name='typeCustomer'
                optionPrimary={null}
                value={values.typeCustomer}
                errorMessage={errors.typeCustomer}
                options={selectOptions()}
                isDisabled={loading}
                onChange={e => {
                  handleChange(e)
                }}
              />

              <div className='grid xl:grid-cols-2 w-full gap-2'>
                <div>
                  <MultiSelect
                    label='Segmento'
                    name='segments'
                    value={selectValues}
                    options={multiSelectOptions.map(option => ({ label: option.name, value: option.id }))}
                    disabled={loading}
                    onChange={e => {
                      setFieldValue('segments', e)
                    }}
                  />
                  {errors.segments && <FieldMessage message={errors.segments} />}
                </div>

                {values.typeCustomer === 'cpf' && (
                  <CpfCnpjField
                    label='CPF'
                    name='cpfCnpj'
                    placeholder='Ex.: 349.251.155-45'
                    value={values.cpfCnpj}
                    errorMessage={errors.cpfCnpj}
                    onChange={handleChange}
                    isDisabled={loading}
                  />
                )}
                {values.typeCustomer === 'cnpj' && (
                  <CpfCnpjField
                    label='CNPJ'
                    name='cpfCnpj'
                    format='cnpj'
                    placeholder='Ex.: 00.000.000/0000-00'
                    value={values.cpfCnpj}
                    errorMessage={errors.cpfCnpj}
                    onChange={handleChange}
                    isDisabled={loading}
                  />
                )}
              </div>

              <div className='grid md:grid-cols-2 gap-2'>
                <TextField
                  label='E-mail'
                  name='email'
                  placeholder='Ex.: eliane_castro@boldcron.com.br'
                  value={values.email}
                  errorMessage={errors.email}
                  onChange={handleChange}
                  isDisabled
                />
                <PhoneField
                  label='Telefone'
                  name='phone'
                  placeholder='Ex.: (32) 99481-8925'
                  value={values.phone}
                  errorMessage={errors.phone}
                  onChange={v => setFieldValue('phone', v.value)}
                  isDisabled={loading}
                />
              </div>

              <div className='grid md:grid-cols-2 gap-2'>
                <PasswordField
                  label='Senha'
                  name='password'
                  placeholder='******'
                  value={values.password}
                  errorMessage={errors.password}
                  onChange={handleChange}
                  isDisabled={loading}
                />
                <PasswordField
                  label='Confirmar senha'
                  name='confirmPassword'
                  placeholder='******'
                  value={values.confirmPassword}
                  errorMessage={errors.confirmPassword}
                  onChange={handleChange}
                  isDisabled={loading}
                />
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-4 max-lg:mt-8 max-lg:bg-surface rounded-lg max-lg:p-2 max-lg:px-4 max-lg:shadow-md'>
            <h3 className='text-2xl font-semibold my-1 text-primary-400'>Endereço</h3>
            <div className='grid md:grid-cols-2 gap-2'>
              <CepField
                label='CEP'
                name='zipCode'
                placeholder='Ex.: 36088-450'
                value={values.zipCode}
                errorMessage={errors.zipCode}
                onValueChange={({ value }) => {
                  setFieldValue('zipCode', value)
                  if (value.length === 8) {
                    myRequestZipCode(value)
                  }
                }}
                isDisabled={loading || loadingZipCode}
              />
              <TextField
                label='Endereço'
                name='street'
                placeholder='Ex.: Rua da Fé'
                value={values.street}
                errorMessage={errors.street}
                onChange={handleChange}
                isDisabled={loading}
              />
              <TextField
                label='Número'
                name='number'
                placeholder='Ex.: 729'
                value={values.number}
                errorMessage={errors.number}
                onChange={handleChange}
                isDisabled={loading}
              />
              <TextField
                label='Complemento'
                name='complement'
                placeholder='Ex.: apto 23 bloco 3'
                value={values.complement}
                errorMessage={errors.complement}
                onChange={handleChange}
                isDisabled={loading}
              />
              <TextField
                label='Bairro'
                name='neighborhood'
                placeholder='Ex.: Santa Cruz'
                value={values.neighborhood}
                errorMessage={errors.neighborhood}
                onChange={handleChange}
                isDisabled={loading}
              />
              <SelectField
                label='Estado'
                name='state'
                placeholder='Minas Gerais'
                value={values.state}
                options={uf}
                errorMessage={errors.state}
                onChange={handleChange}
                isDisabled={loading || loadingZipCode}
              />
              <SelectField
                label='Cidade'
                name='city'
                placeholder='Juiz de Fora'
                value={values.city}
                options={city}
                errorMessage={errors.city}
                onChange={handleChange}
                isDisabled={loading || loadingCity || loadingZipCode}
              />
            </div>
          </div>

          <div className='flex flex-col gap-2 w-full my-4'>
            <CheckBox
              label='Eu concordo com os termos e condições de uso'
              name='check'
              value={values.check}
              onChange={e => {
                setFieldValue('check', e.target.checked)
              }}
              isDisabled={loading}
              checked={values.check}
            />
            <Button text='Cadastrar' type='submit' isDisabled={loading || !values.check} className='w-full' />
            <Link to='/login' className='mb-4 underline underline-offset-2 text-info-dark text-center w-full rounded'>
              Já Possui Conta?
            </Link>
          </div>
        </form>
      </div>
    </div>
  )
}
