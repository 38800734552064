import { useApi, useAxios } from '@/hooks'
import httpClient from '@/utils/httpClient'
import { useEffect, useState } from 'react'

export const useGetPartnerAddress = ({ partnersForm }) => {
  const [ufOptions, setUfOptions] = useState()
  const [cityOptions, setCityOptions] = useState()

  const {
    makeRequest: myRequestZipCode,
    data: dataZipCode,
    loading: loadingZipCode,
  } = useApi(zipCode => httpClient.get(`zipcode/${zipCode}/`))

  const { makeRequest: makeRequestUf, data: dataUf } = useAxios(() =>
    httpClient.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/')
  )

  const {
    makeRequest: makeRequestCity,
    data: dataCity,
    loading: loadingCity,
  } = useAxios(selectedUf =>
    httpClient.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios`)
  )

  const handleOptionsUf = (options = []) => {
    const arr = []
    options?.map(item => {
      arr.push({ label: item.nome, value: item.sigla })
    })
    return arr
  }

  const handleOptionsCity = (options = []) => {
    const arr = []
    options?.map(item => {
      arr.push({ label: item.nome, value: item.nome })
    })
    return arr
  }

  useEffect(() => {
    const { values } = partnersForm
    if (!values.zipCode) return
    if (values.zipCode.length === 8) {
      myRequestZipCode(values.zipCode)
    }
  }, [partnersForm.values.zipCode])

  useEffect(() => {
    if (!dataUf) return
    setUfOptions(handleOptionsUf(dataUf))
  }, [dataUf])

  useEffect(() => {
    if (!dataZipCode) return
    const { setFieldValue } = partnersForm
    setFieldValue('city', dataZipCode.localidade)
    setFieldValue('state', dataZipCode.uf)
    setFieldValue('street', dataZipCode.logradouro)
    setFieldValue('neighborhood', dataZipCode.bairro)
  }, [dataZipCode])

  useEffect(() => {
    const { values } = partnersForm
    if (values.state === '') return
    makeRequestCity(values.state)
  }, [partnersForm.values.state])

  useEffect(() => {
    if (!dataCity) return
    let newCity = handleOptionsCity(dataCity)
    setCityOptions(newCity)
  }, [dataCity])

  useEffect(() => {
    makeRequestUf()
  }, [])

  return {
    ufOptions,
    loadingCity,
    loadingZipCode,
    cityOptions,
  }
}
