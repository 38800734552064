import React from 'react'
import { Icon } from '@iconify/react'
import { twMerge } from 'tailwind-merge'
import { Link } from 'react-router-dom'

export const Header = ({ headerCn }) => {
  const id = 'FXK1234567'
  return (
    <>
      <Link to={`/donate/${id}`} className='mr-auto'>
        <button className='flex items-center text-sm lg:hidden border border-primary text-primary rounded px-2 py-1'>
          <Icon icon='material-symbols:arrow-back' fontSize={16} />
          Voltar
        </button>
      </Link>
      <header className={twMerge('flex items-center justify-center gap-2 lg:justify-center ', headerCn)}>
        <h2 className='font-bold text-positive text-2xl lg:text-4xl'>Doação Realizada!</h2>
        <span className='rounded-full bg-green-500 p-1'>
          <Icon icon='line-md:confirm' color='white' fontSize={24} />
        </span>
      </header>
    </>
  )
}
